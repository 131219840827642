import { Button, Checkbox, FormControlLabel, Grid, Box } from '@mui/material'
import {
	SelectBox,
	ChipSelectBox,
	TextFieldControl,
	LoadMask,
	CustomDialog
} from 'component/common'
import { useAddProjectDialog } from './useAddProjectDialog'
import './AddProjectDialog.scss'

const AddProjectDialog = () => {
	const {
		open,
		data,
		genres,
		isValid,
		isAgree,
		loading,
		projectTypes,
		loadingGenres,
		loadingProjectTypes,
		handleSubmit,
		handleChange,
		handleClose,
		setIsAgree
	} = useAddProjectDialog()

	return (
		<>
			{loading || loadingGenres || loadingProjectTypes ? (
				<LoadMask />
			) : (
				<CustomDialog {...{ open, handleClose, variant: 'new_project' }}>
					<Box component="form" onSubmit={handleSubmit}>
						<Box className="title">Create A New Project</Box>
						<Box className="content">
							<Grid container spacing={3}>
								<Grid item xs={6} container>
									<TextFieldControl
										hiddenLabel
										fullWidth
										autoFocus
										name="title"
										value={data?.title}
										placeholder="Your Project Title"
										onChange={e => {
											handleChange({
												title: e.target.value
											})
										}}
									/>
									<SelectBox
										{...{
											options: projectTypes,
											value: data?.typeId || '',
											setValue: value => {
												handleChange({
													typeId: value
												})
											},
											placeholder: 'Project Type'
										}}
									/>
									<ChipSelectBox
										{...{
											values: data?.genres,
											setValues: values => {
												handleChange({
													genres: values
												})
											},
											options: genres,
											placeholder: 'Your Genres. Choose up to 3'
										}}
									/>
								</Grid>
								<Grid item xs={6}>
									<Box className="iagree">
										<FormControlLabel
											className="custom-checkBox"
											label="I agree"
											control={
												<Checkbox
													checked={isAgree}
													onChange={event => {
														setIsAgree(event.target.checked)
													}}
												/>
											}
										/>
										<small className="byCreate">
											By creating a project you attest that you are the rights
											holder, or acting on behalf of the rights holder with
											their tacit permission to create and manage this project.
											You are responsible for completing, updating and
											maintaining the project page, and managing any inquiries
											the project may receive on Filmio and you agree to our{' '}
											<a
												href="/terms-of-service"
												target="_blank"
												rel="noreferrer"
											>
												Terms of Service
											</a>{' '}
											and{' '}
											<a
												href="/code-of-conduct"
												target="_blank"
												rel="noreferrer"
											>
												Code of Conduct
											</a>
											.
										</small>
									</Box>
								</Grid>
							</Grid>
						</Box>
						<Box className="actions">
							<Button variant="filled" disabled={!isValid} type="submit">
								Submit
							</Button>
						</Box>
					</Box>
				</CustomDialog>
			)}
		</>
	)
}

export default AddProjectDialog
