import { LightTooltip } from 'component/common'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Avatar, Box, Icon, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { formatDateToRelative } from 'utils/date.utils'
import styles from './ReviewInfoTimestamp.module.scss'
import { getTransactionLink } from 'utils/utils'
import { getFileUrl } from 'utils/url.util'

const ReviewInfoTimestamp = ({ updatedAt, user, project, blockchainHash }) => {
	const { user: signInUser } = useSelector(state => state.signIn)
	const displayName = signInUser.id === user.id ? "Your Review" : user.displayName

	return (
		<Box className={styles.profile_link}>
			<LightTooltip title={`View ${user?.displayName}'s Profile`}>
				<Link to={`/profile/${user?.username}`}>
					<Avatar
						alt={user?.displayName}
						sx={{
							bgcolor: '#000000',
							border: '1px solid #ffffff',
							height: 35,
							width: 35,
							marginRight: '12px'
						}}
						src={getFileUrl(user?.avatar)}
					/>
				</Link>
			</LightTooltip>
			<Box className={styles.info_container}>
				<LightTooltip title={`View ${user?.displayName}'s Profile`}>
					<Link to={`/profile/${user?.username}`}>
						<Box className={styles.display_name}>
							<Typography>{displayName}</Typography>
							{user?.isVerified === 1 && (
								<span>
									<Icon baseClassName="fas" className="fa-badge-check" />
								</span>
							)}
						</Box>
					</Link>
				</LightTooltip>
				<Typography className={styles.profession}>
					{project ? (
						<>
							<span className={styles.hash}>
								{getTransactionLink(blockchainHash)}
							</span>{' '}
							Reviewed{' '}
							<Link to={`/project/${project?.slug}`}>{project?.title}</Link>{' '}
							{formatDateToRelative(updatedAt)}
						</>
					) : (
						<>Posted {formatDateToRelative(updatedAt)}</>
					)}
				</Typography>
			</Box>
		</Box>
	)
}

ReviewInfoTimestamp.propTypes = {
	updatedAt: PropTypes.string,
	user: PropTypes.object,
	project: PropTypes.object,
	blockchainHash: PropTypes.string
}

export default ReviewInfoTimestamp
