import _ from 'lodash'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateUserSaleEmailService } from 'services/auth.service'
import {
	setAuthenticated,
	setSaleEmailDialogOpen,
	setIsIngXRestrictedInvestor,
} from 'component/header/signInSlice'
import useNotification from 'hooks/useNotification'
import { isValidEmail } from 'utils/form.util'
import useLoadingMask from 'hooks/useLoadingMask'
import { addObjectToLocalStorage } from 'utils/utils'
import useAxios from 'hooks/useAxios'
import magic from 'utils/blockchain/config/constants'
import { Mixpanel } from 'services/mixpanel.service'
import useHeader from '../useHeader'
import { signMagicVerificationMessage } from 'utils/blockchain/magic'

const useSaleMagicDialog = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [email, setEmail] = useState('')
	const { saleEmailDialogOpen: open, saleUserId } = useSelector(
		state => state.signIn
	)
	const { showNotification } = useNotification()
	const [errors, setErrors] = useState({})
	const { showMask, hideMask } = useLoadingMask()
	const { loadUserTierInfo, handleLogout } = useHeader()
	const { axiosService } = useAxios()

	const authUserToRegisterMagicWallet = async ({ email }) => {
		try {
			showMask()
			const iToken = await magic.auth.loginWithEmailOTP({ email })
			const { message, signature } = await signMagicVerificationMessage()
			const intervalId = showMessageLoop()
			axiosService(
				updateUserSaleEmailService({
					userId: saleUserId,
					email,
					iToken,
					message,
					signature
				}),
				(data, error) => {
					if (!error) {
						const isIngXRestrictedInvestor = !_.isNil(data?.isIngXInvestor) && data?.isIngXInvestor
						dispatch(setIsIngXRestrictedInvestor(isIngXRestrictedInvestor))
						addObjectToLocalStorage({ ...data, isAuthenticated: true , isIngXRestrictedInvestor})
						dispatch(setAuthenticated(data))
						setEmail('')
						registerMixpanelEvent(data)
						loadUserTierInfo()
					}
					clearInterval(intervalId)
					hideMask()
				}
			)
		} catch (error) {
			console.log(error)
			handleClose()
			hideMask()
		}
	}

	const showMessageLoop = () => {
		let index = 0
		const messagePrefix = `We're Upgrading Your Film.io Experience.\n`
		const initialMessage = `${messagePrefix} This will take 1-2 minutes, please hang tight.`
		showMask(initialMessage)
		const loadMessages = [
			`We’re still working on the upgrade, thanks for your patience!`,
			`You’re on your way to a much faster blockchain experience!`,
			`We’re almost there, soon you’ll be able to process multiple transactions at the same time!`,
			`Getting close!  You are on your way to a more secure and convenient no password experience!`
		]
		const intervalId = setInterval(() => {
			const message = `${messagePrefix} ${loadMessages[index]}`
			showMask(message)
			index = index === 3 ? 0 : index + 1
		}, 15000)
		return intervalId
	}

	const registerMixpanelEvent = data => {
		Mixpanel.identify(data?.id)
		Mixpanel.people.set({
			displayName: data?.displayName,
			id: data?.id,
			username: data?.username,
			isDaoTermsAgreed: data?.isDaoTermsAgreed,
			isAirdropCreator: data?.isAirdropCreator,
			isAdmin: data?.isAdmin
		})
		Mixpanel.track('user_login_success_evt', {
			distinct_id: data?.id,
			displayName: data?.displayName,
			id: data?.id,
			username: data?.username,
			isDaoTermsAgreed: data?.isDaoTermsAgreed,
			isAirdropCreator: data?.isAirdropCreator,
			isAdmin: data?.isAdmin,
			projectCount: data?.projectCount,
			balance: data?.balance
		})
	}

	const updateErrors = payload => {
		setErrors({
			...errors,
			...payload
		})
	}

	const isValid = () => {
		let isValid = true
		const errorsTmp = {}
		const message = 'This field is required'
		if (_.isNil(email) || email.trim() === '') {
			errorsTmp.email = message
			isValid = false
		}
		if (!_.isNil(email) && !isValidEmail(email)) {
			errorsTmp.email = 'The email format is incorrect.'
			isValid = false
		}
		updateErrors(errorsTmp)
		return isValid
	}

	const handleSubmit = async () => {
		// const submitService = hidePassword ? checkUserStatus : authUserToRegisterMagicWallet
		if (isValid()) {
			handleClose()
			await authUserToRegisterMagicWallet({ email })
		} else
			showNotification({
				message: 'Please fix the form errors to continue',
				type: 'error'
			})
	}

	const emailChangeHandler = event => {
		updateErrors({ email: false })
		setEmail(_.trim(event.target.value))
	}

	const handleClose = redirect => {
		handleLogout()
		dispatch(setSaleEmailDialogOpen(false))
		if (redirect) {
			// TODO take a look for a better solution
			setTimeout(() => {
				return navigate(redirect)
			}, 0)
		}
	}

	const handleKeypress = e => {
		if (e.charCode === 13) {
			handleSubmit()
		}
	}

	return {
		open,
		email,
		errors,
		emailChangeHandler,
		handleKeypress,
		handleSubmit,
		handleClose
	}
}

export default useSaleMagicDialog
