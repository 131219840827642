import { Box } from '@mui/material'
import styles from './SpeedUpBar.module.scss'
import PropTypes from 'prop-types'

const SpeedUpBar = ({ value}) => {

	return (
		<Box className={styles.bar_container}>
			<Box className={styles.progress_container}>
				<Box className={styles.progress_bar} style={{ width: `${value}%` }}>
				</Box>
			</Box>
			{(value !== 100 && value !== 0) &&
				<span className={styles.progressPercent}>
					{value}
				</span>}
			
		</Box>

	)
}

SpeedUpBar.propTypes = {
	value: PropTypes.number,
	style: PropTypes.object,
}

export default SpeedUpBar
