import _ from 'lodash'
import { createRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { setTrailerInfo } from '../projectDetailSlice'
import { getConfiguration } from 'services/configuration.service'
import { getUserReviewService } from 'services/review.service'
import useAxiosFetch from 'hooks/useAxiosFetch'
import useAxios from 'hooks/useAxios'
import { getVideoInfoFromUrl } from 'utils/video.utils'
import userflow from 'userflow.js'

const useDashboardTab = () => {
	const { project, trailerInfo } = useSelector(state => state.projectDetail)
	const { user: signInUser } = useSelector(state => state.signIn)
	const { isGoScoreV2Enabled } = useSelector(state => state.home)
	const setSearchParams = useSearchParams()[1]
	const dispatch = useDispatch()
	const { axiosService } = useAxios()

	const [storyHeight, setStoryHeight] = useState(0)
	const [showMore, setShowMore] = useState(false)

	const [configuration, setConfiguration] = useState({})
	const [userReview, setUserReview] = useState(null)

	const [{ loading }] = useAxiosFetch(getConfiguration(), (response, error) => {
		if (!error) {
			setConfiguration(response)
		}
	})

	const fetchUserReview = (projectId) => {
		axiosService(getUserReviewService(projectId), (response, error) => {
			if (!error) {
				setUserReview(response)
			}
		})
	}

	const showGreenlight = () => {
		userflow.start('2676977f-57e2-45d4-a09d-b8844a643ed7')
	}

	const showImpact = () => {
		userflow.start('fda43ba1-4a44-4fff-8582-2ddf06755ada')
	}

	const showMoreToggle = () => {
		setShowMore(!showMore)
	}

	const storyRef = createRef()

	const buildCategoriesLine = () =>
		project?.genres?.map(g => (
			<li id={`genre-${g.id}`} key={`genre-${g.id}`}>
				{g.name}
			</li>
		))

	const isOwnerSignedIn = () => {
		return _.get(project, ['user', 'id']) === signInUser?.id
	}

	const handleSocialClick = tab => () => {
		setSearchParams({ tab })
	}

	const isDraft = project.stageId === 1

	useEffect(() => {
		setStoryHeight(storyRef?.current?.clientHeight)
	})

	useEffect(() => {
		if(!_.isNil(project?.id) ){
			fetchUserReview(project?.id)
		}
		const getTrailerInfo = async () => {
			const info = await getVideoInfoFromUrl(project?.trailer)
			dispatch(setTrailerInfo(info))
		}
		getTrailerInfo()
		return () => {
			dispatch(setTrailerInfo(null))
		}
	}, [project])

	const disabledSocialBtns = isDraft || isOwnerSignedIn()
	const disableShareLink =
		configuration?.enableProjectShare === 0 || project?.stageId !== 4

	return {
		loading,
		isDraft,
		isGoScoreV2Enabled,
		project,
		userReview,
		storyRef,
		showMore,
		trailerInfo,
		storyHeight,
		disableShareLink,
		disabledSocialBtns,
		buildCategoriesLine,
		handleSocialClick,
		showMoreToggle,
		showGreenlight,
		showImpact
	}
}

export default useDashboardTab
