import { useState } from 'react'
import { useSelector } from 'react-redux'
import {
	getUserFullTiersMetricsService
} from 'services/daoTier.service'
import useAxiosFetch from 'hooks/useAxiosFetch'
import _ from 'lodash'

export default function useTierInfoMenu() {
	const { user: signInUser } = useSelector(state => state.signIn)
	const [daoTierInfo, setDaoTierInfo] = useState({
		daoTier: {},
		nextDaoTier: {},
		userMetrics: {}
	})

	const [{ loading: loadingTierInfo }] = useAxiosFetch(
		getUserFullTiersMetricsService(true),
		(response, error) => {
			if (!error) {
				const tiers = response.tiers.map(tier => {
					const metrics = tier.metrics.reduce((acc, curr) => {
						acc[curr.name] = curr.threshold
						return acc
					}, {})
					return {
						id: tier?.id,
						image: tier?.image,
						title: tier?.name,
						benefits: tier?.benefits,
						date: tier?.tierUser?.achievedDate,
						completed: tier?.tierUser?.completed === 1,
						confirmed: tier?.tierUser?.confirmed === 1,
						metrics
					}
				})

				const {
					projectsReviewed,
					stakesTotal,
					projectsFollowed,
					refers,
					isVerified
				} = response

				const userMetrics = {
					Review: projectsReviewed,
					Stake: stakesTotal,
					Follow: projectsFollowed,
					Refer: refers,
					'Is User Verified': isVerified,
					'Setup Platform Wallet': signInUser?.isEmailVerified === 1 && !_.isNil(signInUser?.walletAddress) ? 1 : 0
				}
				const daoTier = tiers.find(el => el?.title === signInUser?.daoTierName)
				const nextDaoTier = tiers.find(el => el?.title === signInUser?.nextTierName)
				setDaoTierInfo({
					daoTier,
					nextDaoTier,
					userMetrics
				})
			}
		}
	)

	return {
		signInUser,
		daoTierInfo,
		loadingTierInfo
	}
}
