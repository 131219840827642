import { Box, Grid, Typography, Icon } from '@mui/material'
import PropTypes from 'prop-types'
import { CustomDialog } from 'component/common'
import GoScore from '../goScore/GoScore'
import styles from './ScoreDialog.module.scss'
import scoreDetailImages from 'assets/images/score-details'
import { GoScoreImpactMetricBar } from '../goScoreImpactMetricBar'
import { useSelector } from 'react-redux'
import { useState } from 'react'

export const ScoreDialog = ({ open, handleClose, data }) => {
	const { project } = useSelector(state => state.projectDetail)
	const [sectionHover, setSectionHover] = useState(null)

	const topPercent = Math.round(100 - data?.percentile) + 1

	return (
		<CustomDialog {...{ open, handleClose, variant: 'score_detail' }}>
			<Grid container className={styles.score_detail} spacing={2}>
				<Grid item xs={12} className={styles.title} container>
					<Typography component={'h1'}>Go Score Detail </Typography>
				</Grid>
				<Grid container item xs={12}>
					<Grid item xs={6} className={styles.legend}>
						<Box className={styles.legend_item}>
							<Box className={styles.bg_green}></Box>
							<Typography>Staking</Typography>
						</Box>
						<Box className={styles.legend_item}>
							<Box className={styles.bg_red}></Box>
							<Typography>Social</Typography>
						</Box>
						<Box className={styles.legend_item}>
							<Box className={styles.bg_blue}></Box>
							<Typography>Project</Typography>
						</Box>
					</Grid>
					<Grid
						item
						xs={6}
						justifyContent={'center'}
						container
						className={styles.goScoreModal}
					>
						<GoScore
							{...{
								color: '#3FA8F4',
								showText: false
							}}
						/>
					</Grid>
					<Grid item xs={12} container>
						<Typography className={styles.explainerHeader}>
							GoScore Impact
						</Typography>
						<Typography className={styles.explainer}>
							This is a visual breakdown of how each metric category impacts the
							make up the GoScore.
						</Typography>
						<GoScoreImpactMetricBar {...{ info: data, setSectionHover }} />
					</Grid>
					<Grid item xs={12} className={styles.info_container}>
						<Box className={`${styles.info_item}`}>
							<Box className={styles.info_title}>
								<img src={scoreDetailImages.tokens} />
								<Typography className={styles.green}>Staked Tokens</Typography>
							</Box>
							<Typography className={styles.green}>
								{project?.stakesTotal}
							</Typography>
						</Box>
						<Box className={styles.info_item}>
							<Box className={styles.info_title}>
								<Icon baseClassName="fa-kit" className="fa-fan-stake" />
								<Typography className={styles.green}>Unique Stakers</Typography>
							</Box>
							<Typography className={styles.green}>
								{project?.stakersCount}
							</Typography>
						</Box>
						<Box className={`${styles.info_item}`}>
							<Box className={styles.info_title}>
								<img src={scoreDetailImages.comments} />
								<Typography className={styles.red}>Followers</Typography>
							</Box>
							<Typography className={styles.red}>
								{project?.followersCount}
							</Typography>
						</Box>
						<Box
							className={`${styles.info_item} ${
								sectionHover === 1 ? styles.active : ''
							}`}
						>
							<Box className={styles.info_title}>
								<img src={scoreDetailImages.shares} />
								<Typography className={styles.red}>Referrals</Typography>
							</Box>
							<Typography className={styles.red}>
								{project?.socialMediaShares}
							</Typography>
						</Box>
						<Box
							className={`${styles.info_item} ${
								sectionHover === 2 ? styles.active : ''
							}`}
						>
							<Box className={styles.info_title}>
								<img src={scoreDetailImages.ratings} />
								<Typography className={styles.blue}>Ratings/reviews</Typography>
							</Box>
							<Typography className={styles.blue}>
								{project?.reviewsCount}
							</Typography>
						</Box>
						<Box className={`${styles.info_item}`}>
							<Box className={styles.info_title}>
								<img src={scoreDetailImages.views} />
								<Typography className={styles.blue}>Views</Typography>
							</Box>
							<Typography className={styles.blue}>{project?.views}</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} className={styles.ranking}>
						<Typography>
							Project Rank{' '}
							<span>
								{data?.rank} of {data?.projectCount}
							</span>
						</Typography>
						<Typography>
							🔥 This project is in the <span>top {topPercent}%</span>
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</CustomDialog>
	)
}

ScoreDialog.propTypes = {
	open: PropTypes.bool,
	handleClose: PropTypes.func,
	data: PropTypes.object
}