import _ from 'lodash'
import { Link, useLocation } from 'react-router-dom'
import { Icon, Menu, Button, Typography, Box, Fade } from '@mui/material'
import { formatDateToRelative } from 'utils/date.utils'
import useNotifications from './useNotifications'
import reactions from 'assets/images/reactions'
import styles from './Notifications.module.scss'
import { useEffect } from 'react'

export default function Notifications() {
	const location = useLocation()
	const {
		user,
		anchorEl,
		notifications,
		getUnvisiteNotificationCount,
		notificationsCount,
		handleClose,
		handleOpen
	} = useNotifications()

	const renderNotifications = () => {
		return notifications.rows.map(n => {
			let base = ''
			const inProgress = Number(n?.inProgress) === 1
			const icon = _.get(n, ['type', 'icon'], 'info')
			const iconSx = n?.levelUpImage
				? { backgroundImage: `url( ${n?.levelUpImage} )`, color: '#000' }
				: {}
				
			if ([3, 4].includes(n?.typeId)) { base = 'fas' }
			if ([7,8,9].includes(n?.typeId)) { base = 'fal' }
			if ([1,2,5,6,10,11].includes(n?.typeId)) { base = 'fa-kit' }
			
			let description = n.description ?? ''
			let link = `/profile/${user.username}?tab=4`
			if (n.typeId === 9) {
				const splitDesc = description.split('$$reaction')
				const reactionIcon = _.get(n, 'newsreelReaction.reaction.icon', '')
				description = (
					<>
						{splitDesc[0]} <img src={reactions[reactionIcon]} /> {splitDesc[1]}
					</>
				)
			}
			if ([7, 8, 9].includes(n?.typeId)) {
				const linkType = _.isNil(n?.newsreel?.projectId) ? 'profile' : 'project'
				const slug = _.isNil(n?.newsreel?.projectId)
					? n?.newsreel?.user?.username
					: n?.newsreel?.project.slug
				link = `/${linkType}/${slug}?tab=1&newsreelId=${n?.newsreelId}`
			}
			return (
				<li className={styles.notification_item} key={n.id}>
					<Link to={link} onClick={handleClose}>
						<Icon baseClassName={base} className={`fa-${icon}`} sx={iconSx} />
						{inProgress && <Box className={styles.dot}></Box>}
						<Box className={styles.column}>
							<Box>
								<Typography className={styles.name}>{n.name}</Typography>
								<Typography className={styles.date}>
									{formatDateToRelative(n.createdAt)}
								</Typography>
							</Box>
							<Typography className={styles.description}>
								{description}
							</Typography>
						</Box>
					</Link>
				</li>
			)
		})
	}

	const btnClasses =
		notificationsCount === 0
			? `${styles.notification_btn} ${styles.disabled}`
			: styles.notification_btn

	useEffect(() => {
		getUnvisiteNotificationCount()
	}, [location])

	return (
		<>
			<Button size="small" className={btnClasses} onClick={handleOpen}>
				<Icon baseClassName="fas" className="fa-regular fa-bell" />
				{notificationsCount}
			</Button>

			<Menu
				classes={{ paper: styles.menu_popup }}
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				TransitionComponent={Fade}
			>
				<Typography component={'h2'}>Notifications</Typography>
				{renderNotifications()}
			</Menu>
		</>
	)
}
