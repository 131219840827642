import _ from 'lodash'
import PropTypes from 'prop-types'
import {
	Typography,
	TableSortLabel,
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Icon,
	Button
} from '@mui/material'
import { Link } from 'react-router-dom'
import { visuallyHidden } from '@mui/utils'
import { formatDate } from 'utils/date.utils'
import useActivityTable from './useActivityTable'
import { Search } from 'component/common'
import { getTransactionLink } from 'utils/utils'
import { NumericFormat } from 'react-number-format'
import useStakesTable from 'component/projectDetail/stakesTab/stakesTable/useStakesTable'
import styles from './ActivityTable.module.scss'

const headCells = [
	{
		id: 'icon',
		label: ''
	},
	{
		id: 'amount',
		label: 'Amount'
	},
	{
		id: 'description',
		label: 'Description'
	},
	{
		id: 'project',
		label: 'Project'
	},
	{
		id: 'createdAt',
		label: 'Time Stamp'
	},
	{
		id: 'status',
		label: 'Status'
	},
	{
		id: 'blockchainHash',
		label: 'Transaction'
	},
	{
		id: 'actions',
		label: 'Actions'
	}
]

function EnhancedTableHead({ order, orderBy, onRequestSort }) {
	const createSortHandler = property => event => {
		onRequestSort(event, property)
	}

	return (
		<TableHead>
			<TableRow>
				{headCells.map(headCell => {
					return (
						<TableCell
							key={headCell.id}
							align={headCell.align}
							padding={'none'}
							sortDirection={orderBy === headCell.id ? order : false}
						>
							{!['icon', 'actions'].includes(headCell.id) && (
								<TableSortLabel
									active={orderBy === headCell.id}
									direction={orderBy === headCell.id ? order : 'asc'}
									onClick={createSortHandler(headCell.id)}
								>
									{headCell.label}
									{orderBy === headCell.id ? (
										<Box component="span" sx={visuallyHidden}>
											{order === 'desc'
												? 'sorted descending'
												: 'sorted ascending'}
										</Box>
									) : null}
								</TableSortLabel>
							)}
						</TableCell>
					)
				})}
			</TableRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	isFraud: PropTypes.bool
}

export default function ActivityTable() {
	const {
		only,
		pagin,
		order,
		search,
		orderBy,
		isFraud,
		emptyRows,
		transactions,
		retyingTransaction,
		handleStakesOnlyClick,
		handleEarningsOnlyClick,
		handleChangeRowsPerPage,
		handleReconcileBlockchainTransaction,
		handleRequestSort,
		handleChangePage,
		setSearch
	} = useActivityTable()

	const {
		handleUnstake
	} = useStakesTable()

	const renderRows = (data = []) => {
		return data.map(row => {
			const {
				id,
				type,
				amount,
				typeId,
				statusId,
				blockchainHash,
				createdAt,
				isCredit,
				project,
				daoTier,
				status
			} = row
			const isDaoTierBonus = [10, 13].includes(typeId)
			const typeName = isDaoTierBonus
				? `${type?.name}: ${daoTier?.name}`
				: type?.name
			const hash =
				statusId === 3 && _.isNil(blockchainHash) ? (
					<Button
						className={styles.retry}
						onClick={handleReconcileBlockchainTransaction(row)}
						disabled={!!retyingTransaction}
					>
						{retyingTransaction === id ? 'Retrying...' : 'Retry'}
					</Button>
				) : (
					getTransactionLink(blockchainHash)
				)

			const enableUnstake = !_.isNil(hash) && statusId === 2 && typeId === 4

			const iconClass = isCredit
				? `fa-arrow-up-right ${styles.green}`
				: `fa-arrow-down-right ${styles.red}`
			return (
				<TableRow key={id}>
					<TableCell scope="row" width="3%" align="left">
						{isFraud
							? <span className={styles.fraud_icon}>
								<Icon
									baseClassName="fal"
									className="fa-diamond-exclamation"
									fontSize={'1rem'}
								/>
							</span>
							: <Icon baseClassName="fal" className={iconClass} />}
					</TableCell>
					<TableCell align="left" width="14%">
						<NumericFormat
							value={amount}
							displayType="text"
							thousandSeparator=","
						/>
					</TableCell>
					<TableCell align="left" width="16%">
						{typeName}
					</TableCell>
					<TableCell align="left" width={'24%'}>
						<Link to={`/project/${project?.slug}`}>{project?.title}</Link>
					</TableCell>
					<TableCell align="left" width="12%">
						{formatDate(createdAt, 'med')}
					</TableCell>
					<TableCell align="left" width="12%">
						{status?.name}
					</TableCell>
					<TableCell align="left" width="14%" maxwidth="18%">
						{hash}
					</TableCell>
					<TableCell align="left" width="18%" maxwidth="18%">
						<Button
							className={styles.unstakeButton}
							onClick={handleUnstake(id, amount, project)}
							disabled={!enableUnstake}
						>
							Unstake
						</Button>
					</TableCell>
				</TableRow>
			)
		})
	}

	return (

		<>
			<Box className={styles.activity_table}>
				<Box className={styles.header}>
					<Typography className={styles.title}>Recent Activity</Typography>
					<Box className={styles.only_container}>
						<Typography
							className={`${styles.view_only} ${only.stake && styles.selected}`}
							component="span"
							onClick={handleStakesOnlyClick}
						>
							View only stakes
						</Typography>
						<Typography
							className={`${styles.view_only_earnings} ${only.earnings && styles.selected
								}`}
							component="span"
							onClick={handleEarningsOnlyClick}
						>
							View only earnings
						</Typography>
					</Box>
					<Search
						{...{
							setSearch,
							search,
							placeholder: 'Search Transactions',
							enableAutoFocus: false
						}}
					/>
				</Box>
				<Box>
					<TableContainer className={styles.table_container}>
						{transactions?.rows?.length === 0 ? (
							<Typography className={styles.title}>
								No found wallet activities yet
							</Typography>
						) : (
							<Table stickyHeader>
								<EnhancedTableHead
									{...{ order, orderBy, onRequestSort: handleRequestSort }}
								/>
								<TableBody>
									{renderRows(transactions?.rows)}
									{emptyRows > 0 && (
										<TableRow
											style={{
												height: 53 * emptyRows
											}}
										>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</TableBody>
							</Table>
						)}
					</TableContainer>
					{transactions?.rows?.length > 0 && (
						<TablePagination
							rowsPerPageOptions={[6, 18, 60]}
							component="div"
							count={transactions?.count || 0}
							rowsPerPage={pagin.limit}
							classes={{ root: styles.pagination }}
							page={pagin.page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					)}
				</Box>
			</Box>
		</>
	)
}
