import _ from 'lodash'
import { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import useNotification from './useNotification'
import { notificationTypes } from 'component/common'
import useLoadingMask from './useLoadingMask'
import { useNavigate } from 'react-router-dom'

const useAxiosFetch = (
	initialConfig,
	callback,
	showError = true,
	axiosInstance
) => {
	const [response, setResponse] = useState(null)
	const [error, setError] = useState('')
	const [loading, setLoading] = useState(Boolean(initialConfig.autoexec))
	const controller = useRef(null)
	const { showNotification } = useNotification()
	const { hideMask } = useLoadingMask()
	const navigate = useNavigate()
	const isMounted = useRef(true)

	useEffect(() => {
		return () => {
			isMounted.current = false
		}
	}, [])

	const includeInFormData = (config, extraConfig) => {
		if (_.get(config, ['headers', 'Content-Type']) === 'multipart/form-data') {
			Object.keys(extraConfig).forEach(key => {
				config.data.set(key, extraConfig[key])
			})
		}
		return config
	}

	const axiosFetch = async (extraConfig = {}) => {
		if (!isMounted.current) return

		setLoading(true)
		controller.current = new AbortController()

		const axiosClient = axiosInstance || axios
		let finalConfig = _.merge({}, initialConfig, extraConfig) // Avoid mutating the original config

		if (
			_.get(finalConfig, ['headers', 'Content-Type']) === 'multipart/form-data'
		) {
			finalConfig = includeInFormData(finalConfig, extraConfig)
		}

		try {
			const res = await axiosClient({
				...finalConfig,
				signal: controller.current.signal
			})
			if (isMounted.current) {
				setResponse(res)
				return res
			}
		} catch (err) {
			if (isMounted.current) {
				setResponse(err.response)
				setError(err.message) // Set a user-friendly message or specific part of the error
			}
		} finally {
			if (isMounted.current) setLoading(false)
		}
	}

	const cancelCall = () => {
		controller.current && controller.current.abort()
	}

	useEffect(() => {
		if (initialConfig?.autoexec) {
			axiosFetch()
		}
		return () => cancelCall()
	}, [initialConfig.autoexec])

	useEffect(() => {
		if (!response) {
			return
		}

		const notFound = response?.status === 404
		if (notFound) {
			navigate('/notfound')
			hideMask()
			return
		}

		const existError = response?.status !== 200
		callback && callback(response.data, existError, error)
		if (response?.data?.message && showError && existError) {
			showNotification({
				title: response?.data?.title,
				message: response.data.message,
				type: notificationTypes.ERROR
			})
			hideMask()
		}

		return () => cancelCall()
	}, [response, showError, error])

	return [{ response, error, loading }, axiosFetch, cancelCall]
}

export default useAxiosFetch
