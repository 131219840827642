import { Box, Button, Dialog, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setClaimWalletDialogOpen } from '../../walletTabSlice'
import fgrBack from 'assets/images/fgr-back.png'
import exclamationIcon from 'assets/images/exclamation_icon.png'
import styles from './ClaimWalletDialog.module.scss'
import useAxios from 'hooks/useAxios'
import { claimNcWalletService } from 'services/wallet.service'
import useLoadingMask from 'hooks/useLoadingMask'

const env = process.env.REACT_APP_ENVIRONMENT || 'development'

export const ClaimWalletDialog = () => {
	const { axiosService } = useAxios()
	const { claimWalletDialogOpen: open } = useSelector(
		state => state.walletTab
	)
	const dispatch = useDispatch()
	const { showMask, hideMask } = useLoadingMask()

	const claimNcWallet = () => {
		showMask()
		axiosService(claimNcWalletService(), (result, error) => {
			dispatch(setClaimWalletDialogOpen(false))
			hideMask()
		})
	}

	const handleClose = () => {
		dispatch(setClaimWalletDialogOpen(false))
	}

	const suffix = {
		development: '-dev',
		stage: '-stage',
		production: ''
	}

	const link = `https://reveal.magic.link/filmio${suffix[env]}`

	return (
		<Dialog
			open={open}
			disableScrollLock={true}
			className={styles.claim_wallet_dialog}
			modal="true"
			classes={{
				paper: styles.dialog_paper,
				container: styles.dialog_container
			}}
			onClose={handleClose}
		>
			<Box className={styles.header}>
				<img src={fgrBack} className={styles.header_background} />
				<img src={exclamationIcon} className={styles.exclamationIcon}/>
			</Box>
			<Box className={styles.content}>
				<Typography component={'h1'}>What Comes Next?</Typography>
					<Typography>
						To claim your wallet, follow these steps:
						<ol>
							<li>Click proceed below to be taken to a page that will ask you to sign in. This uses the same email that you sign in to Film.io with.</li>
							<li>Read and agree to the terms presented and click to reveal your private key.</li>
							<li>
								Once you have this key, you will import it into your wallet application, which will import your wallet. You can find instructions for MetaMask
								<a href='https://support.metamask.io/hc/en-us/articles/360015489331-How-to-import-an-account' target="_blank" rel="noreferrer">{' '}here</a>.
							</li>
						</ol>
					</Typography>
					<Typography>
						<strong>Please Note: You will continue to login with your email after importing your wallet into a 3rd party wallet.</strong>
					</Typography>
					<Box className={styles.controls}>
						<Button onClick={handleClose} className={styles.cancel}>Cancel</Button>
						<Button>
							<a href={link} target="_blank" rel="noreferrer" onClick={claimNcWallet}>
								Proceed
							</a>
						</Button>
					</Box>
			</Box>
			<Box className={styles.dialog_background}></Box>
		</Dialog>
	)
}
