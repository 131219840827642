import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './CircularProgressBar.module.scss'

const CircularProgressBar = ({
	value,
	time,
	background,
	lineColor,
	lineEmptyColor,
	lineWidth,
	height,
	classes,
	children
}) => {
	const [percent, setPercent] = useState(0)
	const circularProgress = useRef(null)

	const speed = (time * 1000) / value
	useEffect(() => {
		if (percent < value && percent < 100) {
			setTimeout(() => {
				setPercent(prev => prev + 1)
			}, speed)
		}
	}, [percent, value])

	useEffect(() => {
		setPercent(0)
	}, [value])

	return (
		<div
			className={`${styles.circular_progress} ${classes}`}
			ref={circularProgress}
			style={{
				background: `conic-gradient(${lineColor} ${
					percent * 3.6
				}deg, ${lineEmptyColor} 0deg)`,
				height,
				width: height
			}}
		>
			<div
				className={styles.circular_progress_before}
				style={{
					height: height - lineWidth,
					width: height - lineWidth,
					background
				}}
			>
				{children}
			</div>
		</div>
	)
}

CircularProgressBar.propTypes = {
	value: PropTypes.number,
	time: PropTypes.number,
	background: PropTypes.string,
	lineEmptyColor: PropTypes.string,
	lineWidth: PropTypes.number,
	height: PropTypes.number,
	lineColor: PropTypes.string,
	classes: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
}

export default CircularProgressBar
