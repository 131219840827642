import { useState } from 'react'
import {
	Icon,
	IconButton,
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel
} from '@mui/material'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import { visuallyHidden } from '@mui/utils'
import { useNavigate } from 'react-router-dom'
import { formatDate } from 'utils/date.utils'
import useTileManagement from '../useTileManagement'
import { deleteTile, updateTileService } from 'services/tile.service'
import useNotification from 'hooks/useNotification'
import axios from 'axios'
import { notificationTypes } from 'component/common'

const headCells = [
	{
		id: 'icon',
		numeric: false,
		disablePadding: true,
		label: ''
	},
	{
		id: 'tile',
		numeric: true,
		disablePadding: false,
		label: 'Tile'
	},
	{
		id: 'type',
		numeric: true,
		disablePadding: false,
		label: 'type'
	},
	{
		id: 'starts',
		numeric: true,
		disablePadding: false,
		label: 'Starts'
	},
	{
		id: 'ends',
		numeric: true,
		disablePadding: false,
		label: 'Ends'
	},

	{
		id: 'clicks',
		numeric: false,
		disablePadding: false,
		label: 'Clicks'
	},

	{
		id: 'shares',
		numeric: false,
		disablePadding: false,
		label: 'Shares'
	},
	{
		id: 'actions',
		numeric: false,
		disablePadding: false,
		label: 'Actions'
	}
]

function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort } = props
	const createSortHandler = property => event => {
		onRequestSort(event, property)
	}

	return (
		<TableHead>
			<TableRow>
				{headCells.map(headCell => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? 'left' : 'left'}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired
}

const TileAction = ({ icon, link = '#', onClick }) => {
	const navigate = useNavigate()
	const goToLink = () => navigate(`/${link}`)
	return (
		<IconButton size="small" onClick={onClick || goToLink}>
			<Icon baseClassName="fas" className={`fa-light ${icon}`} />
		</IconButton>
	)
}

TileAction.propTypes = {
	icon: PropTypes.string,
	link: PropTypes.string,
	onClick: PropTypes.func
}

export default function TileTable({ tiles = [], type }) {
	const [order, setOrder] = useState('asc')
	const [orderBy, setOrderBy] = useState('calories')
	const { getTiles } = useTileManagement(false)
	const { showNotification } = useNotification()

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	const handleDeleteTile = id => async () => {
		const response = await deleteTile(id)
		showNotification({ message: response?.data?.message })
		getTiles()
	}

	const handleExpireTile = id => async () => {
		const response = await axios(
			updateTileService({
				id,
				isExpired: true
			})
		)

		if (response.status === 200) {
			showNotification({ message: 'Tile expired sucessfuly' })
			getTiles()
		} else {
			showNotification({ type: notificationTypes.ERROR })
		}
	}

	const getTileType = typeId => {
		const typeMap = {
			1: 'Static',
			2: 'Link',
			3: 'DAO Proposal'
		}
		return typeMap[typeId]
	}

	return (
		<Box sx={{ width: '100%' }}>
			<Paper
				sx={{ width: '100%', mb: 2 }}
				className="wallet-table earning-table"
			>
				<TableContainer>
					<Table
						sx={{ minWidth: 750 }}
						aria-labelledby="tableTitle"
						size="medium"
					>
						<EnhancedTableHead
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
						/>
						<TableBody>
							{tiles.map(tile => {
								const labelId = `row-${tile.id}`
								const startDate = formatDate(tile.startDate)
								const endDate = tile.endDate
									? formatDate(tile.endDate)
									: 'Never'
								const showEdit = type === 'active' || type === 'scheduled'
								const showExpire = type === 'active'
								const showDelete = type === 'scheduled'
								return (
									<TableRow key={tile.id}>
										<TableCell
											id={labelId}
											scope="row"
											padding="none"
											width="5%"
										>
											<i className="fa-light fa-link"></i>
										</TableCell>
										<TableCell width="20%" className="link-contain">
											{tile.name}
										</TableCell>
										<TableCell width="5%">
											{getTileType(tile.tileTypeId)}
										</TableCell>
										<TableCell width="15%">{startDate}</TableCell>
										<TableCell width="15%">{endDate}</TableCell>
										<TableCell width="10%">{tile.clicks}</TableCell>
										<TableCell width="15%">{tile.socialShares}</TableCell>
										<TableCell align="right" width="15%">
											<Box className="link-group">
												{showExpire && (
													<TileAction
														{...{
															icon: 'fa-ghost',
															onClick: handleExpireTile(tile.id)
														}}
													/>
												)}
												{showEdit && (
													<TileAction
														{...{
															icon: 'fa-pen-to-square',
															link: `tile/${tile?.id}`
														}}
													/>
												)}
												{showDelete && (
													<TileAction
														{...{
															icon: 'fa-trash-alt',
															onClick: handleDeleteTile(tile.id)
														}}
													/>
												)}
											</Box>
										</TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</Box>
	)
}

TileTable.propTypes = {
	tiles: PropTypes.array,
	type: PropTypes.string
}
