import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { resetNewsreels, setNewsreelPage, updateNewsreels } from './newsreelTabSlice'
import {
	getNewsreelsByFeatureIdService,
	getNewsreelsByIdService
} from 'services/newsreel.service'
import { NEWSREEL_FEATURES } from './NewsreelTab'
import { useEffect, useState } from 'react'
import { setUserId } from 'component/profile/profileSlice'
import { getProfileByUsername } from 'services/profile.service'
import useAxios from 'hooks/useAxios'
import { useParams, useSearchParams } from 'react-router-dom'
import useLoadingMask from 'hooks/useLoadingMask'

const useNewsreelTab = feature => {
	const dispatch = useDispatch()
	const [loadingNewsreels, setLoadingNewsreels] = useState(false)
	const [loadMore, setLoadMore] = useState(true)
	const { newsreels, page } = useSelector(state => state.newsreelTab)
	const { user: signInUser } = useSelector(state => state.signIn)
	const { userId, user } = useSelector(state => state.profile)
	const { project, projectId, rolePermissions } = useSelector(
		state => state.projectDetail
	)
	const { axiosService } = useAxios()
	const { username, slug } = useParams()
	const { hideMask, showMask } = useLoadingMask()
	const [searchParams, setSearchParams] = useSearchParams()

	const newsreelId = searchParams.get('newsreelId')
	const isProfile = feature === NEWSREEL_FEATURES.profile
	const isProject = feature === NEWSREEL_FEATURES.project
	const isNotNewsreelsOwner =
		(isProfile && signInUser.id !== userId) ||
		(isProject && signInUser.id !== project.userId)

	const getUserId = () => {
		axiosService(getProfileByUsername(username), (data, error) => {
			if (!error) {
				dispatch(setUserId(data?.id))
			}
		})
	}

	const fetchNewsreels = () => {
		showMask()
		setLoadingNewsreels(true)

		const FEATURE_MAP = {
			[NEWSREEL_FEATURES.project]: getNewsreelsByFeatureIdService({ projectId, page: page?.value }),
			[NEWSREEL_FEATURES.profile]: getNewsreelsByFeatureIdService({ userId, page: page?.value })
		}

		const getNewsreels = _.isNil(newsreelId)
			? FEATURE_MAP[feature]
			: getNewsreelsByIdService(newsreelId)

		axiosService(getNewsreels, async (data, error) => {
			if (!error) {
				dispatch(updateNewsreels(data))
				setLoadMore(_.isNil(newsreelId)? data?.length > 0: false)
			}
			setLoadingNewsreels(false)
			hideMask()
		})
	}

	const handleSocialClick = tab => () => {
		setSearchParams({ tab })
	}

	const onScroll = () => {
		if (
			window.innerHeight + window.scrollY >= document.body.offsetHeight - 10 &&
			loadMore &&
			!loadingNewsreels
		) {
			dispatch(setNewsreelPage(page?.value + 1))
		}
	}

	const haveNewsreelPermissions = () =>
		rolePermissions.some(el => el?.permissionId === 2)

	useEffect(() => {
		if ((!_.isNil(userId) && isProfile) || (!_.isNil(slug) && !_.isNil(projectId) && isProject)) {
			fetchNewsreels()
		}
	}, [userId, slug, page, projectId, newsreelId])

	useEffect(() => {
		if (isProfile && !userId) {
			getUserId()
			return () => {
				dispatch(setUserId(null))
			}
		}
	}, [feature])

	useEffect(() => {
		if (page !== 0) dispatch(setNewsreelPage(0))
		return () => {
			dispatch(resetNewsreels())
		}
	}, [])

	const emptyMessage =
		isNotNewsreelsOwner && !haveNewsreelPermissions()
			? 'There are no Newsreel posts. You will be able to join the discussion as soon as the owner of the page creates one!'
			: 'Create a post so that your followers can join your conversation!'

	const isOneNewsreelView = !_.isNil(newsreelId)
	const hideAddNewsreelForm =
		(isNotNewsreelsOwner || isOneNewsreelView) && !haveNewsreelPermissions()
	return {
		user,
		loadMore,
		isProject,
		isProfile,
		newsreels,
		newsreelId,
		emptyMessage,
		loadingNewsreels,
		handleSocialClick,
		hideAddNewsreelForm,
		isOneNewsreelView,
		onScroll
	}
}

export default useNewsreelTab
