import { Box } from '@mui/material'
import { TextFieldControl, CustomButton, CustomDialog } from 'component/common'
import useCreateMagicWalletDialog from './useCreateMagicWalletDialog'
import styles from './CreateMagicWalletDialog.module.scss'

export const CreateMagicWalletDialog = () => {
	const {
		open,
		email,
		errors,
		emailChangeHandler,
		handleKeypress,
		handleSubmit,
		handleClose
	} = useCreateMagicWalletDialog()

	return (
		<CustomDialog {...{ open, handleClose, variant: 'magicCreate' }}>
			<Box className={styles.header}>
				Claim Platform Wallet
				<span>Verify your email to create your platform wallet, which is used to transact and earn rewards</span>
			</Box>
			<Box className={styles.content}>
				<Box className={styles.fields}>
					<TextFieldControl
						error={errors.email}
						hiddenLabel
						fullWidth
						name="username"
						placeholder="Email"
						value={email}
						onKeyPress={handleKeypress}
						onChange={emailChangeHandler}
						className={styles.emailField}
					/>
				</Box>
				<Box className={styles.actions}>
					<CustomButton
						customStyle={'blue_wo_border'}
						onClick={handleSubmit}
					>
						Next
					</CustomButton>
					<CustomButton
						customStyle={'cancel_dark_blue'}
						onClick={handleClose}
					>
						Cancel
					</CustomButton>
				</Box>
			</Box>
		</CustomDialog>
	)
}
