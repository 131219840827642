import { Box } from '@mui/material'
import { LoadMask } from 'component/common'
import { AidropJoinDialog } from 'component/header/aidropJoinDialog'
import { updateMetaTags } from 'utils/utils'
import { CommunitySection } from './communitySection'
import { DaoSection } from './daoSection'
import { ExploreSection } from './exploreSection'
import styles from './Home.module.scss'
import { PressSection } from './pressSection'
import { ProjectSection } from './projectSection'
import { StartSection } from './startSection'
import useHome from './useHome'

const Home = () => {
	const { loading } = useHome()

	updateMetaTags({})

	return (
		<>
			<AidropJoinDialog />
			{loading ? (
				<LoadMask />
			) : (
				<Box className={styles.home}>
					<ExploreSection />
					<DaoSection />
					<ProjectSection />
					<StartSection />
					<PressSection />
					<CommunitySection />
				</Box>
			)}
		</>
	)
}

export default Home
