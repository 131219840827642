import _ from 'lodash'
import { createRef, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { reloadStakes } from 'component/projectDetail/projectDetailSlice'
import {
	addBlockchainStakeService,
	addStakeService,
	getUserStakeLimitService
} from 'services/stake.service'
import useNotification from 'hooks/useNotification'
import { notificationExtTypes } from 'component/common'
import useAxiosFetch from 'hooks/useAxiosFetch'
import useHeader from 'component/header/useHeader'
import useStakesTable from '../stakesTable/useStakesTable'
import { reloadRecentActivity } from 'component/profile/walletTab/walletTabSlice'
import useAxios from 'hooks/useAxios'
import useNotifications from 'component/header/notifications/useNotifications'
import { Mixpanel } from 'services/mixpanel.service'
import { StakingContract } from 'utils/blockchain/functions'
import { setCreatePlatformWalletDialogOpen, setFrontTransactionsInProgress } from 'component/header/signInSlice'
import useLoadingMask from 'hooks/useLoadingMask'
import useCommon from 'hooks/useCommon'
import { reloadDaoTiers } from 'component/profile/profileSlice'
import { truncateToDecimals } from 'utils/form.util'

export const useAddStakeForm = () => {
	const dispatch = useDispatch()
	const { user: signInUser } = useSelector(
		state => state.signIn
	)
	const { project, projectId } = useSelector(state => state.projectDetail)
	const [stakeInProgress, setStakeInProgress] = useState(false)
	const [amount, setAmount] = useState('')
	const [errors, setErrors] = useState(null)
	const [disableStake, setDisableStake] = useState(false)
	const [limit, setLimit] = useState(Number.MAX_SAFE_INTEGER)
	const [stakeLimit, setStakeLimit] = useState(0)
	const [failedStakesTotal, setFailedStakesTotal] = useState(0)
	const {
		showNotificationExtended,
		completeNotificationExtended,
		closeNotificationExtended
	} = useNotification()
	const { getProjectStakes } = useStakesTable()
	const { loadUserTierInfo, relaodUserInfo } = useHeader()
	const { axiosService } = useAxios()
	const { getUnvisiteNotificationCount } = useNotifications()
	const inputRef = createRef()
	const { showMask, hideMask } = useLoadingMask()
	const { nonCustodialWithoutMatic, checkNcNoMagic } = useCommon()
	const isProjectOwner = signInUser?.id === project?.user?.id

	const addStakeMixPanelEvent = transactionId => {
		Mixpanel.track('usr_stake_added_evt', {
			distinct_id: signInUser.id,
			amount,
			transactionId,
			displayName: signInUser.displayName,
			id: signInUser.id,
			username: signInUser.username
		})
	}

	const addFailStakeMixPanelEvent = transactionId => {
		Mixpanel.track('transaction_failed_stake_evt', {
			distinct_id: signInUser.id,
			amount,
			transactionId,
			displayName: signInUser.displayName,
			id: signInUser.id,
			username: signInUser.username
		})
	}

	const addBlockchainStake = (transactionId, blockchainHash, amount) => {
		axiosService(
			addBlockchainStakeService({
				transactionId,
				blockchainHash
			}),
			(_data, error) => {
				if (!error) {
					dispatch(reloadStakes())
					loadUserTierInfo()
					getUserStakeLimit()
					if (_.isNil(blockchainHash)) {
						addFailStakeMixPanelEvent(transactionId)
						handleRestoreForm()
						closeNotificationExtended()
					} else {
						completeNotificationExtended()
						addStakeMixPanelEvent(transactionId)
						loadUserTierInfo()
						dispatch(reloadDaoTiers())
					}
				}
				getProjectStakes()
				dispatch(reloadRecentActivity())
				relaodUserInfo()
				getUnvisiteNotificationCount()
				setStakeInProgress(false)
				dispatch(setFrontTransactionsInProgress(false))
				setDisableStake(false)
			}
		)
	}

	const afterCreateStake = async (transaction, error) => {
		if (!error) {
			getProjectStakes()
			getUnvisiteNotificationCount()
			const transactionId = transaction?.id
			dispatch(setFrontTransactionsInProgress(true))
			hideMask()
			showNotificationExtended({
				projectTitle: project?.title,
				total: amount,
				type: notificationExtTypes.STAKING
			})
			const hash = await StakingContract.stakeTokens(
				projectId,
				Number(amount),
				transactionId
			)
			addBlockchainStake(transactionId, hash, transaction?.amount)
		}
	}

	const [{ loading: loadingConfig }, addStake] = useAxiosFetch(
		addStakeService({
			amount,
			projectId
		}),
		afterCreateStake
	)

	const [{ loading: loadingLimit }, getUserStakeLimit] = useAxiosFetch(
		getUserStakeLimitService(projectId),
		(data, error) => {
			if (!error) {
				setLimit(data?.limit)
				setStakeLimit(data?.stakeLimit)
				setFailedStakesTotal(data?.failedStakesTotal ?? 0)
			}
		}
	)

	const handleChangeForm = (value) => {
		const trancatedValue = truncateToDecimals(value, 4)
		setAmount(trancatedValue)
	}
	
	const handleRestoreForm = () => {
		setAmount('')
		inputRef ?? inputRef?.current?.focus()
	}

	const handleStakeClick = (amount) => async () => {
		if (checkNcNoMagic()) {
			dispatch(setCreatePlatformWalletDialogOpen(true))
		} else {
			setDisableStake(true)
			const isNonCustodialWithoutMatic = await nonCustodialWithoutMatic()
			if (isNonCustodialWithoutMatic) { return }
			if (amount === '') {
				setErrors('This field is required')
				setDisableStake(false)
				return
			}
			if (amount < 10) {
				setErrors('The minimun value allowed is 10')
				setDisableStake(false)
				return
			}
			showMask()
			setStakeInProgress(true)
			addStake()
		}
	}

	const handleKeypress = useMemo(() => {
		return _.debounce(e => {
			if (e.charCode === 13 && !isProjectOwner && !disableAddBtn) {
				setDisableStake(true)
				handleStakeClick(amount)()
			} else {
				setErrors(null)
				setDisableStake(false)
			}
		}, 500)
	}, [amount])

	const handleFocus = event => event.target.select()

	const balance = Number(signInUser.balance).toFixed(2)
	const isAbleToStake = Number(signInUser.balance) >= Number(amount)
	const disableAddBtn = disableStake || !isAbleToStake || loadingConfig || limit < amount || stakeInProgress || project?.stageId === 5
	useEffect(() => {
		if (!_.isNil(projectId)) getUserStakeLimit()
	}, [projectId])

	return {
		user: signInUser,
		limit,
		errors,
		amount,
		project,
		balance,
		inputRef,
		stakeLimit,
		failedStakesTotal,
		loadingLimit,
		isAbleToStake,
		disableAddBtn,
		isProjectOwner,
		signInUser,
		handleStakeClick,
		handleRestoreForm,
		handleKeypress,
		handleFocus,
		handleChangeForm
	}
}
