import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { ProjectItem } from 'component/dashboard'
import { CustomButton } from 'component/common/customButton'
import { setLoginDialogOpen } from 'component/header/signInSlice'
import styles from './ProjectSection.module.scss'

const ProjectSection = () => {
	const { projects } = useSelector(state => state.home)
	const { isAuthenticated } = useSelector(state => state.signIn)
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const renderProjects = (items, variant) => {
		return items.map(item => {
			return (
				<ProjectItem
					key={item.id}
					{...{
						...item,
						variant
					}}
				/>
			)
		})
	}

	const handleGoToExplore = () => {
		if (!isAuthenticated) dispatch(setLoginDialogOpen(true))
		else {
			navigate('/explore')
		}
	}

	return (
		<Box className={styles.project}>
			<Box className={styles.content}>
				<Box>
					<Box className={styles.trending}>
						<div className={styles.trendDesc}>
							<h3>
								These are the most popular projects from across the platform.
							</h3>
							<p>
								Right now fans are commenting, sharing, reviewing and staking to
								these projects more than any other on the platform.
							</p>
							<CustomButton customStyle={'blue'} onClick={handleGoToExplore}>
								Explore All Projects
							</CustomButton>
						</div>
						{renderProjects(projects?.trending.slice(0, 3), 'trending')}
					</Box>
				</Box>
				<Box>
					<Box className={styles.most_stakes}>
						{renderProjects(projects?.mostStakes.slice(0, 3))}
						<div className={styles.stakesDesc}>
							<h3>
								These are the most staked projects from across the platform.
							</h3>
							<p>
								Fans stake their tokens, which equates to a vote in the Film.io
								DAO. The more staked tokens a project has, the faster it can be
								greenlit, unlocking powerful new features like funding tools.
							</p>

							<CustomButton
								sx={{ visibility: isAuthenticated ? 'hidden' : 'visible' }}
								customStyle={'outlined'}
								onClick={handleGoToExplore}
							>
								Connect to Stake
							</CustomButton>
						</div>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export default ProjectSection
