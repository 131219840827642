import _ from 'lodash'
import { useState } from 'react'
import { Button } from '@mui/material'
import { TierDialog } from 'component/profile/dashboardTab/daoTiers/tierDialog/TierDialog'
import styles from './ClaimButtonDialog.module.scss'
import useDaoTiers from 'component/profile/dashboardTab/daoTiers/useDaoTiers'
import { useSelector } from 'react-redux'

export default function ClaimButtonDialog() {
	const [open, setOpen] = useState(false)
	const { bronzeTierData, user } = useSelector(state => state.signIn)
	const { getUserDaoTiers } = useDaoTiers()

	const handleClose = () => { setOpen(false) }

	const handleClaimClick = () => {
		getUserDaoTiers()
		setOpen(true)
	}

	const {
		id,
		title: name,
		metrics,
		benefits,
		userMetrics
	} = bronzeTierData

	return <>
		{user?.nextTierName === 'Bronze'
			&& <li>
				<Button className={styles.claim_btn} onClick={handleClaimClick}>
					<i className="fa-sharp fa-regular fa-treasure-chest" />
					<span>Claim Bonus!</span>
				</Button>
			</li>}
		{!_.isEmpty(userMetrics) && <TierDialog
			{...{
				id,
				open,
				name,
				metrics,
				benefits,
				userMetrics,
				handleClose
			}}
		/>}
	</>
}
