import _ from 'lodash'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	reloadRecentActivity,
	setFgrPaging,
	setFgrProjectIdInProgress,
	setProjectsFgr
} from '../walletTabSlice'
import { setCreatePlatformWalletDialogOpen } from 'component/header/signInSlice'
import useAxios from 'hooks/useAxios'
import useLoadingMask from 'hooks/useLoadingMask'
import {
	claimFGRService,
	claimFgrBlockchainService
} from 'services/project.service'
import { getProfileFgrListByProject } from 'services/fgr.service'
import {
	setClaiming,
	showFgrModal
} from 'component/header/claimFgrDialog/claimFgrSlice'
import useHeader from 'component/header/useHeader'
import useNotifications from 'component/header/notifications/useNotifications'
import useNotification from 'hooks/useNotification'
import { Mixpanel } from 'services/mixpanel.service'
import useCommon from 'hooks/useCommon'

const useFgrTable = () => {
	const { axiosService } = useAxios()
	const { hideMask, showMask } = useLoadingMask()
	const { projectsFgr, fgrPagin, fgrProjectIdInProgress } = useSelector(
		state => state.walletTab
	)
	const { user: signInUser } = useSelector(state => state.signIn)
	const { userId } = useSelector(state => state.profile)
	const { claiming } = useSelector(state => state.claimFgr)
	const { checkNcNoMagic } = useCommon()
	const dispatch = useDispatch()
	const { relaodUserInfo, showConfetti } = useHeader()
	const { getUnvisiteNotificationCount } = useNotifications()
	const { showNotification } = useNotification()

	const afterFetchTransactions = (data, error) => {
		if (!error) dispatch(setProjectsFgr(data))
		hideMask()
	}

	const getFgrListByProject = () => {
		showMask()
		axiosService(
			getProfileFgrListByProject({
				limit: fgrPagin.limit,
				offset: fgrPagin.offset
			}),
			afterFetchTransactions
		)
	}

	const claimFgrBlockchain = transactionId => {
		axiosService(claimFgrBlockchainService(transactionId), (data, error) => {
			if (!error) {
				showNotification({
					message: 'You claimed your FGR tokens successfully!'
				})
			}
			getFgrListByProject()
			dispatch(setFgrProjectIdInProgress(null))
			getUnvisiteNotificationCount()
			dispatch(setClaiming(false))
			dispatch(reloadRecentActivity())
			relaodUserInfo()
		})
	}

	const claimFgr = ({ fgrEarned, title, projectId }) => {

		if (checkNcNoMagic()) {
			dispatch(setCreatePlatformWalletDialogOpen(true))
		} else {
			if (fgrEarned < 5) {
				showNotification({
					message: 'To claim FGR you need to have accrued at least 5 FAN tokens.'
				})
			} else {
				if (!claiming) dispatch(setFgrProjectIdInProgress(projectId))
				showMask()
				axiosService(claimFGRService(projectId), (data, error) => {
					if (!error) {
						Mixpanel.track('user_project_fgr_claim_event', {
							distinct_id: signInUser.id,
							displayName: signInUser.displayName,
							username: signInUser.username,
							id: data?.id,
							fgrClaimed: data?.total,
							userId,
							title,
							projectId
						})
						claimFgrBlockchain(data?.id)
						dispatch(showFgrModal({ fgrEarned, title, claiming: true }))
						showConfetti(5000)
						getUnvisiteNotificationCount()
					} else if (!claiming) dispatch(setFgrProjectIdInProgress(null))
					hideMask()
				})
			}
		}
	}

	const handleClaimFgr =
		({ fgrEarned, title, projectId }) =>
			() => {

				claimFgr({ fgrEarned, title, projectId })
			}

	const handleChangePage = (_event, newPage) => {
		const offset = Number(newPage) * Number(fgrPagin.limit)
		dispatch(
			setFgrPaging({
				offset,
				page: newPage
			})
		)
	}

	const handleChangeRowsPerPage = event => {
		dispatch(
			setFgrPaging({
				page: 0,
				offset: 0,
				limit: event.target.value
			})
		)
	}

	const emptyRows =
		fgrPagin.page > 0
			? Math.max(0, (1 + fgrPagin.page) * fgrPagin.limit - projectsFgr.count)
			: 0

	useEffect(() => {
		if (!_.isNil(userId)) {
			getFgrListByProject()
		}
	}, [fgrPagin, userId])

	return {
		pagin: fgrPagin,
		emptyRows,
		projectsFgr,
		fgrProjectIdInProgress,
		handleChangeRowsPerPage,
		handleChangePage,
		handleClaimFgr
	}
}

export default useFgrTable
