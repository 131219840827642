import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	MenuItem,
	Icon,
} from '@mui/material'
import blackPaper from 'assets/pdf/Filmio-Black-Paper-November-3-2023.pdf'
import useHeader from '../../useHeader'
import styles from './TokenSaleMenu.module.scss'
import { setMenuOpen } from 'component/header/signInSlice'

const TokenSaleMenu = () => {
	const { user: signInUser } = useSelector(state => state.signIn)
	const dispatch = useDispatch()
	const { handleLogout } = useHeader()

	const handleClose = () => {
		dispatch(setMenuOpen(false))
	}

	const isTokenSaleWithoutAccess =
		signInUser?.isTokenSale === 1 && signInUser?.appAccess === 0

	return <>
		<MenuItem className={styles.menu_item}>
			<a
				href={blackPaper}
				download="Filmio-Black-Paper-November-3-2023"
				title="Read the Blackpaper"
				target="_blank"
				rel="noreferrer"
				onClick={handleClose}
			>
				<Icon baseClassName="fas" className="fa-regular fa-book" />
				<span>Read the Blackpaper</span>
			</a>
		</MenuItem>
		<MenuItem className={styles.menu_item}>
			<a
				href="https://help.film.io/knowledgebase/welcome/what-is-film.io"
				title="Visit our Knowledgebase"
				target="_blank"
				rel="noreferrer"
				onClick={handleClose}
			>
				<Icon
					baseClassName="fas"
					className="fa-regular fa-circle-question"
				/>
				<span>View the Knowledgebase</span>
			</a>
		</MenuItem>
		<MenuItem className={styles.menu_item}>
			<a
				href="https://filmio.atlassian.net/servicedesk/customer/portal/4"
				title="Submit a Ticket"
				target="_blank"
				rel="noreferrer"
				onClick={handleClose}
			>
				<Icon
					baseClassName="fas"
					className="fa-regular fa-ticket-simple"
				/>
				<span>Submit a Ticket</span>
			</a>
		</MenuItem>
		<MenuItem className={styles.menu_item} onClick={handleLogout}>
			<Link onClick={handleClose}>
				<Icon
					baseClassName="fas"
					className="fa-regular fa-arrow-right-from-bracket"
				/>
				{isTokenSaleWithoutAccess ? (
					<span> Disconnect </span>
				) : (
					<span> Logout </span>
				)}
			</Link>
		</MenuItem>
	</>
}

export default TokenSaleMenu