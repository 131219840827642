import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getProjectActivities } from 'services/project.service'
import ActivityItem from './activityItem/ActivityItem'
import styles from './RecentActivity.module.scss'
import useAxios from 'hooks/useAxios'
import _ from 'lodash'

const RecentActivity = ({ handleSocialClick }) => {
	const [activities, setActivities] = useState([])
	const { project, projectId } = useSelector(state => state.projectDetail)
	const { axiosService } = useAxios()

	const fetchProjectActivities = () => {
		axiosService(getProjectActivities(projectId), (data, error) => {
			if (!error) {
				setActivities(data)
			}
		})
	}

	useEffect(() => {
		if (!_.isNil(projectId)) {
			fetchProjectActivities()
		}
	}, [projectId])

	const renderActivities = () => {
		return activities.map((act, idx) => {
			act.projectTitle = project.title
			return <ActivityItem {...act} key={`activity-${idx}`} />
		})
	}

	return (
		<Box className={styles.activities}>
			{renderActivities()}
			<Box className={styles.footer}></Box>
		</Box>
	)
}

RecentActivity.propTypes = {
	handleSocialClick: PropTypes.func
}

export default RecentActivity
