import _ from 'lodash'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import {
	getProjectGoScoreMilestoneService,
	getProjectGoScoreService,
	getGoScoreConfigService
} from 'services/goscore.service'
import { ScoreDialogV2 } from '../scoreDialogV2'
import stageImages from 'assets/images/stages'
import styles from './GoScoreCircle.module.scss'
import { useSelector } from 'react-redux'
import useAxios from 'hooks/useAxios'
import ActiveIcon from 'assets/images/creatorType/active.svg'
import InActiveIcon from 'assets/images/creatorType/inactive.svg'
import ActiveBlueIcon from 'assets/images/creatorType/activeBlue.svg'
import FireIcon from 'assets/images/creatorType/fire.svg'
import { CircularBar } from '../circularBar'
import { LightTooltip } from 'component/common'

const GoScoreCircle = ({ color, showText = true, height = 104, lineWidth = 30,clickable=false, decayStatus="1" }) => {
	const { projectId } = useSelector(state => state.projectDetail)
	const [, setMilestones] = useState(null)
	const [goScoreConfig, setGoScoreConfig] = useState(null)
	const [data, setData] = useState({})
	const [open, setOpen] = useState(false)
	const [isHover, setIsHover] = useState(false)
	const { axiosService } = useAxios()

	const getProjectGoScoreConfig = () => {
		axiosService(
			getGoScoreConfigService(),
			(data, error) => {
				if (!error) {
					setGoScoreConfig(data)
				}
			}
		)
	}

	const getProjectGoScoreMilestone = () => {
		axiosService(
			getProjectGoScoreMilestoneService(projectId),
			(data, error) => {
				if (!error) {
					setMilestones(data)
				}
			}
		)
	}

	const getProjectGoScore = () => {
		axiosService(getProjectGoScoreService(projectId), (data, error) => {
			if (!error) {
				setData(data)
			}
		})
	}

	useEffect(() => {
		if (!_.isNil(projectId)) {
			getProjectGoScoreMilestone()
			getProjectGoScore()
		}
	}, [projectId])

	useEffect(() => {
		getProjectGoScoreConfig()
	}, [])

	const handleMouseEnter = () => {
		setIsHover(true)
	}

	const handleMouseLeave = () => {
		setIsHover(false)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const handleOpen = () => {
		if (clickable && !data?.locked) setOpen(true)
	}

	const creatorPercent = data?.cesPercent ? Math.round(100 - data?.cesPercent) : 99
	
	const getOptionClassName = (option) => {
		switch (option) {
			case 1:
				return styles.option1;
			case 2:
				return styles.option2;
			case 3:
				return styles.option3;
			case 4:
				return styles.option4;
			default:
				return '';
		}
	};
	const Title = (option, percent) => {
		switch (option) {
			case 1:
				return `This creator posts and engages with their community in the top ${percent}% of the platform.`;
			case 2:
				return 'This project has not been active on the platform and may not offer much engagement.';
			case 3:
				return 'This project has not been active on the platform and may not offer much engagement.';
			case 4:
				return `This creator posts and engages with their community in the top ${percent}% of the platform.`;
			default:
				return '';
		}
	}

	const calculateCreaterPercentage = () => {
		if (_.isNil(data?.ces)) return 0
		if (data?.ces < 1) return 0
		const ces = data?.ces
		const fes = data?.fes ? data?.fes : 0
		const finalGoScore = Math.round(ces * fes)
		if (finalGoScore <= 0) return 0
		return (((ces - 1) * fes) * 100) / finalGoScore
	}

	const createrPercent = calculateCreaterPercentage()
	const createrStatus = data?.ces >= 1 || decayStatus === "0" ? 1 : 2;
	const finalGoScore = Math.round(data?.goScore)
	return (
		<Box className={styles.container}>
			<Box className={styles.score_container}>
				<Box
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
					className={styles.score}
					style={{
						boxShadow: isHover ? `0 0 0.9rem 0.4rem ${color}` : 'none'
					}}
					onClick={handleOpen}
				>

					<CircularBar
						{...{
							height,
							lineWidth,
							background: '#101010',
							time: 0.5,
							value1: data?.stakingPercent,
							value2: data?.socialPercent,
							value3: data?.projectPercent,
							value4: createrPercent,
							classes: data?.locked?`${styles.bar} ${styles.lockedBar}`:`${styles.bar}`,
							id: 'stake'
						}}
					/>

					<img src={stageImages.scoreRing} className={styles.ring_image} />
					{data?.locked ? (
						<img src={stageImages.locked} className={styles.center_image} />
					) : (
						<Typography className={styles.center_text}>
							{data.goScore && finalGoScore}
						</Typography>
					)}
				</Box>
				{showText &&
					(!data?.locked ? (

						<Box onClick={handleOpen} className={`${styles.creator_container} ${getOptionClassName(createrStatus)}`}>
							<Box className={styles.iconType}>
								<LightTooltip
									enterTouchDelay={0}
									leaveTouchDelay={4000}
									placement='bottom'
									title={Title(createrStatus, creatorPercent)}>
									<span className={styles.icon} aria-haspopup="true">
										{((createrStatus === 1) || (createrStatus === 4)) && <img alt='' src={ActiveIcon} />}
										{createrStatus === 2 && <img alt='' src={InActiveIcon} />}
										{createrStatus === 3 && <img alt='' src={ActiveBlueIcon} />}
									</span>
								</LightTooltip>
							</Box>
							<Box className={styles.creatorType}>
								<Typography className={styles.creatorText}>
									{createrStatus !== 2 ? `Active Creator` : `Inactive Creator`}
								</Typography>
								{createrStatus !== 2 && <Typography className={styles.creatorTop}>
									Top {creatorPercent}%
									{(creatorPercent <= 10) && <img alt='fire' width={'10px'} height={'12px'} src={FireIcon} />}
								</Typography>
								}
							</Box>



						</Box>
					) : (
						<Typography className={styles.text} onClick={handleOpen}>
							This project is locked
						</Typography>
					))}
			</Box>
			<ScoreDialogV2 {...{ open, handleClose, data, goScoreConfig, createrPercent ,createrStatus }} />
		</Box>
	)
}

GoScoreCircle.propTypes = {
	color: PropTypes.string,
	showText: PropTypes.bool,
	height: PropTypes.number,
	lineWidth: PropTypes.number,
	clickable:PropTypes.bool,
	decayStatus: PropTypes.string
}

export default GoScoreCircle
