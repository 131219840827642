import _ from 'lodash'
import {
	Box,
	Button,
	Container,
	Grid,
	Icon,
	Typography
} from '@mui/material'
import useOnboardJoin from './useOnboardJoin'
import { CustomButton, TextFieldControl } from '../common'
import metamaskLogo from 'assets/images/metamask-color.png'
import { isMetamask, isMobile } from 'utils/utils'
import styles from './OnboardJoin.module.scss'
import { CaptchaCheck } from 'component/common/captchaCheck'

const OnboardJoin = () => {
	const {
		data,
		errors,
		campaign,
		captchaToken,
		handleNcLogin,
		handleMMLogin,
		handleSubmit,
		handleChange,
	} = useOnboardJoin()

	const renderBenefits = () => {
		const list = campaign.benefits.map(b => (
			<li key={b?.id}>• {b.description}</li>
		))
		return <ul>{list}</ul>
	}

	const currentURL = window.location.href
	const metaMaskButtonUrl = currentURL
		.replace('https://', '')
		.replace('http://', '')

	return (
		<Container disableGutters maxWidth="sm" className={styles.onboard_join}>
			<Grid container spacing={2}>
				<Grid item md={12} className={styles.right}>
					<Box className={styles.hero_container}>
						{campaign?.headerImage &&
							<img src={campaign?.headerImage} />}
					</Box>
					<Box className={styles.logo}>
						{campaign?.logo &&
							<img src={campaign?.logo} />}
					</Box>
					<Box className={styles.fields}>
						<Typography className={styles.title}>{campaign?.title}</Typography>
						<Typography className={styles.subtext}>
							{campaign?.subtext}
						</Typography>
						{renderBenefits()}
						<Typography className={styles.subtitle}>
							{campaign?.formText}
						</Typography>
					</Box>
					<Box className={styles.login_container} >
						<Box className={styles.login_box}>
							<Box className={styles.content}>
								<Box className={styles.fields}>
									<Box className={styles.row}>
										<TextFieldControl
											error={errors?.firstName}
											classes={{ root: styles.first_name }}
											hiddenLabel
											fullWidth
											name="firstName"
											placeholder="First Name"
											value={data.firstName}
											onChange={e => {
												handleChange({ firstName: e.target.value })
											}}
										/>
										<TextFieldControl
											error={errors?.lastName}
											classes={{ root: styles.last_name }}
											hiddenLabel
											fullWidth
											name="username"
											placeholder="Last Name"
											value={data.lastName}
											onChange={e => {
												handleChange({ lastName: e.target.value })
											}}
										/>
									</Box>
									<TextFieldControl
										error={errors?.email}
										classes={{ root: styles.email_input }}
										hiddenLabel
										fullWidth
										name="email"
										placeholder="Email"
										value={data.email}
										onChange={e => {
											handleChange({ email: _.trim(e.target.value) })
										}}
									/>
								</Box>
								<Box className={styles.recaptcha}>
									<CaptchaCheck />
								</Box>
								<CustomButton
									customStyle={'blue_wo_border'}
									onClick={handleSubmit}
									disabled={captchaToken === null}
								>
									Login/SignUp
								</CustomButton>
							</Box>



							<Box className={styles.otherLogin}>
								{isMetamask() || !isMobile() ? (
									<Button className={styles.walletMobileCnct} onClick={handleMMLogin}>
										<img src={metamaskLogo} alt="MetaMask" />
										MetaMask
									</Button>
								) : (
									<Button className={styles.walletMobileCnct}>
										<a href={`https://metamask.app.link/dapp/${metaMaskButtonUrl}`}>
											<img src={metamaskLogo} alt="MetaMask" />
											<Typography>MetaMask</Typography>
										</a>
									</Button>
								)}
								<Button
									className={styles.walletCnct}
									onClick={handleNcLogin}
									startIcon={<Icon baseClassName="fas" className="fa-wallet" />}
								>
									Wallet Connect
								</Button>
							</Box>
							<Box className={styles.agree}>
								<p>
									By signing up, you agree to the{' '}
									<a
										href="https://film.io/constitution"
										target="_blank"
										rel="noreferrer"
									>
										DAO Constitution
									</a>
									{', '}
									<a
										href="https://film.io/token-terms-conditions/"
										target="_blank"
										rel="noreferrer"
									>
										Token Terms & Conditions
									</a>
									{' and '}
									<a
										href="https://film.io/terms-of-use/"
										target="_blank"
										rel="noreferrer"
									>
										Terms of Use
									</a>
								</p>
							</Box>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Container>
	)
}

export default OnboardJoin
