import { useDispatch, useSelector } from 'react-redux'
import { setMenuOpen, setShowTierInfoMenu } from 'component/header/signInSlice'
import { useState } from 'react'

export default function useViewProfile() {
	const dispatch = useDispatch()
	const [anchorEl, setAnchorEl] = useState(null)
	const {
		menuOpen,
		user: signInUser,
		showTierInfoMenu
	} = useSelector(state => state.signIn)

	const handleOpen = event => {
		setAnchorEl(event.currentTarget)
		dispatch(setMenuOpen(true))
	}

	const handleClose = () => {
		dispatch(setMenuOpen(false))
		setTimeout(()=>{
			dispatch(setShowTierInfoMenu(false))
		}, 500)
	}

	const isDaoTierConfirmed = Number(signInUser?.daoTierConfirmed) === 1
	const isDaoTierCompleted = Number(signInUser?.daoTierCompleted) === 1
	const upgradeAvailable =
		(!isDaoTierConfirmed && isDaoTierCompleted) ||
		Number(signInUser?.nextTierCompleted) === 1

	return {
		anchorEl,
		menuOpen,
		signInUser,
		showTierInfoMenu,
		upgradeAvailable,
		handleClose,
		handleOpen,
	}
}
