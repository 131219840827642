import { useEffect, useState } from 'react'
import { useConfirm } from 'material-ui-confirm'
import { IconButton, Icon } from '@mui/material'
import PropTypes from 'prop-types'
import { deleteCommentService } from 'services/comment.service'
import useAxiosFetch from 'hooks/useAxiosFetch'
import useNotification from 'hooks/useNotification'
import { UserInfoTimestamp } from 'component/common'
import { Reactions, renderReactionsButtons } from 'component/common/reactions'
import useLoadingMask from 'hooks/useLoadingMask'
import styles from './Comment.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { commentDeletedHook } from 'component/newsreelPage/newsreelPageSlice'

const Comment = ({
	id,
	user,
	content,
	updatedAt,
	reactions: commentReactions,
	reload
}) => {
	const [reactions, setReactions] = useState(commentReactions)
	const { showNotification } = useNotification()
	const { showMask, hideMask } = useLoadingMask()
	const confirm = useConfirm()
	const dispatch = useDispatch()

	const { user: signInUser } = useSelector(state => state.signIn)

	const { isAdmin } = signInUser
	const isOwnerSignedIn = signInUser?.id === user?.id

	const [{ loading }, deleteComment] = useAxiosFetch(
		deleteCommentService(id),
		afterDelete
	)

	function afterDelete(data, error) {
		if (!error) {
			reload()
			dispatch(commentDeletedHook(id))
			showNotification({ message: data?.message })
		}
	}

	const handleDeleteComment = () => {
		confirm({
			confirmationText: `Delete`,
			description: `This comment will be permanently deleted. You can't undo this action.`
		}).then(() => deleteComment())
	}

	useEffect(() => {
		setReactions(commentReactions)
	}, [commentReactions])

	useEffect(() => {
		loading ? showMask() : hideMask()
	}, [loading])

	return (
		<>
			<div className={styles.comment}>
				<div className={styles.commentArrow}>
					<Icon
						baseClassName="fas"
						className="fa-regular fa-arrow-turn-down-right"
					/>
				</div>
				<div className={styles.heading}>
					<UserInfoTimestamp {...{ updatedAt, user, showInfo: false }} />
					{(Number(isAdmin) === 1 || isOwnerSignedIn === true) && (
						<IconButton
							aria-label="delete"
							size="small"
							sx={{ color: '#fff' }}
							onClick={handleDeleteComment}
						>
							<Icon
								baseClassName="fas"
								className="fa-regular fa-trash-can"
								fontSize={'1rem'}
							/>
						</IconButton>
					)}
				</div>
				<div className={styles.content}>{content}</div>
				<div className={styles.footer}>
					<Reactions
						{...{ feedId: id, updateReactions: setReactions, type: 'comment' }}
					/>
					{renderReactionsButtons(reactions, id, setReactions, 'comment')}
				</div>
				<span className={styles.commentLine}></span>
			</div>
		</>
	)
}

Comment.propTypes = {
	updatedAt: PropTypes.string,
	content: PropTypes.string,
	user: PropTypes.object,
	id: PropTypes.string,
	reload: PropTypes.func,
	reactions: PropTypes.array
}

export default Comment
