import { Box, Button, Icon, IconButton, Typography } from '@mui/material'
import { closeWhitelistWalletDialog } from 'component/profile/profileSlice'
import useWeb3 from 'hooks/useWeb3'
import { useDispatch } from 'react-redux'
import styles from './ConnectWallet.module.scss'
import { isMetamask, isMobile } from 'utils/utils'
import metamaskLogo from 'assets/images/metamask-color.png'

// eslint-disable-next-line react/prop-types
const ConnectWallet = ({ setOpenConnectWalletModal }) => {
	const dispatch = useDispatch()
	const { metamaskLogin, openConnectWalletModal, signer } = useWeb3()

	const handleClose = () => {
		setOpenConnectWalletModal(false)
		dispatch(closeWhitelistWalletDialog())
	}

	const handleMMLogin = async () => {
		if (!signer) {
			await metamaskLogin()
		}
		setOpenConnectWalletModal(false)
		handleClose()
	}

	const handleNcLogin = () => {
		openConnectWalletModal()
		setOpenConnectWalletModal(false)
		handleClose()
	}

	const currentURL = window.location.href
	const metaMaskButtonUrl = currentURL
		.replace('https://', '')
		.replace('http://', '')

	return <Box className={styles.nc_connect}>
		<Typography component={'h1'}>Select Wallet</Typography>
		<Typography>Connect your wallet account</Typography>
		<Box className={styles.otherLogin}>
			{isMetamask() || !isMobile()
				? <Button className={styles.wallet_mobile_cnct} onClick={handleMMLogin}>
					<img src={metamaskLogo} alt="MetaMask" />
					MetaMask
				</Button>
				: <a
					href={`https://metamask.app.link/dapp/${metaMaskButtonUrl}`}
				>
					<Box className={styles.walletMobileCnct}>
						<IconButton
							size="small"
							className={styles.walletBtn}
						>
							<img src={metamaskLogo} alt="MetaMask" />
							<Typography>MetaMask</Typography>
						</IconButton>
					</Box>
				</a>
			}
			<Button className={styles.wallet_cnct} onClick={handleNcLogin}
				startIcon={<Icon baseClassName="fas" className="fa-wallet" />}>
				Wallet Connect
			</Button>
		</Box>
		<Button className={styles.cancel} onClick={handleClose}>
			Cancel
		</Button>
	</Box>
}

export default ConnectWallet