import {
	Avatar,
	Badge,
	Box,
	Button,
	Container,
	Grid,
	Icon,
	Typography
} from '@mui/material'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { ProfileTabSection } from './profileTabSection'
import useProfile from './useProfile'
import { useState } from 'react'
import images from 'assets/images'
import { VerifiedPopover } from './verifiedPopover'
import styles from './Profile.module.scss'

const Profile = () => {
	const {
		user,
		isAdmin,
		tabIndex,
		isSignInUserProfile,
		handleFollowUnfollowUserClick
	} = useProfile()

	const {
		id,
		avatar,
		displayName,
		isFollowedByUser,
		daoTierRingImg,
		daoTierName,
		daoTierImg,
		profession,
		headerImg,
	} = user

	const [anchorEl, setAnchorEl] = useState(null)
	const daoTier = _.capitalize(daoTierName)

	const handlePopoverOpen = event => {
		setAnchorEl(event.currentTarget)
	}

	const handlePopoverClose = () => {
		setAnchorEl(null)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const profileHeaderImg = headerImg || images.defaultProfileHeader

	return (
		<Box className={styles.profile_section}>
			<Container disableGutters maxWidth="lg">
				<Grid container className={styles.profileWrapper}>
					<Grid item md={6} xs={9} className={styles.profile_info}>
						<Badge
							overlap="circular"
							anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
							badgeContent={
								<img src={daoTierImg} className={styles.avatar_badge} />
							}
						>
							<Avatar
								alt={displayName}
								sx={{
									bgcolor: '#000',
									height: '70px',
									width: '70px'
								}}
								src={avatar}
							/>
							<img src={daoTierRingImg} className={styles.avatar_ring} />
						</Badge>
						<Box className={styles.info_container}>
							<Box className={styles.display_name}>
								<Typography>{displayName}</Typography>
								{user.isVerified === 1 && (
									<>
										<span onClick={handlePopoverOpen}>
											<Icon baseClassName="fas" className="fa-badge-check" />
										</span>
										<VerifiedPopover
											{...{
												anchorEl,
												handlePopoverClose,
												handleClose
											}}
										/>
									</>
								)}
							</Box>
							<Typography className={styles.profession}>
								{profession}
							</Typography>
							<Typography className={styles.membership}>
								{daoTier} DAO Membership
							</Typography>
						</Box>
					</Grid>
					<Grid item md={6} xs={3} className={styles.btns_container}>
						{(Number(isAdmin) === 1 || isSignInUserProfile) && (
							<>
								<Link to={`/profile/edit/${id}`}>
									<Button className={styles.edit_profile_btn}>
										Edit Profile
									</Button>
								</Link>
							</>
						)}
						{!isSignInUserProfile && (
							<Button
								className={styles.follow_btn}
								onClick={handleFollowUnfollowUserClick}
							>
								{`${isFollowedByUser ? 'Unfo' : 'Fo'}llow`}
							</Button>
						)}
					</Grid>
					<Grid
						item
						md={12}
						className={`${styles.profile_header} ${tabIndex === 0 ? '' : styles.no_dashboard
							}`}
					>
						<img src={`${profileHeaderImg}?tr=w-1080`} alt="user header" />
					</Grid>
					<Grid item md={12}>
						<ProfileTabSection />
					</Grid>
				</Grid>
			</Container>
		</Box>
	)
}

export default Profile
