import PropTypes from 'prop-types'
import { Slider, Box } from '@mui/material'
import Button from '@mui/material/Button'
import { useCallback, useEffect, useState } from 'react'
import Cropper from 'react-easy-crop'
import styles from './TilePreview.module.scss'
import getCroppedImg from './getCropImage'

const TilePreview = ({ image, tile, setCroppedImage, fileName }) => {
	const [crop, setCrop] = useState({ x: 0, y: 0 })
	const [zoom, setZoom] = useState(1)
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels)
	}, [])

	const typeIdMap = {
		1: {
			class: 'project',
			buttonLabel: 'Read More'
		},
		2: {
			class: 'link',
			buttonLabel: 'View'
		},
		3: {
			class: 'proposal',
			buttonLabel: 'Vote'
		}
	}

	const isDaoProposal = tile.tileTypeId === 3

	useEffect(() => {
		const updateCroopedImage = async () => {
			if (image) {
				const croppedImage = await getCroppedImg(image, croppedAreaPixels)
				const fileCrooped = new File([croppedImage], fileName)
				setCroppedImage(fileCrooped)
			}
		}
		updateCroopedImage()
	}, [zoom, croppedAreaPixels, image])

	const valueLabelFormat = value => `${Math.round(value * 100)}%`

	return (
		<Box className={`${styles.preview_container}`}>
			<Box
				className={`${styles.preview} ${styles.otherProduct} ${
					styles[typeIdMap[tile.tileTypeId].class]
				}`}
			>
				<Box className={styles.crooper}>
					<Cropper
						image={image}
						crop={crop}
						zoom={zoom}
						maxZoom={4}
						minZoom={1}
						aspect={14 / 9}
						restrictPosition={true}
						onCropChange={setCrop}
						onCropComplete={onCropComplete}
						onZoomChange={setZoom}
						objectFit="contains"
						showGrid={false}
						zoomWithScroll={false}
						classes={{
							containerClassName: styles.crooper_container,
							mediaClassName: styles.crooper_media,
							cropAreaClassName: styles.crooper_crop_area
						}}
					/>
				</Box>
				<Box className={styles.other_product_content}>
					<Box className={styles.projectInfo}>
						<Box className={styles.projectName}>
							<a
								href=""
								className={styles.sub_content}
								target="_blank"
								rel="noreferrer"
							>
								{tile.name}
							</a>
						</Box>
						<Box className={styles.description}>
							{isDaoProposal ? (
								<div
									dangerouslySetInnerHTML={{
										__html: tile.content?.substring(0, 180) + '...'
									}}
								/>
							) : (
								tile.content
							)}
						</Box>
						<Button
							component={'a'}
							href=""
							color="primary"
							variant="contained"
							className={styles.view_link}
							target="_blank"
							rel="noreferrer"
						>
							{typeIdMap[tile.tileTypeId].buttonLabel}
						</Button>
					</Box>
				</Box>
			</Box>
			<Slider
				size="small"
				value={zoom}
				aria-label="Small"
				valueLabelDisplay="auto"
				min={1}
				max={4}
				step={0.01}
				getAriaValueText={valueLabelFormat}
				valueLabelFormat={valueLabelFormat}
				className={styles.slider}
				onChange={e => {
					setZoom(e.target.value)
				}}
			/>
		</Box>
	)
}

TilePreview.propTypes = {
	image: PropTypes.any,
	tile: PropTypes.object,
	setCroppedImage: PropTypes.func,
	fileName: PropTypes.string
}

export default TilePreview
