import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Icon, IconButton } from '@mui/material'
import { Reactions, renderReactionsButtons } from 'component/common'
import {
	reloadComments,
	setShowAddCommentForm
} from 'component/projectDetail/projectDetailSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Comment } from './comment'
import { AddCommentForm } from './addCommentForm'
import styles from './CommentReaction.module.scss'
import useAxios from 'hooks/useAxios'
import useLoadingMask from 'hooks/useLoadingMask'
import { getCommentService } from 'services/comment.service'

const CommentReaction = ({
	comments = [],
	defaultReactions = [],
	feedId,
	type,
	commentsCount
}) => {
	const { showAddCommentForm } = useSelector(state => state.projectDetail)
	const [page, setPage] = useState(0)
	const [count, setCount] = useState(commentsCount ?? comments?.length)
	const [commentList, setCommentList] = useState(comments)
	const [loadCompleted, setLoadCompleted] = useState(false)
	const [reactions, setReactions] = useState([])
	const dispatch = useDispatch()
	const { axiosService } = useAxios()
	const { showMask, hideMask } = useLoadingMask()

	const handleShowCommentForm = () => {
		dispatch(setShowAddCommentForm(feedId))
	}

	const reload = isAdd => () => {
		setCount(c => (isAdd ? c + 1 : c - 1))
		dispatch(reloadComments())
	}

	const renderComments = () =>
		commentList?.map(comment => (
			<Comment {...{ ...comment, reload: reload(false) }} key={comment?.id} />
		))

	const updateReactions = reaction => {
		setReactions(reaction)
	}

	const loadMore = () => {
		showMask()
		axiosService(
			getCommentService({
				page,
				[`${type}Id`]: feedId
			}),
			(data, error) => {
				if (!error) {
					setPage(p => p + 1)
					setCommentList([...commentList, ...data])
				}
				hideMask()
			}
		)
	}

	useEffect(() => {
		if (defaultReactions.length > 0) setReactions(defaultReactions)
	}, [defaultReactions])

	useEffect(() => {
		if (comments.length !== commentList.length) setCommentList(comments)
	}, [comments])

	useEffect(() => {
		const completed = commentList?.length >= count
		setLoadCompleted(completed)
	}, [commentList])

	return (
		<>
			<Box className={styles.footer}>
				{showAddCommentForm !== feedId && (
					<Box className={styles.commentCta}>
						<IconButton
							aria-label="addComment"
							size="small"
							onClick={handleShowCommentForm}
							className={styles.add_comment_btn}
						>
							<Icon baseClassName="fas" className="fa-light fa-comment-plus" />
						</IconButton>
						<span>{count}</span>
					</Box>
				)}
				{<Reactions {...{ feedId, updateReactions, type }} />}
				{renderReactionsButtons(reactions, feedId, updateReactions, type)}
			</Box>
			{showAddCommentForm === feedId && (
				<AddCommentForm {...{ feedId, type, reload: reload(true) }} />
			)}
			{commentList.length > 0 && (
				<>
					<Box className={styles.comments}>{renderComments()}</Box>
					{!loadCompleted && (
						<Button className={styles.more_button} onClick={loadMore}>
							{' '}
							Load more{' '}
						</Button>
					)}
				</>
			)}
		</>
	)
}

CommentReaction.propTypes = {
	type: PropTypes.string,
	feedId: PropTypes.string,
	commentsCount: PropTypes.number,
	comments: PropTypes.array,
	defaultReactions: PropTypes.array
}

export default CommentReaction
