import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { getProjectGoScoreStagesService } from 'services/goscore.service'
import { GoScore } from 'component/projectDetail/dashboardTab/goScore'
import { GoScoreCircle } from 'component/projectDetail/dashboardTab/goScoreCircle'
import stagesImages from 'assets/images/stages'
import Stage from './stage/Stage'
import { StageDialog } from 'component/projectDetail/dashboardTab/stages/stageDialog'
import useAxios from 'hooks/useAxios'
import styles from './Stages.module.scss'

const ProgressBar = ({ percent }) => {
	return (
		<Box className={styles.bar}>
			<Box className={styles.back}>
				<Box className={styles.progress} sx={{ width: `${percent}%` }}></Box>
			</Box>
		</Box>
	)
}

ProgressBar.propTypes = {
	percent: PropTypes.number
}

const Stages = () => {
	const initialDialog = {
		open: false,
		stage: 0
	}
	const [stages, setStages] = useState([])
	const [dialog, setDialog] = useState(initialDialog)
	const { projectId, project } = useSelector(state => state.projectDetail)
	const { isGoScoreV2Enabled } = useSelector(state => state.home)
	const { axiosService } = useAxios()

	const getProjectGoScoreStages = () => {
		axiosService(getProjectGoScoreStagesService(projectId), (data, error) => {
			if (!error) {
				setStages(data)
			}
		})
	}

	useEffect(() => {
		if (!_.isNil(projectId)) {
			getProjectGoScoreStages()
		}
	}, [projectId])

	const handleClose = () => {
		setDialog(initialDialog)
	}

	const handleStageClick = stage => () => {
		setDialog({
			open: true,
			stage
		})
	}

	const renderStages = () => {
		return stages.map((item, idx) => {
			const centerImage = stagesImages[item.label]
			const stagemetrics = item.stageMetrics
			delete item?.stageMetrics
			const stage = {
				...item,
				stagemetrics,
				centerImage,
				ringImage: stagesImages[`${item.label}Ring`]
			}
			return (
				<Box key={`stage-${idx}`} className={styles.stage_container}>
					<ProgressBar percent={stage?.progress} />
					<Stage {...stage} onClick={handleStageClick(idx)} />
				</Box>
			)
		})
	}

	return (
		<Box className={styles.stages}>
			<StageDialog {...{ handleClose, ...dialog }} />
			<Box className={styles.goscore}>
				{ isGoScoreV2Enabled ? 
					<GoScoreCircle showText={false} clickable={true} decayStatus={project?.decayStatus}/>
					:
					<GoScore showText={false} />
				}	
			</Box>
			<Box className={styles.right_section}>{renderStages()}</Box>
		</Box>
	)
}

export default Stages
