import { useState } from 'react'

export const useSocialMediaShareMenu = () => {
	const [anchorEl, setAnchorEl] = useState(null)

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	return {
		anchorEl,
		handleClose,
		handleClick
	}
}
