import { useDispatch, useSelector } from 'react-redux'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import Dashboard from 'component/dashboard/Dashboard'
import ForgotPassword from 'component/forgotPassword/ForgotPassword'
import ProjectDetail from 'component/projectDetail/ProjectDetail'
import Profile from 'component/profile/Profile'
import Settings from 'component/settings/Settings'
import TileManagement from 'component/tileManagement/TileManagement'
import TransactionInformation from 'component/profile/walletTab/transactionInformation/TransactionInformation'
import { ProjectEdit } from 'component/projectEdit'
import { NewTile } from 'component/newTile'
import { ProfileEdit } from 'component/profileEdit'
import { Home } from 'component/home'
import { SocialLogin } from 'component/header/socialLogin'
import { TermsOfService } from 'component/termsOfService'
import { CodeOfConduct } from 'component/codeOfConduct'
import { SocialJoin } from 'component/socialJoin'
import { ResetPassword } from 'component/resetPassword'
import { OnboardJoin } from 'component/onboardJoin'
import { ProjectAddWizard } from 'component/projectAddWizard'
import { SharedTwitter } from 'component/sharedTwitter'
import { NewsreelPage } from 'component/newsreelPage'
import { NotFound } from 'component/notFound'
import { addObjectToLocalStorage } from 'utils/utils'
import { setLoginDialogOpen } from 'component/header/signInSlice'
import { isRouteMatch } from 'utils/url.util'
import { useEffect } from 'react'
import { TokenSaleJoin } from 'component/tokenSaleJoin'
import { TokenSaleExperience } from 'component/tokenSaleExperience'
import { DashboardProduct } from 'component/dashboard'

const AppRoutes = () => {
	const dispatch = useDispatch()
	const { pathname, search } = useLocation()
	const { isAuthenticated, user } = useSelector(state => state.signIn)

	useEffect(() => {
		// Routes skipped that doesn't show the login modal by default
		const routes = [
			'/',
			'/forgotPassword',
			'/airdropJoin/:userId/:token',
			'/resetPassword/:userId/:token',
			'/resetPassword/:action/:userId/:token',
			'/login/:provider/:id/:token',
			'/terms-of-service',
			'/code-of-conduct',
			'/join/:network/:userShortUrl/:shortUrl',
			'/join/:network/:userShortUrl',
			'/onboard/join/:slug',
			'/tokenSale/:id',
			'/tokenSale'
		]
		if (!isRouteMatch(pathname, routes)) {
			const redirectTo = search === '' ? pathname : `${pathname}/${search}`
			addObjectToLocalStorage({ redirectTo })
			dispatch(setLoginDialogOpen(true))
		}
	}, [])

	if (isAuthenticated) {
		if (user?.isTokenSale === 1 && user?.appAccess === 0) {
			return (
				<Routes>
					<Route path="/tokenSale" element={<TokenSaleExperience />} />
					<Route path="*" element={<Navigate to="/tokenSale" replace />} />
				</Routes>
			)
		} else {
			return (
				<Routes>
					<Route path="/tokenSale" element={<TokenSaleExperience />} />
					<Route path="/" element={<Home />} />
					<Route path="/explore" element={<Dashboard />} />
					<Route path="/newsreel" element={<NewsreelPage />} />
					<Route path="/project/:slug" element={<ProjectDetail />} />
					<Route path="/project/edit/:id" element={<ProjectEdit />} />
					<Route
						path="/project/draft/:id"
						element={<ProjectDetail draft={true} />}
					/>
					<Route path="/profile/:username" element={<Profile />} />
					<Route path="/profile/edit/:userId" element={<ProfileEdit />} />
					<Route
						path="/transactionInformation"
						element={<TransactionInformation />}
					/>
					<Route path="/settings" element={<Settings />} />
					<Route path="/tileManagement" element={<TileManagement />} />
					<Route path="/newTile" element={<NewTile />} />
					<Route path="/newProject" element={<ProjectAddWizard />} />
					<Route path="/tile/:tileId" element={<NewTile />} />
					<Route path="/terms-of-service" element={<TermsOfService />} />
					<Route path="/code-of-conduct" element={<CodeOfConduct />} />
					<Route path="/tweet/:token/:secret" element={<SharedTwitter />} />
					<Route path="/notfound" element={<NotFound />} />
					<Route path="*" element={<Navigate to="/" replace />} />
				</Routes>
			)
		}
	} else {
		return (
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/forgotPassword" element={<ForgotPassword />} />
				<Route path="/airdropJoin/:userId/:token" element={<Home />} />
				<Route
					path="/resetPassword/:userId/:token"
					element={<ResetPassword />}
				/>
				<Route
					path="/resetPassword/:action/:userId/:token"
					element={<ResetPassword />}
				/>
				<Route path="/login/:provider/:id/:token" element={<SocialLogin />} />
				<Route path="/terms-of-service" element={<TermsOfService />} />
				<Route path="/code-of-conduct" element={<CodeOfConduct />} />
				<Route
					path="/join/:network/:userShortUrl/:shortUrl"
					element={<SocialJoin />}
				/>
				<Route path="/join/:network/:userShortUrl" element={<DashboardProduct />} />
				<Route path="/onboard/join/:slug" element={<OnboardJoin />} />
				<Route path="/tokenSale" element={<TokenSaleJoin />} />
				<Route path="/tokenSale/:id" element={<TokenSaleJoin />} />
				<Route path="*" element={<Navigate to="/" replace />} />
			</Routes>
		)
	}
}

export default AppRoutes
