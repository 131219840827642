import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useNotification from 'hooks/useNotification'
import { getGenresService } from 'services/genres.service'
import {
	addProjectService,
	editProjectService,
	getProjectById
} from 'services/project.service'
import { getProjectStatus, getProjectTypes } from 'services/seed.service'
import { Mixpanel } from 'services/mixpanel.service'
import {
	setCloseModalOpen,
	setStatuses,
	setErrors,
	setGenres,
	setTypes,
	updatePoster,
	updateProject,
	updateHeaderImage
} from './projectAddWizardSlice'
import useLoadingMask from 'hooks/useLoadingMask'
import useAxios from 'hooks/useAxios'
import { createProjectPreviewService } from 'services/projectPreview.service'
import styles from './ProjectAddWizard.module.scss'
import { FilmioProjectContract } from 'utils/blockchain/functions'

const useProjectAddWizard = () => {
	const dispatch = useDispatch()
	const { project, statuses, types, genres, headerImage, poster, errors } =
		useSelector(state => state.projectAddWizard)
	const { user: signInUser } = useSelector(state => state.signIn)
	const [videoInfo, setVideoInfo] = useState(null)
	const [scrolled, setScrolled] = useState(false)
	const { showNotification } = useNotification()
	const { showMask, hideMask } = useLoadingMask()
	const navigate = useNavigate()
	const { axiosService } = useAxios()
	const [searchParams, setSearchParams] = useSearchParams()

	const fetchProjectStatus = () => {
		axiosService(getProjectStatus(false), (data, error) => {
			if (!error) {
				dispatch(setStatuses(data))
			}
		})
	}

	const fetchProjectTypes = () => {
		axiosService(getProjectTypes(false), (data, error) => {
			if (!error) {
				dispatch(setTypes(data))
			}
		})
	}

	const getGenres = () => {
		axiosService(getGenresService(false), (data, error) => {
			if (!error) {
				dispatch(setGenres(data))
			}
		})
	}

	const createProject = async (extra = {}, exit) => {
		showMask()
		axiosService(
			addProjectService({
				...project,
				coverImg: headerImage,
				poster,
				...extra
			}),
			async (data, error) => {
				if (!error) {
					const projectHash = await FilmioProjectContract.createLock(
						data?.id,
						data?.id
					)
					updateProjectDb({ id: data?.id, projectHash })
					Mixpanel.track('new_project_creation_event', {
						distinct_id: signInUser.id,
						displayName: signInUser.displayName,
						username: signInUser.username,
						id: data?.id,
						slug: data?.slug,
						projectHash,
						...data
					})
					dispatch(updateProject(data))
					showNotification({ message: 'Project saved as a draft' })
					setSearchParams({
						page: 2,
						id: data?.id
					})
					if (extra?.stageId === 2 || exit) navigate(`/project/${data?.slug}`)
				}
				hideMask()
			}
		)
	}

	const updateProjectDb = (projectData = {}, exit) => {
		showMask()
		axiosService(editProjectService(projectData), (data, error) => {
			if (!error) {
				Mixpanel.track('project_update_event', {
					distinct_id: signInUser.id,
					displayName: signInUser.displayName,
					username: signInUser.username,
					...data
				})
				dispatch(updateProject(data))
				dispatch(updateHeaderImage(undefined))
				dispatch(updatePoster(undefined))
				showNotification({ message: 'Project updated successfully' })
				document.getElementById('saveButton').classList.remove(styles.active)
				if (projectData?.stageId === 2 || exit)
					navigate(`/project/${data?.slug}`)
			}
			hideMask()
		})
	}

	const fetchProjectById = id => {
		showMask()
		axiosService(getProjectById({ id, isUpdate: true }), (data, error) => {
			if (!error) {
				dispatch(updateProject(data))
			}
			hideMask()
		})
	}

	const handleSavePreviewChanges = () => {
		showMask()
		const id = searchParams.get('id')
		if (!_.isNil(id))
			axiosService(
				createProjectPreviewService({
					...project,
					projectId: id,
					coverImg: headerImage
				}),
				(_data, error) => {
					if (!error) {
						const baseUrl = window.location.origin
						window.open(
							`${baseUrl}/project/draft/${id}`,
							'_blank',
							'noopener,noreferrer'
						)
					}
					hideMask()
				}
			)
	}

	const updateErrors = payload => {
		dispatch(
			setErrors({
				...errors,
				...payload
			})
		)
	}

	const validateTitle = () => {
		if (_.isNil(project.title) || project.title.trim() === '') {
			const message = 'The title is required to create the project'
			updateErrors({ title: message })
			return false
		}
		return true
	}

	const isValidSubmitForAppoval = () => {
		let isValid = true
		const approvalErrors = {}
		const message = 'This field is required for submit for approval'
		if (_.isNil(project.title) || project.title.trim() === '') {
			approvalErrors.title = message
			isValid = false
		}
		if (_.isNil(project?.coverImg) && _.isNil(headerImage)) {
			approvalErrors.header = message
			isValid = false
		}
		if (_.isNil(project.lookbook)) {
			approvalErrors.lookbook = message
			isValid = false
		}
		if (_.isNil(poster) && _.isNil(project?.poster)) {
			approvalErrors.poster = message
			isValid = false
		}
		if (_.isNil(project.logline)) {
			approvalErrors.logline = message
			isValid = false
		}
		const story = project?.story?.replace(/<\/?[^>]+(>|$)/g, '')
		if (_.isNil(story) || story.trim() === '') {
			approvalErrors.story = message
			isValid = false
		}

		updateErrors(approvalErrors)

		return isValid
	}

	const showErrors = () => {
		setScrolled(false)
		showNotification({
			message: 'Complete the required fields to submit for approval',
			type: 'error'
		})
	}

	const handleSubmitForApproval = async () => {
		if (isValidSubmitForAppoval()) {
			const id = searchParams.get('id')
			if (id === null) {
				createProject({ stageId: 2 })
			} else {
				
				let projectHash = null
				
				if(_.isNil(project?.projectHash)){
					projectHash = await FilmioProjectContract.createLock(id, id)
					if(_.isNil(projectHash)){
						showNotification(
							{ message: "You need to create the project on the blockchain!"}
						)
						return
					}
				}
				
				const projectData = {
					...project,
					coverImg: headerImage,
					poster,
					stageId: 2,
					projectHash
				}
				updateProjectDb(projectData)
			}
		} else showErrors()
	}

	const handleSaveChanges = exit => () => {
		if (validateTitle()) {
			const id = searchParams.get('id')
			if (id === null) {
				createProject(null, exit)
			} else {
				const projectData = {
					...project,
					coverImg: headerImage,
					poster
				}
				updateProjectDb(projectData, exit)
			}

			return true
		} else {
			showNotification({
				message: 'Complete the required fields to save the changes',
				type: 'error'
			})

			return false
		}
	}

	const handleChange = payload => {
		const key = Object.keys(payload)[0]

		updateErrors({ [key]: false })
		dispatch(updateProject(payload))

		if (project[key].toString().length > 0 && project[key] !== payload[key]) {
			document.getElementById('saveButton').classList.add(styles.active)
		} else {
			document.getElementById('saveButton').classList.remove(styles.active)
		}
	}

	const handleCancel = () => {
		navigate('/')
	}

	const handleNextPage = () => {
		const page = Number(searchParams.get('page')) + 1

		if (handleSaveChanges(false)() === true) {
			searchParams.set('page', page)
			setSearchParams(searchParams)
		}
	}

	const handleOpenConfirmModal = () => {
		dispatch(setCloseModalOpen(true))
	}

	const setHeaderImage = image => {
		// Rreview later the image is sent twice to backend
		// const imageName = image?.name
		// const headerPath = project?.coverImg?.path ?? ''
		// const headerName = _.get(headerPath.split('__'), [1], null)
		// if (imageName !== headerName)
		dispatch(updateHeaderImage(image))
	}

	const setPoster = image => {
		dispatch(updatePoster(image))
	}

	useEffect(() => {
		const id = searchParams.get('id')

		if (_.isEmpty(project)) {
			getGenres()
			fetchProjectStatus()
			fetchProjectTypes()
			if (!_.isNil(id)) {
				fetchProjectById(id)
			}
		}
	}, [])

	return {
		types,
		errors,
		genres,
		project,
		scrolled,
		statuses,
		videoInfo,
		handleSavePreviewChanges,
		handleSubmitForApproval,
		handleOpenConfirmModal,
		handleSaveChanges,
		setHeaderImage,
		handleNextPage,
		setVideoInfo,
		handleCancel,
		handleChange,
		updateErrors,
		setScrolled,
		setPoster
	}
}

export default useProjectAddWizard
