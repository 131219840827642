import {
	Box,
	Button,
	Dialog,
	Icon,
	IconButton,
	Typography
} from '@mui/material'
import loadingChest from 'assets/images/dao-tiers/loading.svg'
import chest from 'assets/images/dao-tiers/chest.svg'
import { RingAvatar } from 'component/common/customAvatar'
import { useDaoTierLevelingDialog } from './useDaoTierLevelingDialog'
import styles from './DaoTierLevelingDialog.module.scss'

export const DaoTierLevelingDialog = () => {
	const {
		user,
		data,
		message,
		daoTierLevelingDialogOpen,
		handleGoToExplore,
		handleClose
	} = useDaoTierLevelingDialog()


	const renderTodo = () =>
		data?.todo?.map(t => {
			const isCompleted = t.thresholdProgress >= t.threshold
			return (
				<Typography key={t.id}>
					{
						<>
							<span>
								(<span className={isCompleted ? styles.completed : 0}>{t.thresholdProgress}/{t.threshold}</span>)
							</span>
							{t.description}
						</>
					}
				</Typography>
			)
		})
	const renderBenefits = () =>
		data?.benefits
			?.filter(b => {
				const isAidropUser =
					Number(user?.isAirdropFan) === 1 ||
					Number(user?.isAirdropCreator) === 1
				if (!isAidropUser && b.highlight) return false
				return true
			})
			.map(b => {
				return (
					<Typography key={b.id} className={b.highlight ? styles.green : ''}>
						<span>
							<img src={loadingChest} />
						</span>{' '}
						{b.description}
					</Typography>
				)
			})

	return (
		<>
			<Dialog
				open={daoTierLevelingDialogOpen}
				disableScrollLock={false}
				className={styles.dialog}
				modal="true"
				classes={{
					paper: styles.dialog_paper,
					container: styles.dialog_container
				}}
			>
				<IconButton onClick={handleClose}>
					<Icon baseClassName="fas" className="fa-xmark" />
				</IconButton>
				<Box className={styles.container}>
					<Typography component={'h2'}>
						You made it in {user?.displayName}!
					</Typography>
					<Box className={styles.info}>
						<RingAvatar
							{...{
								avatar: user?.avatar,
								ring: user?.daoTierRingImg,
								displayName: user?.displayName
							}}
						/>
						<Box
							dangerouslySetInnerHTML={{ __html: message }}
							className={styles.message}
						></Box>
						<Typography>
							{user?.campaign?.isAirdrop === 0 && (
								<>
									<br />
									<span className={styles.green}>
										{user?.campaign?.rewardMessage}
									</span>
								</>
							)}
						</Typography>
					</Box>
					<Box className={styles.dao_details}>
						<Typography>{data?.name} Membership Details</Typography>
						<Box></Box>
					</Box>
					<Box className={styles.center}>
						<Box className={styles.left}>
							<Typography>
								WHAT’S LEFT TO DO
								<Icon baseClassName="fas" className="fa-list-check" />
							</Typography>
							{renderTodo()}
						</Box>
						<Box className={styles.right}>
							<Typography>WHAT YOU GET</Typography>
							<img src={chest} />
							{renderBenefits()}
						</Box>
					</Box>
					<Box className={styles.buttons}>
						<Button onClick={handleGoToExplore}>Show me some projects!</Button>
						<a
							href="https://help.film.io/knowledgebase/film.io-dao/what-are-dao-tiers"
							target="_blank"
							rel="noreferrer"
						>
							Learn about DAO Tiers
							<Icon
								baseClassName="fal"
								className="fa-arrow-up-right-from-square"
							/>
						</a>
					</Box>
				</Box>
			</Dialog>
		</>
	)
}
