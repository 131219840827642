import { useContestJoinedDialog } from './useContestJoinedDialog'
import { CustomDialog } from '../../common'
import { CustomButton } from 'component/common'
import { Stack, Typography } from '@mui/material'
import { ActiveContestBadge } from '../../dashboard/contests/badge/ActiveContestBadge'
import { useEffect } from 'react'
import useAxios from 'hooks/useAxios'
import { setContestNotifiedService } from 'services/contest.service'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import styles from './ContestJoinedDialog.module.scss'

export const ContestJoinedDialog = () => {
	const { open, close } = useContestJoinedDialog()
	const { axiosService } = useAxios()
	const { activeContests } = useSelector(state => state.signIn)

	const setContestNotified = async () => {
		axiosService(setContestNotifiedService())
	}

	useEffect(() => {
		if (open) {
			setContestNotified()
		}
	}, [open])

	return (
		<CustomDialog open={open} removeX variant='contest_joined_dialog'>
			<Stack spacing={2} alignItems="center" className={styles.contest_joined_dialog}>
				<ActiveContestBadge width={112} height={111} src={_.get(activeContests, [0, 'badge'])} />
				<Typography align="center" fontFamily={'Titillium Web'} fontWeight={700} fontSize={32} lineHeight={'40px'} className={styles.header_title}>
					Congratulations!<br />
					You’re in the Contest!
				</Typography>
				<Typography variant="body1" align="center" opacity={0.8} fontSize={14} fontWeight={400} lineHeight={'24px'}>
					You’ve been enrolled in the Creator Collaboration Award Contest.
					Engage with your community to boost your chances of winning and increase your Go Score!
				</Typography>
				<CustomButton customStyle={'cancel_dark_blue'} onClick={close} className={styles.close_button}>
					Close
				</CustomButton>
			</Stack>
		</CustomDialog>
	)
}
