import coinQ from './coin.q.png'
import entr from './entr.png'
import filmDaily from './film.daily.png'
import forbes from './forbes.png'
import ibt from './ibt.png'
import investing from './investing1.png'

const images = {
	coinQ,
	entr,
	filmDaily,
	forbes,
	ibt,
	investing
}

export default images
