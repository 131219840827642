import { Icon, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useRef } from 'react'
import {
	SnapList,
	SnapItem,
	useScroll,
	useDragToScroll,
	useVisibleElements
} from 'react-snaplist-carousel'
import images from 'assets/images'
import styles from './ExplainerSlideshow.module.scss'

const ExplainerSlideshow = () => {
	const snapList = useRef(null)
	const items = 5

	const visible = useVisibleElements(
		{ debounce: 2, ref: snapList },
		([element]) => element
	)

	const goToSnapItem = useScroll({ ref: snapList })

	useDragToScroll({ ref: snapList, disabled: false })

	const handleNextClick = () => {
		goToSnapItem(visible + 2)
	}

	const handlePrevClick = () => {
		goToSnapItem(visible > 2 ? visible - 2 : 0)
	}

	return (
		<Box className={styles.eSlideshow}>
			{visible > 0 && (
				<IconButton
					size="small"
					onClick={handlePrevClick}
					className={styles.prev}
				>
					<Icon baseClassName="fas" className="fa-chevron-left" />
				</IconButton>
			)}

			<SnapList
				className={styles.slideContainer}
				ref={snapList}
				tabIndex={0}
				role="region"
				hideScrollbar="true"
			>
				<SnapItem className={styles.slide} snapAlign="center">
					<img onClick={() => goToSnapItem(0)} src={images.explainerSlideOne} />
					<Typography component={'h2'}>
						SUBMIT A DAO PROJECT PROPOSAL
					</Typography>
					<Box className={styles.quote}>
						<Typography id="one" className={styles.quoteOne} component={'p'}>
							<strong>Creators build out</strong> and then submit a DAO project
							proposal to the platform
						</Typography>
					</Box>
				</SnapItem>
				<SnapItem className={styles.slide} snapAlign="center">
					<Typography component={'h2'}>
						STAKE TOKENS TO UNLOCK A PROJECT
					</Typography>
					<img
						onClick={() => goToSnapItem(1)}
						src={images.explainerSlideFour}
					/>
					<Box className={styles.quote}>
						<Typography id="two" className={styles.quoteTwo} component={'p'}>
							<strong>Fans (DAO members)</strong> stake FAN tokens to a project
							to help the proposal get approved and unlock its Go Score™
						</Typography>
					</Box>
				</SnapItem>
				<SnapItem className={styles.slide} snapAlign="center">
					<Typography component={'h2'}>GET FAN TOKENS & LEVEL UP</Typography>
					<img onClick={() => goToSnapItem(2)} src={images.explainerSlideTwo} />
					<Box className={styles.quote}>
						<Typography
							id="three"
							className={styles.quoteThree}
							component={'p'}
						>
							<strong>Fans (DAO members)</strong> are rewarded in FAN Tokens and
							level up their DAO tier, increasing their reputation and status.
						</Typography>
					</Box>
				</SnapItem>
				<SnapItem className={styles.slide} snapAlign="center">
					<Typography component={'h2'}>ATTRACT PEOPLE & FUNDING</Typography>
					<img
						onClick={() => goToSnapItem(3)}
						src={images.explainerSlideThree}
					/>
					<Box className={styles.quote}>
						<Typography id="four" className={styles.quoteFour} component={'p'}>
							<strong>Creators promote their project</strong>, build an
							audience, launch funding campaigns and get matched to film
							financiers for greenlight and distribution
						</Typography>
					</Box>
				</SnapItem>
				<SnapItem className={styles.slide} snapAlign="center">
					<Typography component={'h2'}>
						GET CONNECTED TO DISTRIBUTORS
					</Typography>
					<img
						onClick={() => goToSnapItem(4)}
						src={images.explainerSlideFive}
					/>
					<Box className={styles.quote}>
						<Typography id="five" className={styles.quoteFive} component={'p'}>
							<strong>Studios, distribution partners</strong>, and other
							integrations connect with Film.io to interact with these projects.
						</Typography>
					</Box>
				</SnapItem>
			</SnapList>

			{visible < items - 2 && (
				<IconButton
					size="small"
					onClick={handleNextClick}
					className={styles.next}
				>
					<Icon baseClassName="fas" className="fa-chevron-right" />
				</IconButton>
			)}

			<Box className={styles.explanation}></Box>
		</Box>
	)
}

export default ExplainerSlideshow
