import {
	FormControl,
	Typography,
	FormHelperText,
	TextField,
	Autocomplete
} from '@mui/material'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import styles from './AutocompleteBox.module.scss'
import useAxios from 'hooks/useAxios'

export const AutocompleteBox = ({
	error,
	value = '',
	setValue,
	placeholder = '',
	label,
	variant = 'grey',
	displayField = 'name',
	valueField = 'id',
	service,
	options = [],
	...others
}) => {
	const [selectedValue, setSelectedValue] = useState(null)
	const [loading, setLoading] = useState(false)
	const [inputValue, setInputValue] = useState('')
	const [optionsList, setOptionsList] = useState(options)
	const { axiosService } = useAxios()

	const getOptions = () => {
		setLoading(true)
		axiosService(service({ search: inputValue }), (data, error) => {
			if (!error) {
				setOptionsList(data)
			}
			setLoading(false)
		})
	}

	const handleChangeDebounced = useMemo(() => {
		return _.debounce(newValue => {
			setInputValue(newValue)
		}, 500)
	}, [])

	useEffect(() => {
		if (service) getOptions()
		else if (!service) {
			if (inputValue === '') setOptionsList(options)
			else {
				setOptionsList(
					options.filter(el => {
						const name = el?.name?.toLocaleLowerCase()
						const value = inputValue?.toLocaleLowerCase()
						return name.includes(value)
					})
				)
			}
		}
	}, [inputValue])

	useEffect(() => {
		if (!service) {
			setOptionsList(options)
		}
		if (value !== '') {
			const record = options.find(el => el[valueField] === value)
			setSelectedValue(record)
		}
	}, [])

	const errorClass = error ? styles.error : ''
	const classes = [styles.autocomplete_box, styles[variant], errorClass].join(
		' '
	)
	return (
		<FormControl className={classes} size="small">
			{label && (
				<Typography component={'h2'} className={styles.label}>
					{label}
				</Typography>
			)}
			<Autocomplete
				filterOptions={x => x}
				isOptionEqualToValue={(option, value) =>
					option.id === selectedValue?.id
				}
				getOptionLabel={option =>
					typeof option === 'string' ? option : option.name
				}
				options={optionsList}
				autoComplete
				value={selectedValue}
				noOptionsText={loading ? 'Loading...' : 'No match found'}
				onChange={(_event, newValue) => {
					if (!_.isNil(newValue)) {
						setValue(newValue[valueField])
						setSelectedValue(newValue)
					}
				}}
				classes={{ root: styles?.root, popper: styles.popper }}
				onInputChange={(_event, newValue) => {
					handleChangeDebounced(newValue)
				}}
				renderInput={params => (
					<TextField
						{...params}
						fullWidth
						placeholder
						classes={{ root: styles.text_field }}
					/>
				)}
				renderOption={(props, option) => {
					return (
						<li {...props} key={option?.id}>
							{option?.name}
						</li>
					)
				}}
				{...others}
			/>
			{error && <FormHelperText>{error}</FormHelperText>}
		</FormControl>
	)
}

AutocompleteBox.propTypes = {
	error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	setValue: PropTypes.func,
	options: PropTypes.array,
	placeholder: PropTypes.string,
	label: PropTypes.string,
	variant: PropTypes.string,
	displayField: PropTypes.string,
	valueField: PropTypes.string,
	service: PropTypes.func,
	others: PropTypes.object
}
