import { useState } from 'react'
import { Box, Icon, Typography, Button, Dialog } from '@mui/material'
import newToken from 'assets/images/new_token_blue.svg'
import gemIcon from 'assets/images/gem_icon.png'
import transferIllustration from 'assets/images/transfer-illustration.png'
import useWalletOverview from './useWalletOverview'
import styles from './WalletOverview.module.scss'
import { CustomButton, LightTooltip } from 'component/common'
import { ClaimWalletDialog } from './claimWalletDialog'
import { WhitelistWalletDialog } from '../whitelistWalletDialog'
import { WhitelistTransferDialog } from '../whitelistTransferDialog'
import { ImportFanTokenDialog } from 'component/header/importFanTokenDialog'
import Grid from '@mui/material/Unstable_Grid2'
import unLockIcon from 'assets/images/walletIcons/unlock.svg'
import timerIcon from 'assets/images/walletIcons/timer.svg'
import infoIcon from 'assets/images/walletIcons/info.svg'
import { SpeedUpDialog } from '../speedUpDialog'
import { abbreviateNumberFixed, NumberWithDecimals } from 'utils/utils'
import InfoDiamond from 'assets/images/diamond_icon_info.svg'
import GreenInfo from 'assets/images/walletIcons/green_info.svg'
import { formatDate } from 'utils/date.utils'
import PropTypes from 'prop-types'
import { SpeedUpBar } from 'component/common/speedUpBar'

const WalletOverview = ({ changeTab }) => {
	const {
		type,
		isHodl,
		endDate,
		progress,
		gemTotal,
		startDate,
		hodlAmount,
		stakesTotal,
		balance,
		balanceTotal,
		unlockedTokens,
		timeToUnlock,
		earningsTotal,
		earningsToDate,
		walletAddress,
		isWhitelisted,
		isNonCustodial,
		isNcWithoutMagic,
		isIngXRestrictedInvestor,
		unlockedRewards,
		openSpeedUpDialog,
		shortwalletAddress,
		handleOpenWhitelistWalletDialog,
		whitelistTransferDialogOpen,
		handleOpenWhitelistTransfer,
		handleCopyWalletAddress,
		handleOpenClaimWallet,
		handleUnlockRewards,
		handleRemoveWallet,
		handleCloseSpeedUp,
		handleOpenSpeedUp,
		whitelistAddress,
		updateOverview,
		isRestricted,
	} = useWalletOverview()

	const availableToWithdraw =  `${abbreviateNumberFixed(unlockedTokens?? 0, 2)} Withdrawable`
	const rewardsTokens = (earningsTotal ?? 0) - (unlockedRewards ?? 0)
	const [open, setOpen] = useState(false) 

	const handleOpen = () => {
		setOpen(true) 
	}

	const handleClose = () => {
		setOpen(false)
	}

	const onClickChangeTab = () => {
		changeTab(3)
	}

	const handleFontSize = (balance,rewardsTokens,stakesTotal) => {
		const len1 = String(NumberWithDecimals(balance?? 0)).length
		const len2 = String(abbreviateNumberFixed(rewardsTokens ?? 0, 2)).length
		const len3 = String(abbreviateNumberFixed(stakesTotal ?? 0, 2)).length
		if (len1 < 6 && len2 < 6 && len3 < 6) return false
		return true
	}
	return (
		!isHodl ?
			<Box className={styles.overview}>
				{isNcWithoutMagic &&
					<Box className={styles.rewards_locked}>
						<CustomButton customStyle={'outlined_square'} onClick={handleUnlockRewards}>
							Setup Your Platform Wallet
						</CustomButton>
					</Box>
				}
				<Box className={styles.row_group}>
					<Box className={styles.total_balance}>
						<Box className={styles.body}>
							<Box className={styles.top}>
								<Typography component={'h2'} className={styles.text}>
									Platform Wallet
									<LightTooltip
										enterTouchDelay={0}
										leaveTouchDelay={4000}
										placement='bottom'
										title='The platform wallet represents your total FAN balance, which is available + staked tokens.  You can also Transfer FAN into this wallet address. Note: please do not transfer any crypto other than FAN into this wallet, as it may not be recoverable.'
									>
										<span className={styles.help_icon} aria-haspopup="true">
											<img alt='info Icon' src={infoIcon} />
										</span>
									</LightTooltip>
								</Typography>
							</Box>
							<Box className={styles.center}>
								<img src={newToken} />
								<Typography component={'h1'} className={styles.text}>
									{abbreviateNumberFixed(balanceTotal??0, 2)}
								</Typography>
							</Box>
							<Box className={styles.bottom}>
								<Button onClick={handleCopyWalletAddress}
									startIcon={<Icon baseClassName="fas" className="fa-copy" />}>
									{walletAddress}
								</Button>
							</Box>
						</Box>
					</Box>
					<Box className={styles.tokens_section}>
						<Box className={handleFontSize(balance, rewardsTokens, stakesTotal) ? `${styles.ARV_token} ${styles.small_gap} ` : `${styles.ARV_token}`}>
						{isIngXRestrictedInvestor &&
							<Box className={styles.green_info}>
							<LightTooltip
									enterTouchDelay={0}
									leaveTouchDelay={4000}
									placement='bottom'
									title="Your withdrawable amount is calculated based on the 30-day trailing Average Daily Trading Volume (ADTV) and the pro rata share of Principal FAN Token you have been awarded."
								>
									<span>
									<img src={GreenInfo} alt='gree Info'/>
									</span>
								</LightTooltip>
							</Box>
						}
							<Box className={styles.right_section}>
								<LightTooltip
									enterTouchDelay={0}
									leaveTouchDelay={4000}
									placement='bottom'
									title="Available Tokens are $FAN that have not yet been staked to any project. Staking these tokens will earn you more FGR."
								>
									<span>
										<img alt='info Icon' src={unLockIcon} />
									</span>
								</LightTooltip>
								<Typography component={'h3'} className={`${styles.header_tokens} ${styles.availableToken}`}>
									Available Tokens
									<span className={styles.availableToWithdraw}>
										{availableToWithdraw}
									</span>
								</Typography>
							</Box>
							<Box className={handleFontSize(balance, rewardsTokens, stakesTotal) ? `${styles.left_section} ${styles.small_font} ` : `${styles.left_section}`}>
								<img className={styles.width_16} src={newToken} />
								<Typography component={'h2'} className={styles.text}>
									{NumberWithDecimals(balance ?? 0)}
								</Typography>
							</Box>
						</Box>
						<Box className={handleFontSize(balance, rewardsTokens, stakesTotal) ? `${styles.ARV_token} ${styles.small_gap} ` : `${styles.ARV_token}`}>
							<Box className={styles.right_section}>
								<LightTooltip
									enterTouchDelay={0}
									leaveTouchDelay={4000}
									placement='bottom'
									title="This is your Reward Wallet, where any rewards from completing tasks and staking will be stored. Please note that Fan rewards token cannot be transferred out of the platform.">
									<span>
										<img alt='info Icon' src={timerIcon} />
									</span>
								</LightTooltip>
								<Typography component={'h3'} className={styles.header_tokens}>Reward Tokens</Typography>
							</Box>
							<Box className={handleFontSize(balance, rewardsTokens, stakesTotal) ? `${styles.left_section} ${styles.small_font} ` : `${styles.left_section}`}>
								<img className={styles.width_16} src={newToken} />
								<Typography component={'h2'} className={styles.text}>
									{abbreviateNumberFixed(rewardsTokens, 2)}
								</Typography>
							</Box>
						</Box>
						<Box className={handleFontSize(balance, rewardsTokens, stakesTotal) ? `${styles.ARV_token} ${styles.small_gap} ` : `${styles.ARV_token}`}>
							<Box className={styles.right_section}>
								<LightTooltip
									enterTouchDelay={0}
									leaveTouchDelay={4000}
									placement='bottom'
									title="This represent the total amount of tokens that you have staked across various projects.">
									<span>
										<img alt='info Icon' src={infoIcon} />
									</span>
								</LightTooltip>
								<Typography component={'h3'} className={styles.header_tokens}>Staked Tokens</Typography>
							</Box>
							<Box className={handleFontSize(balance, rewardsTokens, stakesTotal) ? `${styles.left_section} ${styles.small_font} ` : `${styles.left_section}`}>
								<img className={styles.width_16} src={newToken} />
								<Typography component={'h2'} className={styles.text}>
									{abbreviateNumberFixed(stakesTotal ?? 0, 2)}
								</Typography>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box className={styles.speedUp}>
					<Box className={styles.body}>
						<Box className={styles.top}>
							<Typography component={'h2'}>
								Reward Release Schedule
								<LightTooltip
									enterTouchDelay={0}
									leaveTouchDelay={4000}
									placement='bottom'
									title="Rewards and Bonus Tokens are subject to Film.io's Rewards Release Schedule (RRS) and are released based on your activity, reputation, time, and can be accelerated using gems.">
									<span>
										<img alt='info Icon' src={timerIcon} />
									</span>
								</LightTooltip>
							</Typography>
							<Box className={`${styles.center} ${styles.flex_column}`}>
								<Box className={styles.countdown}>{timeToUnlock}</Box>
								<Box className={styles.progress_container}>
									<SpeedUpBar value={progress} />
								</Box>
								<Grid className={styles.flex_Gems} container xs={12}>
									<Typography className={styles.flex_width} component={'h3'}>Your Gems:</Typography>
									<LightTooltip
										enterTouchDelay={0}
										leaveTouchDelay={4000}
										placement='bottom'
										title="Gems are used to accelerate your RRS and are earned over time (similar to an FGR - the more FAN you stake the more gems you earn), as well as by completing quests at club.film.io"
									>
										<img width={17} height={14} src={gemIcon} alt="Timer Icon" />
									</LightTooltip>
									<Typography className={styles.gem_text} component={'h2'}>{abbreviateNumberFixed(gemTotal ?? 0, 2)} </Typography>
								</Grid>
								<Button className={styles.speedUpBtn} onClick={handleOpenSpeedUp} disabled={progress === '0%' || gemTotal <= 0 || earningsTotal <= 0}>
									<img src={gemIcon} />
									Speed Up
								</Button>
							</Box>
						</Box>
					</Box>
				</Box>
				{!isRestricted ?
					<Box className={styles.whitelisted_wallet}>
						{isWhitelisted ? <Box className={styles.content}>
							<Box className={styles.top}>
								<Box className={styles.wallet_info}>
								</Box>
								<Box className={styles.flex_center}>
									<Typography component={'h2'} className={styles.text}>
										{!isNonCustodial ? 'Whitelist Wallet' : 'Linked Wallet'}
										<LightTooltip
											enterTouchDelay={0}
											leaveTouchDelay={4000}
											placement='bottom'
											title={!isNonCustodial ? 'This allows you to whitelist an external wallet and transfer tokens.' : 'Your platform account has been linked to this external wallet address.'}
										>
											<span className={styles.help_icon} aria-haspopup="true">
												<img alt='info Icon' src={infoIcon} />

											</span>
										</LightTooltip>
									</Typography>
									<span className={styles.whiteAddress}><Icon baseClassName="fal" className="fa-wallet" /> {whitelistAddress}</span>
									{!isNonCustodial && <Button
										className={styles.remove}
										onClick={handleRemoveWallet}
										disabled={isNonCustodial}
									>
										<Icon baseClassName="fas" className="fa-times" />
									</Button>}
								</Box>
								<Box className={styles.buttons}>
									<Typography>Transfer</Typography>
									<Button className={styles.transferIn} onClick={handleOpen}>In</Button>
									<Button
										className={styles.transferOut}
										onClick={handleOpenWhitelistTransfer}
										disabled={isHodl}
									>Out</Button>
								</Box>
								{whitelistTransferDialogOpen && <WhitelistTransferDialog />}
							</Box>
						</Box>
							: <>
								<CustomButton
									customStyle={'outlined_square_reverse'}
									className={styles.whitelist_wallet_btn}
									onClick={handleOpenWhitelistWalletDialog}
								>
									Whitelist Wallet
								</CustomButton>
								<WhitelistWalletDialog />
							</>}
					</Box>
					:
					<Box></Box>
				}
				{open &&
					<Dialog
						open={open}
						disableScrollLock={true}
						className={styles.transferIn}
						modal="true"
						classes={{
							paper: styles.dialog_paper,
							container: styles.dialog_container
						}}
						onClose={handleClose}
					>
						<Box className={styles.dialog_content}>
							<Typography component={'h1'}>Transfer $FAN Into the Platform</Typography>
							<Typography>
								You can transfer $FAN into your platform wallet by using the below wallet address
							</Typography>

							<img className={styles.illustration} src={transferIllustration} />

							<Box className={styles.walletCopy}>
								<button onClick={handleCopyWalletAddress}>Copy</button>
								{walletAddress}
							</Box>

							<Typography className={styles.warning}>
								Before transferring FAN$ onto the platform, please ensure that you double-check your wallet address
							</Typography>

							<Box className={styles.controls}>
								<Button onClick={handleClose}>Close</Button>
							</Box>
						</Box>
						<Box className={styles.detail_dialog}></Box>
					</Dialog>
				}
				<ImportFanTokenDialog />
				<SpeedUpDialog open={openSpeedUpDialog}
					handleClose={handleCloseSpeedUp}
					gemTotal={gemTotal}
					timeToUnlock={timeToUnlock}
					rewardsTokens={rewardsTokens}
					releasedTokens={unlockedRewards}
					percentage={progress}
					updateOverview={updateOverview}
				/>
				<Box className={styles.external_wallet}>
					{!isNonCustodial && (
						<>
							<CustomButton
								customStyle={'outlined'}
								onClick={handleOpenClaimWallet}
							>
								Claim Your Wallet!
							</CustomButton>
							<Typography>
								You can now take control of your Film.io wallet, allowing you to
								use a 3rd party application like metamask to manage your FAN
								Tokens, login to and transact within the app.
							</Typography>
							<ClaimWalletDialog />
						</>
					)}
				</Box>
			</Box>
			:
			<Box className={styles.overview_isHodl}>
				{isNcWithoutMagic &&
					<Box className={styles.rewards_locked}>
						<CustomButton customStyle={'outlined_square'} onClick={handleUnlockRewards}>
							Setup Your Platform Wallet
						</CustomButton>
					</Box>
				}
				<Box className={styles.row_group}>
					<Box className={styles.total_tokens}>
						<Box className={styles.body}>
							<Box className={styles.top}>
								<Typography component={'h2'} className={styles.header_token_hodl}>Total Tokens
									<LightTooltip
										enterTouchDelay={0}
										leaveTouchDelay={4000}
										placement='bottom'
										title='The Platform Wallet is a combination of both Available and Reward Token. Available tokens are FAN$ transferred in, and they can be transferred out. Reward tokens are tokens received on the platform.'>
										<span>
											<img alt='info Icon' src={infoIcon} />
										</span>
									</LightTooltip>
								</Typography>

							</Box>
							<Box className={styles.number_copy}>
								<Box className={styles.center}>
									<img className={styles.width_20} src={newToken} />
									<Typography component={'h1'} className={styles.text}>
										{abbreviateNumberFixed(balanceTotal ?? 0, 2)}
									</Typography>
								</Box>
								<Box className={styles.bottom}>
									<Button onClick={handleCopyWalletAddress}
										startIcon={<Icon baseClassName="fas" className="fa-copy" />} className={styles.copy}>
										{shortwalletAddress}
									</Button>
								</Box>
							</Box>
						</Box>
					</Box>
					<Box className={styles.diamond_circle_HODL}>
						<Box className={styles.body}>
							<Box className={styles.top}>
								<Typography component={'h2'} className={styles.header_token_hodl}>
									Diamond Circle HODL
									<LightTooltip
										enterTouchDelay={0}
										leaveTouchDelay={4000}
										placement='bottom'
										title={
											<>
												<p style={{ 'lineHeight': '17px' }}><strong>Your Diamond Circle Tokens will increase with each monthly Vesting release. Your bonus tokens will be based on the total amount at the end of the HODL period that you selected and will then be released over {type?.hodlPeriod} months</strong></p>
												<p style={{ 'lineHeight': '17px' }}>
													<strong>{type?.bonusMultiplier}% Bonus</strong>
												</p>
												<p style={{ 'lineHeight': '17px' }}>
													<strong>Bonus Earnings To Date:</strong>
													<p style={{ 'lineHeight': '1', 'marginTop': '3px' }}>
														{abbreviateNumberFixed(earningsToDate, 1)} $FAN
													</p>
												</p>
												<p style={{ 'lineHeight': '17px' }}>
													<b>HODL Period: </b>
													<p style={{ 'lineHeight': '1', 'marginTop': '3px' }}>{formatDate(startDate)} - {formatDate(endDate)}</p>
												</p>

											</>
										}>
										<span>
											<img alt='info Icon' src={InfoDiamond} />
										</span>
									</LightTooltip>
								</Typography>

							</Box>
							<Box className={styles.number_copy}>
								<Box className={styles.center}>
									<img className={styles.width_20} src={newToken} />
									<Typography component={'h1'} className={styles.text}>
										{abbreviateNumberFixed(hodlAmount, 1)}
									</Typography>
								</Box>
								<Box className={styles.bottom}>
									<Button onClick={onClickChangeTab} className={styles.wht_btn}>
										View More
									</Button>
								</Box>
							</Box>
						</Box>
					</Box>
					<Box className={styles.tokens_section}>
						<Box className={handleFontSize(balance, rewardsTokens, stakesTotal) ? `${styles.ARV_token} ${styles.small_gap} ` : `${styles.ARV_token}`}>
						{isIngXRestrictedInvestor && 
							<Box className={styles.green_info}>
								<LightTooltip
										enterTouchDelay={0}
										leaveTouchDelay={4000}
										placement='bottom'
										title="Your withdrawable amount is calculated based on the 30-day trailing Average Daily Trading Volume (ADTV) and the pro rata share of Principal FAN Token you have been awarded."
									>
										<span>
										<img src={GreenInfo} alt='gree Info'/>
										</span>
									</LightTooltip>
							</Box>	
						}
							<Box className={styles.right_section}>
								<LightTooltip
									enterTouchDelay={0}
									leaveTouchDelay={4000}
									placement='bottom'
									title="Available Tokens are $FAN that have not yet been staked to any project. Staking these tokens will earn you more FGR."
								>
									<span>
										<img alt='info Icon' src={unLockIcon} />
									</span>
								</LightTooltip>
								<Typography component={'h3'} className={`${styles.header_tokens} ${styles.availableToken}`}>
									Available Tokens
									<span className={styles.availableToWithdraw}>
										{availableToWithdraw}
									</span>
								</Typography>
							</Box>
							<Box className={handleFontSize(balance, rewardsTokens, stakesTotal) ? `${styles.left_section} ${styles.small_font} ` : `${styles.left_section}`}>
								<img className={styles.width_16} src={newToken} />
								<Typography component={'h2'} className={styles.text}>
									{NumberWithDecimals(balance ?? 0)}
								</Typography>
							</Box>
						</Box>
						<Box className={handleFontSize(balance, rewardsTokens, stakesTotal) ? `${styles.ARV_token} ${styles.small_gap} ` : `${styles.ARV_token}`}>
							<Box className={styles.right_section}>
								<LightTooltip
									enterTouchDelay={0}
									leaveTouchDelay={4000}
									placement='bottom'
									title="This is your Reward Wallet, where any rewards from completing tasks and staking will be stored. Please note that Fan rewards token cannot be transferred out of the platform.">
									<span>
										<img alt='info Icon' src={timerIcon} />
									</span>
								</LightTooltip>
								<Typography component={'h3'} className={styles.header_tokens}>Reward Tokens</Typography>
							</Box>
							<Box className={handleFontSize(balance, rewardsTokens, stakesTotal) ? `${styles.left_section} ${styles.small_font} ` : `${styles.left_section}`}>
								<img className={styles.width_16} src={newToken} />
								<Typography component={'h2'} className={styles.text}>
									{abbreviateNumberFixed((earningsTotal ?? 0) - (unlockedRewards ?? 0), 2)}
								</Typography>
							</Box>
						</Box>
						<Box className={handleFontSize(balance, rewardsTokens, stakesTotal) ? `${styles.ARV_token} ${styles.small_gap} ` : `${styles.ARV_token}`}>
							<Box className={styles.right_section}>
								<LightTooltip
									enterTouchDelay={0}
									leaveTouchDelay={4000}
									placement='bottom'
									title="This represent the total amount of tokens that you have staked across various projects.">
									<span>
										<img alt='info Icon' src={infoIcon} />
									</span>
								</LightTooltip>
								<Typography component={'h3'} className={styles.header_tokens}>Staked Tokens</Typography>
							</Box>
							<Box className={handleFontSize(balance, rewardsTokens, stakesTotal) ? `${styles.left_section} ${styles.small_font} ` : `${styles.left_section}`}>
								<img className={styles.width_16} src={newToken} />
								<Typography component={'h2'} className={styles.text}>
									{abbreviateNumberFixed(stakesTotal ?? 0, 2)}
								</Typography>
							</Box>
						</Box>
					</Box>
				</Box>



				<Box className={styles.speedUp}>
					<Box className={styles.body}>
						<Box className={styles.top}>
							<Typography component={'h2'}>
								Reward Release Schedule
								<LightTooltip
									enterTouchDelay={0}
									leaveTouchDelay={4000}
									placement='bottom'
									title="Rewards and Bonus Tokens are subject to Film.io's Rewards Release Schedule (RRS) and are released based on your activity, reputation, time, and can be accelerated using gems.">
									<span>
										<img alt='info Icon' src={timerIcon} />
									</span>
								</LightTooltip>
							</Typography>
							<Box className={`${styles.center} ${styles.flex_column}`}>
								<Box className={styles.countdown}>{timeToUnlock}</Box>
								<Box className={styles.progress_container}>
								<SpeedUpBar value={progress}  />
								</Box> 
								<Grid className={styles.flex_Gems} container xs={12}>
									<Typography className={styles.flex_width} component={'h3'}>Your Gems:</Typography>
									<LightTooltip
										enterTouchDelay={0}
										leaveTouchDelay={4000}
										placement='bottom'
										title="Gems are used to accelerate your RRS and are earned over time (similar to an FGR - the more FAN you stake the more gems you earn), as well as by completing quests at club.film.io"
									>
										<img width={17} height={14} src={gemIcon} alt="Timer Icon" />
									</LightTooltip>
									<Typography className={styles.gem_text} component={'h2'}>{abbreviateNumberFixed(gemTotal ?? 0, 2)} </Typography>
								</Grid>
								<Button className={styles.speedUpBtn} onClick={handleOpenSpeedUp} disabled={progress === '0%' || gemTotal <= 0 || earningsTotal <= 0}>
									<img src={gemIcon} />
									Speed Up
								</Button>
							</Box>
						</Box>
					</Box>
				</Box>

				<Box className={styles.whitelisted_wallet}>
					{isWhitelisted ? <Box className={styles.content}>
						<Box className={styles.top}>
							<Box className={styles.wallet_info}>
							</Box>
							<Box className={styles.flex_center}>
								<Typography component={'h2'} className={styles.text}>
									{!isNonCustodial ? 'Whitelist Wallet' : 'Linked Wallet'}
									<LightTooltip
										enterTouchDelay={0}
										leaveTouchDelay={4000}
										placement='bottom'
										title={!isNonCustodial ? 'This allows you to whitelist an external wallet and transfer tokens.' : 'Your platform account has been linked to this external wallet address.'}
									>
										<span className={styles.help_icon} aria-haspopup="true">
											<img alt='info Icon' src={infoIcon} className={styles.info_img} />
										</span>
									</LightTooltip>
								</Typography>
								<span className={styles.whiteAddress}><Icon baseClassName="fal" className="fa-wallet" /> {whitelistAddress}</span>
								{!isNonCustodial && <Button
									className={styles.remove}
									onClick={handleRemoveWallet}
									disabled={isNonCustodial}
								>
									<Icon baseClassName="fas" className="fa-times" />
								</Button>}
							</Box>
							<Box className={styles.buttons}>
								<Typography>Transfer</Typography>
								<Button className={styles.transferIn} onClick={handleOpen}>In</Button>
								<Button
									className={styles.transferOut}
									onClick={handleOpenWhitelistTransfer}
									disabled={isHodl}>
									Out
								</Button>
							</Box>
							{whitelistTransferDialogOpen && <WhitelistTransferDialog />}
						</Box>
					</Box>
						: <>
							<CustomButton
								customStyle={'outlined_square_reverse'}
								className={styles.whitelist_wallet_btn}
								onClick={handleOpenWhitelistWalletDialog}
							>
								Whitelist Wallet
							</CustomButton>
							<WhitelistWalletDialog />
						</>}
				</Box>

				{open &&
					<Dialog
						open={open}
						disableScrollLock={true}
						className={styles.transferIn}
						modal="true"
						classes={{
							paper: styles.dialog_paper,
							container: styles.dialog_container
						}}
						onClose={handleClose}
					>
						<Box className={styles.dialog_content}>
							<Typography component={'h1'}>Transfer $FAN Into the Platform</Typography>
							<Typography>
								You can transfer $FAN into your platform wallet by using the below wallet address
							</Typography>

							<img className={styles.illustration} src={transferIllustration} />

							<Box className={styles.walletCopy}>
								<button onClick={handleCopyWalletAddress}>Copy</button>
								{walletAddress}
							</Box>

							<Typography className={styles.warning}>
								Before transferring FAN$ onto the platform, please ensure that you double-check your wallet address
							</Typography>

							<Box className={styles.controls}>
								<Button onClick={handleClose}>Close</Button>
							</Box>
						</Box>
						<Box className={styles.detail_dialog}></Box>
					</Dialog>
				}
				<ImportFanTokenDialog />
				<SpeedUpDialog open={openSpeedUpDialog}
					handleClose={handleCloseSpeedUp}
					gemTotal={gemTotal}
					timeToUnlock={timeToUnlock}
					rewardsTokens={rewardsTokens}
					releasedTokens={unlockedRewards}
					percentage={progress}
					updateOverview={updateOverview}
				/>
				<Box className={styles.external_wallet}>
					{!isNonCustodial && (
						<>
							<CustomButton
								customStyle={'outlined'}
								onClick={handleOpenClaimWallet}
							>
								Claim Your Wallet!
							</CustomButton>
							<Typography>
								You can now take control of your Film.io wallet, allowing you to
								use a 3rd party application like metamask to manage your FAN
								Tokens, login to and transact within the app.
							</Typography>
							<ClaimWalletDialog />
						</>
					)}
				</Box>
			</Box>)
}

WalletOverview.propTypes = {
	changeTab: PropTypes.func.isRequired
}

export default WalletOverview