import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import styles from './Callout.module.scss'

const Callout = ({ title, content, top, right, bottom, left }) => {
	const getClasses = () => {
		const classes = [styles.callout]
		if (top) classes.push(styles.top)
		if (right) classes.push(styles.right)
		if (bottom) classes.push(styles.bottom)
		if (left) classes.push(styles.left)
		return classes.join(' ')
	}

	return (
		<Box className={getClasses()}>
			<Typography component={'span'}>{title}</Typography>
			<Typography component={'p'}>{content}</Typography>
		</Box>
	)
}

Callout.propTypes = {
	title: PropTypes.string,
	content: PropTypes.string,
	top: PropTypes.bool,
	left: PropTypes.bool,
	right: PropTypes.bool,
	bottom: PropTypes.bool
}

export default Callout
