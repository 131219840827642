import { Box, Typography } from '@mui/material'
import images from 'assets/images'
import styles from './CommunitySection.module.scss'

const CommunitySection = () => {
	return (
		<Box className={styles.comunity}>
			<Box className={styles.content}>
				<Typography component={'p'} className={styles.header}>
					Chat with the team and others in the community to learn more about
					Film.io and help greenlight the future of entertainment.
				</Typography>
				<Box className={styles.links}>
					<a
						href="https://discord.gg/joinFilmio"
						target="_blank"
						rel="noreferrer"
					>
						<img src={images.discord} />
					</a>
					<a
						href="https://twitter.com/Filmio_Official"
						target="_blank"
						rel="noreferrer"
					>
						<img src={images.twitter} />
					</a>
					<a
						href="https://t.me/officialfilmio"
						target="_blank"
						rel="noreferrer"
					>
						<img src={images.telegram} />
					</a>
				</Box>
			</Box>
		</Box>
	)
}

export default CommunitySection
