import PropTypes from 'prop-types'
import {
	Button,
	Dialog,
	Checkbox,
	DialogContent,
	Typography,
	Paper,
	Box,
	Grid
} from '@mui/material'
import { useDispatch } from 'react-redux'
import styles from './joinContestDialog.module.scss'
import { styled } from '@mui/material/styles'
import { useRef, useState } from 'react'
import ArrowDown from 'assets/images/arrow_down.svg'
import useAxiosFetch from '../../../hooks/useAxiosFetch'
import { enrollContest } from '../../../services/contest.service'
import { updateProject } from '../projectDetailSlice'
import { setContestJoinedDialogOpen } from '../../header/signInSlice'
const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.type === 'dark' ? '#1A1938' : '#1A1938',
	...theme.typography.body2,
	padding: '13px 24px',
	textAlign: 'center',
	color: theme.palette.primary.main,
	border: 'none',
	borderRadius: '16px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
}))

const DialogWithbackground = styled(Dialog)(({ theme }) => ({
	backgroundColor: theme.palette.type === 'dark' ? '#000000b3' : '#000000b3',
	...theme.typography.body2
}))
const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
	color: '#fff',
	width: '14px',
	height: '14px',
	...theme.typography.body2
}))

export const JoinContestDialog = ({
	projectId,
	contestId,
	open,
	readOnly,
	handleClose
}) => {
	const dispatch = useDispatch()
	const [checked, setChecked] = useState(false)
	const [isConsentGiven, setIsConsentGiven] = useState(false)

	const [{ loading: isEnrolling }, enroll] = useAxiosFetch(
		enrollContest({
			contestId,
			projectId
		}),
		data => {
			dispatch(
				updateProject({
					contests: data.contests
				})
			)
			handleClose()
			dispatch(setContestJoinedDialogOpen(true))
		}
	)

	const handleCloseDialog = () => {
		setIsConsentGiven(false)
		handleClose()
	}

	const scrollContainerRef = useRef(null)

	const checkScroll = container => {
		if (container) {
			const maxScroll = container.scrollHeight - container.clientHeight
			const currentScroll = container.scrollTop

			if (currentScroll >= maxScroll - 200) {
				setIsConsentGiven(true)
			}
		}
	}

	const handleScroll = () => {
		const container = scrollContainerRef.current
		checkScroll(container)
	}

	const handleScrollDown = () => {
		const container = scrollContainerRef.current
		const maxScroll = container.scrollHeight - container.clientHeight
		if (container) {
			container.scrollBy({ top: maxScroll, left: 0, behavior: 'smooth' })
			setTimeout(() => checkScroll(container), 300)
		}
	}

	const handleEnrollClick = () => {
		enroll()
	}

	return (
		<DialogWithbackground
			open={open}
			disableScrollLock={true}
			className={`${styles.dialog} warning`}
			modal="true"
			classes={{
				paper: styles.dialog_paper,
				container: styles.dialog_container
			}}
			onClose={handleCloseDialog}
		>
			<DialogContent className={styles.dialog_content}>
				<Typography component={'h2'}>
					Official Rules – Film.io Collaboration Contest
				</Typography>
				<Box>
					<Item>
						<Grid container>
							<ul
								ref={scrollContainerRef}
								className={styles.main_list}
								onScroll={handleScroll}
							>
								<Typography>
									NO PURCHASE, DEPOSIT OR TRADE NECESSARY TO ENTER OR WIN. NO PURCHASE OR PAYMENT OF ANY KIND WILL NOT INCREASE YOUR CHANCES OF WINNING. THE FOLLOWING CONTEST IS OPEN TO LEGAL RESIDENTS OF THE UNITED STATES (EXCEPT RHODE ISLAND, PUERTO RICO, GUAM, US VIRGIN ISLANDS, AND THE DISTRICT OF COLUMBIA), AND RESIDENTS FROM ALL OVER THE WORLD, BUT EXPRESSLY EXCLUDING ANY FOREIGN JURISDICTION WHERE SUCH CONTEST ARE PROHIBITED BY LAW, WHO MEET THE ELIGIBILITY REQUIREMENTS (AS DEFINED BY THE RULES BELOW). VOID WHERE PROHIBITED OR RESTRICTED BY LAW. THE SUBMISSION OF AN ENTRY IN THIS CONTEST CONSTITUTES UNCONDITIONAL ACCEPTANCE OF THESE OFFICIAL RULES AND THE INTENT TO BE LEGALLY BOUND HEREBY.
								</Typography>
								<Typography>
									BY PARTICIPATING IN THE CONTEST, PARTICIPANT FULLY AND UNCONDITIONALLY ACCEPTS THESE RULES, INCLUDING THE DECISIONS AND INTERPRETATIONS OF ORGANIZER, WHICH ARE FINAL AND BINDING ON ALL MATTERS RELATING TO THE CONTEST.  FAILURE TO COMPLY WITH THESE RULES, POLICIES AND PROCEDURES WILL VOID PARTICIPANT’S ENTRY.
								</Typography>
								<li className={styles.level1}>
									Eligibility
									<ul>
										<li className={styles.level2}>
											The Collaboration Contest (“Contest“) is open to creators who
											have an active project on the Film.io platform during the Contest Period
											(defined below) and is operated by Filmio.io (the “Organizer”).
										</li>
										<li className={styles.level2}>
											The Contest is open to all legal residents of the United States (excluding Rhode Island,
											Puerto Rico, Guam, the US Virgin Islands, and other United States overseas territories),
											the District of Columbia, and any other country or jurisdiction (excluding any foreign
											jurisdiction where such Contest are prohibited by law),  who are eighteen (18)
											years of age or older at the beginning of the Contest Entry Period.
										</li>
										<li className={styles.level2}>
											The following classes of persons are not eligible to enter, participate or win: (a) Employees,
											officers, investors, vendors, and directors of Organizer, each of their respective franchisees,
											affiliated companies, subsidiaries, distributors, retailers, printers, advertising and promotion
											agencies, and any and all other companies associated with the design or execution of this promotion
											(collectively “Released Parties”), or (b) the members of the immediate families or households of any
											of the above, whether or not related. “Immediate family members” includes, for purposes of this Contest,
											parents (including in-laws), grandparents, siblings (including stepsiblings), children (including stepchildren),
											grand children (including step grandchildren) and each of their respective spouses.
										</li>
									</ul>
								</li>
								<li className={styles.level1}>
									Contest Period
									<ul>
										<li className={styles.level2}>
											The Contest begins on March 8, 2024, and ends on December 10,
											2024 (“Contest Period” latest update on July 10, 2024).
										</li>
										<li className={styles.level2}>
											Projects must be opted in to the Contest by the creator during the Contest Period to be eligible.
										</li>
									</ul>
								</li>
								<li className={styles.level1}>
									How to Enter
									<ul>
										<li className={styles.level2}>
											To participate, Creators must opt in to the Contest by agreeing to these Official Rules.
										</li>
										<li className={styles.level2}>
											Opt-in can be done through the designated opt-in page or by responding to the {' '}
											<a href='https://docs.google.com/forms/d/e/1FAIpQLScokCR9V5SCVXJpEASAoS3CkifiNwjqi_AgWhBFMlrR3Bg5Dw/viewform'
												target="_blank" rel="noreferrer"
											>
												opt-in email.
											</a>
										</li>
										<li className={styles.level2}>
											During the Contest Period, Participant shall engage in
											activities that contribute to their &#34;Go Score&#34;
											calculation. The proprietary Film.io Go Score is
											determined by many factors, including but not limited to:
											<p>
												a. Reviews and Ratings: High ratings and numerous
												reviews from fans indicate project quality and appeal.
											</p>
											<p>
												b. Followers: A higher number of followers suggests
												ongoing fan engagement and support.
											</p>
											<p>
												c. Referrals: Frequent fan promotion of the project
												reflects its viral potential and broad appeal.
											</p>
											<p>
												d. Staked Tokens: The amount of $FAN Tokens staked on a
												project represents fans&apos; financial support and
												confidence.
											</p>
											<p>
												e. Views: The number of views reflects the project’s overall reach and initial interest.
											</p>
											<p>
												f. Creator Engagement Score (CES): The CES measures the
												creator&apos;s active involvement on the platform,
												including but not limited to daily logins and newsreel
												activity.
											</p>
											<p>
												The Go Score is subject to a time decay mechanism, which
												decrements based on creator inactivity. If creators fail
												to engage over a specified period, their Go Score will
												gradually decrease.
											</p>
										</li>
										<li className={styles.level2}>
											By participating in Contest, each participant (herein
											known as the &#34;Participant&#34;) fully and
											unconditionally accepts and agrees to comply with and
											abide by these official rules (the &#34;Official
											Rules&#34;) and all decisions of the Organizer, which
											shall be final.
										</li>
										<li className={styles.level2}>
											Organizer is not responsible for lost, late, misdirected,
											illegible, incomplete, invalid, unintelligible, damaged,
											or for entries submitted in a manner that is not expressly
											allowed under these rules, or for any entry not submitted
											or received or lost due to any technical error or failure,
											human or programming error, unauthorized human
											intervention, inaccurate capture or mis-entry of any
											required information, or the failure to capture any such
											information or effects of hackers or failure of any
											electronic equipment, computer transmissions and/or
											network connections; all of which will be disqualified.
										</li>
										<li className={styles.level2}>
											Each Participant must submit entries under one account.
											Any attempt by any Participant to obtain more than the
											stated number of entries allowed by using
											multiple/different email accounts, identities, or any
											other methods shall void that Participant&apos;s entries
											and that Participant will be disqualified and ineligible
											to receive any prize. No mechanically reproduced or
											automated entries are permitted. Use of any automated
											system to participate is prohibited and will result in
											disqualification and all such entries will be deemed void.
											In the event of a dispute as to an entry, the authorized
											account holder used to register will be deemed to be the
											Participant and they must be eligible according to these
											Official Rules. The &#34;authorized account holder&#34; is
											the natural person who is assigned the email account used
											for the entry.
										</li>
									</ul>
								</li>
								<li className={styles.level1}>
									Go Score
									<sup style={{ 'font-variant': 'all-small-caps' }}>
										TM
									</sup>{' '}
									Calculation
									<ul>
										<li className={styles.level2}>
											The Go Score will be the winning determinant for all
											projects that have opted in.
										</li>
										<li className={styles.level2}>
											The Go Score will be calculated based on the Film.io
											platform&apos;s Go Score v2 algorithm, which takes into
											account several criteria, including, but not limited to
											such as (a) creator activity, (b) engagement, and (c)
											project performance.
										</li>
									</ul>
								</li>
								<li className={styles.level1}>
									Prizes
									<ul>
										<li className={styles.level2}>
											The Participant with the highest Go Score on December 10, 2024,
											will be declared the winner and will receive a grand prize of
											$25,000. The top users on the leaderboard as of July 24, 2024
											will share a total Fan Prize of $5,000, (each, a “Winner”).
											Note that the grand prize Winner is subject to a payment schedule
											and additional ongoing requirements after being selected as a winner.
											Please carefully review Sections 7 (Winner Obligations) and 14
											(Payment Payout Schedule). IF YOU DO NOT AGREE WITH THE REQUIREMENTS SET
											FORTH IN THESE TERMS AND SECTIONS 7 AND 14 SO NOT PARTICIPATE IN THE CONTEST.
											IN THE EVENT THAT A WINNER DOES NOT AGREE TO COMPLY WITH THESE TERMS AFTER
											WINNER SELECTION, WE RESERVE THE RIGHT TO VOID THE APPLICABLE ENTRY AND THE
											PRIZE WILL BE AWARDED TO THE SECOND HIGHEST GO SCORE.
										</li>
										<li className={styles.level2}>
											Prizes are non-assignable, non-substitutable and non-transferable without
											the prior written consent of the Organizer, which consent may be withheld
											for any reason and in the Organizer’s sole and absolute discretion.
											Organizer reserves the right to substitute a prize of equal or greater value if
											an advertised prize becomes unavailable. The Prizes may be provided in cash,
											cryptocurrency, FAN Tokens, or a combination thereof, subject to Organizer’s
											reasonable discretion.  All details of any prize not specified herein shall be
											determined solely by Organizer. All Participants agree to be bound by these
											Official Rules and the decisions of Organizer and/or its authorized representatives,
											whose decisions in all aspects of the Contest shall be final and binding.
											By accepting a prize, the Contest Winner acknowledges compliance with these Official Rules.
											The Winner must accept delivery of prize from Organizer, or as otherwise instructed by Organizer.
											All other costs related to prize delivery and acceptance is the responsibility of the Winner
											including but not limited to federal, state and local taxes. All properly claimed prizes will be awarded.
										</li>
									</ul>
								</li>
								<li className={styles.level1}>
									Winner Selection and Notification
									<ul>
										<li className={styles.level2}>
											The Participating winners will be selected based on the highest Go Score at the end of the Contest Period as determined on December 10th, 2024.
										</li>
										<li className={styles.level2}>
											The ten (10) fan winners will be selected based on their being in positions 1-10 on the community leaderboard at the time of the Fan Prize winners selection on July 24, 2024.
										</li>
										<li className={styles.level2}>
											Fan Prize winners will be notified via email and announced on the Film.io platform on August 8, 2024. Creator Prize winners will be notified via email and announced on the Film.io platform by December 24, 2024
										</li>
										<li className={styles.level2}>
											All winners must respond to the notification within 7 days to claim their prize. Failure to respond within this timeframe may result in forfeiture of the prize.
										</li>
										<li className={styles.level2}>
											All prize values shall be in United States Dollars (USD).
										</li>
									</ul>
								</li>
								<li className={styles.level1}>
									Winner Obligations
									<ul>
										<li className={styles.level2}>
											By accepting the prize, the Participant winner agrees to:
											<p>
												a. Provide executive producer credits to Film.io in the
												project.{' '}
											</p>
											<p>
												b. Grant Film.io publicity rights related to the project
												and the Contest.
											</p>
											<p>
												c. Include the Film.io logo in the project&apos;s
												promotional materials and the beginning or ending
												sequence of the project.{' '}
											</p>
											<p>
												d. Participate in reasonable publicity and marketing
												activities related to the Contest and the Film.io
												platform.{' '}
											</p>
										</li>
										<li className={styles.level2}>
											All winners must sign a Release and Affidavit of
											Eligibility before receiving the prize.{' '}
										</li>
									</ul>
								</li>
								<li className={styles.level1}>
									Publicity Release
									<p>
										By participating in this Contest, Participants grant and express unconditional permission for Organizer to edit and use name, video, photographs, images, logos, likeness, audio, biographical information, statements, testimonials, personal exposition, address, and prize information of Participant (collectively, “Attributes”) for advertising and/or publicity purposes worldwide and in all forms of media, including the designated landing page, now known or hereafter devised, in perpetuity, without further compensation or authorization, except if you are a resident of the state of Tennessee or as otherwise prohibited by applicable Laws, Participant shall release, hold harmless and defend the Organizer from any and all claims arising from the use of such Attributes. IF YOU DO NOT WISH TO HAVE YOUR ATTRIBUTES USED IN THE MANNER DESCRIBED HEREIN, DO NOT ENTER THIS CONTEST. The Organizer is not and cannot be held responsible for any unauthorized use of a Participant’s Attributes.
									</p>
								</li>
								<li className={styles.level1}>
									Intellectual Property
									<ul>
										<li className={styles.level2}>
											By opting in to the Contest, Participant retains all intellectual property rights to their projects (the “Intellectual Property”).
										</li>
										<li className={styles.level2}>
											Participant grants Film.io a non-exclusive, worldwide, royalty-free license to use, reproduce, and display the project for promotional and marketing purposes related to the Contest and the Film.io platform.
										</li>
										<li className={styles.level2}>
											Participant represents and warrants that the Participant solely owns, or otherwise has the full right and permission to exploit, all of the rights in, to, and under the Intellectual Property and to grant the rights and licenses set forth herein and that the Intellectual Property does not and will not infringe upon any third party’s intellectual property rights, right of publicity or privacy, or any other proprietary rights, whether contractual, statutory or common law.
										</li>
									</ul>
								</li>
								<li className={styles.level1}>
									Indemnification and Release of Claims
									<ul>
										<li className={styles.level2}>
											All Participants and their legally defined heirs, executors, administrators and successors (individually and collectively, the “Releasing Parties”), as a condition in participating this Contest, agree to unconditionally and irrevocably release, discharge, hold harmless and defend the Organizer, and each of their respective past and present employees, officers, directors, representatives, agents, franchisees, licensees, subsidiaries, parent companies, affiliates, shareholders, investors, vendors, owners and members and all other individuals or entities involved in the administration, advertisement or promotion of this Contest and/or the award and delivery of any prize (individually and collectively, the “Released Parties”), from any liability, claims, litigation, losses, actions, or damages of any kind, whether actual, incidental or consequential, for injury (including but not limited to death), illness, damages, losses or expenses arising out of or relating to Participant’s participation in this Contest, or the acceptance, possession or use/misuse of any prize, or participation in prize-related activities.
										</li>
										<li className={styles.level2}>
											By participating in this Contest, Releasing Parties further agree to abide by and be bound wholly by these Official Rules and waive any right to claim any ambiguity or error therein or in this Contest itself, and to be bound by the decisions of the Organizer, which shall be final in all matters relating to this Contest. In the event a Participant wins a prize and is later found to be in violation of these rules he or she will be required to forfeit the prize or to reimburse Organizer for the stated value of the prize if such violation is discovered after a Winner has used the prize.
										</li>
										<li className={styles.level2}>
											Organizer shall not be liable to the Winner or any other person for failure to supply the prize or any part thereof by reason of any acts of God, any actions, regulations, orders, or requests by any governmental entity, equipment failure, terrorist acts, war, fire, unusually severe weather, embargo, labor dispute or strike, labor or material shortage, transportation interruption of any kind, or any other cause beyond Organizer’s sole control.
										</li>
										<li className={styles.level2}>
											The Released Parties are not responsible for, and each
											Participant hereby releases any claims and all liability
											for (i) the inability of a Participant to complete or
											continue an entry due to equipment malfunction, busy
											lines, inadvertent disconnections, acts beyond the
											Released Parties control, or otherwise; (ii) entries due
											to technical difficulties or transmission errors; (iii)
											any delays, mis-delivery, loss, or failure in the delivery
											of any item sent by mail, courier, express, electronic
											transmission, or other delivery method; (iv) mechanical,
											technical, electronic, communications, telephone,
											computer, hardware or software errors, malfunctions or
											failures of any kind, including: failed, incomplete,
											garbled or delayed transmission of online entries, traffic
											congestion on telephone lines, the Internet or at any
											website or lost or unavailable network connections which
											may limit an online Participant&apos;s ability to
											participate in this Contest; (v) any injury or damage to
											Participant&apos;s or any other person&apos;s computer or
											mobile phone related to or resulting from participating in
											or downloading any information necessary to participate in
											the Contest; and (vi) other errors of any kind, whether
											computer, technical, typographical, printing, human or
											otherwise, relating to or in connection with this Contest,
											including, without limitations, errors which may occur in
											connection with the printing or advertising of this
											Contest, administration or execution of the Contest, the
											processing of entries or in the announcement of the
											Winner. Organizer, in its sole discretion, reserves the
											right to disqualify any person tampering with the entry
											process or the operation of the Organizer&apos;s website
											or any Organizer account.{' '}
										</li>
										<li className={styles.level2}>
											NOTWITHSTANDING ANY PROVISION OF THESE OFFICIAL RULES TO
											THE CONTRARY AND TO THE FULLEST EXTENT PERMISSIBLE BY LAW,
											UNDER NO CIRCUMSTANCES SHALL ORGANIZER OR ANY RELEASED
											PARTIES&apos; LIABILITY TO PARTICIPANTS EXCEED $1.00. IN
											NO EVENT SHALL ORGANIZER OR ANY RELEASED PARTIES&apos; BE
											LIABLE TO ANY PARTICIPANT FOR ANY DAMAGES SPECIAL,
											INCIDENTAL, CONSEQUENTIAL, INDIRECT, PUNITIVE OR EXEMPLARY
											DAMAGES, HOWEVER CAUSED AND REGARDLESS OF THE THEORY OF
											LIABILITY. EACH OF THE LIMITATIONS SET FORTH IN THIS
											PARAGRAPH SHALL APPLY EVEN IF ORGANIZER HAS BEEN ADVISED
											OR IS AWARE OF THE POSSIBILITY OF SUCH DAMAGES. SOME
											JURISDICTIONS DO NOT ALLOW THE LIMITATIONS OR EXCLUSION OF
											LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE
											ABOVE MAY NOT APPLY TO YOU.
										</li>
									</ul>
								</li>
								<li className={styles.level1}>
									Disqualification/Contest Cancellation
									<ul>
										<li className={styles.level2}>
											Organizer reserves the right to cancel, modify or
											terminate the Contest if fraud, misconduct, or technical
											failures destroy or threaten the integrity of this portion
											of the Contest, in the opinion of the Organizer, or if a
											computer virus, bug, or other technical problem corrupts
											the administration, security, or proper conduct of the
											Contest as determined by Organizer, in their sole
											discretion. In the event of early termination of the
											Contest, Organizer reserves the right to determine any
											eligible prize winners from among all eligible,
											non-suspect entries received as of the time/date of such
											termination.{' '}
										</li>
										<li className={styles.level2}>
											If due to circumstances beyond the control of the
											Organizer, any event associated with this Contest or the
											prize is delayed, rescheduled, postponed, or cancelled,
											Organizer reserves the right, but not the obligation, to
											cancel or modify the Contest and shall not be required to
											award a substitute prize.
										</li>
									</ul>
								</li>
								<li className={styles.level1}>
									Arbitration; Choice of Law
									<ul>
										<li className={styles.level2}>
											Except where prohibited by law, as a condition of
											participating in this Contest, each Participant agrees
											that (a) any and all disputes and causes of action arising
											out of or connected with this Contest, including but not
											limited to a prize awarded, shall be resolved
											individually, without resort to any form of class action,
											and exclusively by final and binding arbitration under the
											rules of the BVI International Arbitration Centre and held
											at in the British Virgin Islands The judgment upon such
											arbitration award may be entered in any court having
											jurisdiction. Participant further agrees that any and all
											disputes, claims, and causes of action arising out of or
											connected with this Contest, or any prize awarded shall be
											resolved individually, without resort to any form of class
											action.
										</li>
										<li className={styles.level2}>
											All issues and questions concerning the construction,
											validity, interpretation, and enforceability of these
											Official Rules, or the rights and obligations of the
											Participant, and/or Organizer in connection with the
											Contest, shall be governed by, and construed in accordance
											with, the laws of the State of California, without giving
											effect to any choice of law or conflict of law rules,
											which would cause the application of the laws of any
											jurisdiction other than the State of California.
										</li>
										<li className={styles.level2}>
											The invalidity or unenforceability of any provision of these Official Rules shall not affect the validity or enforceability of any other provision so long as the general intent of these Official Rules remains in effect.  In the event that any such provision is determined to be invalid or otherwise unenforceable, these Official Rules shall be construed in accordance with their terms as if the invalid or unenforceable provision were not contained therein.
										</li>
									</ul>
								</li>
								<li className={styles.level1}>
									Non-Defamation
									<ul>
										<li className={styles.level2}>
											The Participant agrees not to make, publish, or
											communicate to any person or entity any defamatory or
											disparaging remarks, comments, or statements concerning
											Film.io, its affiliates, or any of their respective
											directors, officers, employees, agents, or
											representatives, including but not limited to remarks,
											comments, or statements that disparage the products,
											services, business, or reputation of Film.io or its
											affiliates.
										</li>
										<li className={styles.level2}>
											The Participant acknowledges that any violation of this
											Non-Defamation Clause may result in immediate
											disqualification from the Collaboration Contest and
											forfeiture of any prizes or benefits associated with the
											Contest.
										</li>
										<li className={styles.level2}>
											The Participant further agrees that, in the event of a
											breach or threatened breach of this Non-Defamation Clause,
											Film.io shall be entitled to seek injunctive relief to
											prevent such breach or threatened breach, in addition to
											any other remedies available at law or in equity.
										</li>
										<li className={styles.level2}>
											This Non-Defamation Clause shall survive the termination or expiration of the Collaboration Contest and shall continue in full force and effect for a period of [insert duration, e.g., three (3) years following the conclusion of the Contest.
										</li>
										<li className={styles.level2}>
											The Participant acknowledges that this Non-Defamation
											Clause is a material term of the Collaboration Contest,
											and that Film.io would not permit the Participant&apos;s
											entry into the Contest in the absence of the
											Participant&apos;s agreement to this clause.
										</li>
									</ul>
								</li>
								<li className={styles.level1}>
									Prize Payout Schedule
									<ul>
										<li className={styles.level2}>
											The grand prize of $25,000 will be paid out to the winner
											in twelve (12) equal monthly installments of $2,083.33,
											subject to the terms set forth in Section 5.
										</li>
										<li className={styles.level2}>
											The first creator installment will be paid within thirty
											(30) days of the winner being announced and executing the
											Release and Affidavit of Eligibility.
										</li>
										<li className={styles.level2}>
											Subsequent installments will be paid on a monthly basis,
											subject to the following conditions:
											<p>
												a. The winner must demonstrate reasonable progress
												towards completing the milestones outlined in the
												&#34;Winner Obligations&#34; section of the Official
												Rules. These milestones include:
												<p style={{ marginLeft: '4px' }}>
													i. Providing executive producer credits to Filmio in
													the project.{' '}
												</p>
												<p style={{ marginLeft: '4px' }}>
													ii. Granting Filmio publicity rights related to the
													project and the Contest.{' '}
												</p>
												<p style={{ marginLeft: '4px' }}>
													iii. Including the Filmio logo in the project&apos;s
													promotional materials and/or the beginning or ending
													sequence of the project.
												</p>
												<p style={{ marginLeft: '4px' }}>
													iv. Participating in reasonable publicity and
													marketing activities related to the Contest and the
													Filmio platform.{' '}
												</p>
											</p>
											<p>
												b. The winner&apos;s project must maintain a minimum
												score of 85% of the project&apos;s winning v2 Go Score
												throughout the 12-month payout period. The Go Score will
												be evaluated at the end of each month to determine
												eligibility for that month&apos;s installment.
											</p>
										</li>
										<li className={styles.level2}>
											If the winner fails to meet the conditions outlined in
											Section 3 for any given month, the installment for that
											month may be withheld until the conditions are met. If the
											conditions remain unmet for two consecutive months, Filmio
											reserves the right to terminate the prize payout and
											forfeit any remaining installments.
										</li>
										<li className={styles.level2}>
											In the event that the winner is unable to complete the
											project or fulfill the obligations outlined in the “Winner
											Obligations” section due to unforeseen circumstances,
											Filmio reserves the right to modify or terminate the prize
											payout at its sole discretion.{' '}
										</li>

										<li className={styles.level2}>
											Any changes to the prize payout schedule or conditions
											must be agreed upon in writing by both the winner and
											Filmio.
										</li>
									</ul>
								</li>
								<li className={styles.level1}>
									List of Winners
									<ul>
										<li className={styles.level2}>
											The name of the Winners will be periodically posted online at https://awards.film.io after the winners have been verified.  To obtain a winners list and/or a copy of these Official Rules by mail, Participants who wish to receive a copy must email: awards@filmio.com or to social@film.io.
										</li>
									</ul>
								</li>
								<li className={styles.level1}>
									Privacy
									<ul>
										<li className={styles.level2}>
											The information provided will be used in accordance with the Organizer’s Privacy Policy (link privacy policy here). Should there be any conflict between Organizer’s Privacy Policy and these Official Rules, these Official Rules shall govern. Should there be any conflict between Organizer’s Terms and Conditions (link terms and conditions here) and these Official Rules, these Official Rules shall govern only as it relates to the Contest.  We will not disclose your personal information to a third party, unless you have consented to such disclosure or where we are required to do so by law. Should you breach these Official Rules, or if we are under a duty to disclose or share your personal data in order to comply with any legal obligation, we may disclose your information to a relevant authority. This may include exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction. Any disclosure of personal information will be strictly controlled and made fully in accordance with U.S. laws.
											<p>
												By participating in the Collaboration Contest, I acknowledge that I have read, understood, and agree to be bound by these Official Rules.
											</p>
										</li>
									</ul>
								</li>
							</ul>
							<Box className={styles.flex_box}>
								<Button className={styles.down} onClick={handleScrollDown}>
									<img src={ArrowDown} alt="down" />
								</Button>
							</Box>
						</Grid>
					</Item>
				</Box>
				{!readOnly && (
					<Box className={styles.bottom}>
						{isConsentGiven ? (
							<Box className={styles.agree_section}>
								<CustomCheckbox
									className={styles.check_active}
									checked={checked}
									onChange={event => {
										setChecked(event.target.checked)
									}}
								/>

								<Typography component={'h2'} className={styles.active_head}>
									I have read and agree Film.io Terms of Service.
								</Typography>
							</Box>
						) : (
							<Box className={styles.agree_section}>
								<Typography component={'h2'} className={styles.disabled_header}>
									I have read and agree Film.io Terms of Service.
								</Typography>
							</Box>
						)}
						<Box className={styles.action_btns}>
							<Button
								onClick={handleEnrollClick}
								disabled={(!isConsentGiven || isEnrolling) || !checked}
								className={styles.agree}
							>
								I Agree
							</Button>
							<Button
								onClick={handleClose}
								className={styles.cancel}
								disabled={isEnrolling}
							>
								Cancel
							</Button>
						</Box>
					</Box>
				)}
			</DialogContent>
		</DialogWithbackground>
	)
}

JoinContestDialog.propTypes = {
	projectId: PropTypes.string,
	contestId: PropTypes.string,
	open: PropTypes.bool,
	handleClose: PropTypes.func,
	readOnly: PropTypes.bool
}
