import { useDispatch, useSelector } from 'react-redux'
import useAxiosFetch from 'hooks/useAxiosFetch'
import { getActiveContests } from 'services/contest.service'
import { setActiveContests } from 'component/header/signInSlice'
import { Box, Button, Stack, Typography } from '@mui/material'
import { ActiveContestBadge } from './badge/ActiveContestBadge'
import { updateFilters } from '../dashboardSlice'

export const ActiveContestBanner = () => {
	const dispatch = useDispatch()
	const { activeContests } = useSelector(state => state.signIn)

	useAxiosFetch(getActiveContests(), (data, error) => {
		if (!error) {
			dispatch(setActiveContests(data))
		}
	})

	const handleViewAllContestProjectsClick = () => {
		dispatch(updateFilters({ types: ['contest'] }))
	}

	if (activeContests.length === 0) {
		return null
	}

	return (
		<Box
			height={{ xs: 'auto', sm: '46px' }}
			py={{ xs: 1, sm: 0 }}
			width={1}
			top="66px"
			position="fixed"
			zIndex={101}
			sx={{
				background: `linear-gradient(90deg, #F768CA 0%, #4E4BDE 50%, #01B6DF 100%)`
			}}
		>
			<Stack
				direction={{ xs: 'column', sm: 'row' }}
				justifyContent="center"
				alignItems="center"
				height={1}
				gap="1px"
			>
				<Typography fontWeight={600} fontSize={16}>
					{activeContests[0].name} is Live!
				</Typography>
				<Button
					onClick={handleViewAllContestProjectsClick}
					variant="text"
					sx={{
						m: 0,
						p: '2px 8px',
						fontWeight: 600,
						fontSize: 16,
						textDecoration: 'underline',
						':hover': {
							textDecoration: 'underline'
						}
					}}
					endIcon={<ActiveContestBadge width={26} height={26} src={activeContests[0].badge} />}
				>
					View Projects
				</Button>
			</Stack>
		</Box>
	)
}
