import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Typography } from '@mui/material'
import { NumericFormat } from 'react-number-format'
import { setDialogOpen } from './vestingSlice'
import { VestingDialog } from './vestingDialog/VestingDialog'
import useVestingOverview from './useVestingOverview'
import styles from './VestingOverview.module.scss'

const VestingOverview = () => {
	const dispatch = useDispatch()
	const { vestingStats, user } = useVestingOverview()
	const isVester = user?.isVesting === 1
	const isHodl = user?.isHodl

	const handleOpenDialog = useCallback(() => {
		dispatch(setDialogOpen(true))
	})

	const totalTokens = vestingStats?.total ?? 0

	return (
		<>
			{isVester && (
				<div className={isHodl? styles.overview_Hodl: styles.overview } onClick={handleOpenDialog}>
					<Typography className={styles.title}>
						<span className={styles.header}>Vesting Tokens </span>
						<NumericFormat
							className={styles.value}
							thousandSeparator=","
							displayType="text"
							value={vestingStats?.vestingTokens ?? 0}
							decimalScale={2}
						/>
					</Typography>
					<Typography className={styles.title}>
						<span className={styles.header}>Released Tokens</span>
						<NumericFormat
							className={styles.value}
							thousandSeparator=","
							displayType="text"
							value={vestingStats?.releasedTokens ?? 0}
							decimalScale={2}
						/>
					</Typography>
					<Typography className={styles.title}>
						<span className={styles.header}>Total Tokens</span>
						<NumericFormat
							className={styles.value}
							thousandSeparator=","
							displayType="text"
							value={totalTokens ?? 0}
							decimalScale={2}
						/>
					</Typography>
					<Typography className={styles.title}>
						<span className={styles.header}>Vesting Schedule</span>
						<span className={styles.value}>
							{isHodl?(<u>More info</u>):(<u>Click here for more info</u>)}
						</span>
					</Typography>
				</div>
			)}
			<VestingDialog />
		</>
	)
}

export default VestingOverview
