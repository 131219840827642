import badgeLockedGrey from './badge-locked-grey.png'
import badgeUnlocked from './badge-unlocked.png'
import vaultlockBlank from './vaultlock-blank.png'
import lockedPhase from './locked-phase.png'
import development from './development.png'
import developmentUnlocked from './development-unlocked.png'
import greenlight from './greenlight.png'
import funding from './funding.png'
import token from './token.png'
import posterNotAvailable from './poster-not-available.png'
import defaultProjectCover from './default-project-cover.png'
import qrLogo from './qr_combo.jpeg'

const IMAGES = {
	badgeLockedGrey,
	badgeUnlocked,
	vaultlockBlank,
	lockedPhase,
	development,
	developmentUnlocked,
	greenlight,
	funding,
	token,
	posterNotAvailable,
	defaultProjectCover,
	qrLogo
}

export default IMAGES
