import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, Typography } from '@mui/material'
import { CustomButton } from 'component/common/customButton'
import { setLoginDialogOpen } from 'component/header/signInSlice'
import { Carousel } from './carousel'
import styles from './ExploreSection.module.scss'

const ExploreSection = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { isAuthenticated } = useSelector(state => state.signIn)

	const handleGoToExplore = () => {
		if (!isAuthenticated) dispatch(setLoginDialogOpen(true))
		else {
			navigate('/explore')
		}
	}

	return (
		<Box
			className={`${styles.explore} ${
				isAuthenticated ? styles.is_authenticated : ''
			}`}
		>
			<Box className={styles.background}></Box>
			<Grid container spacing={4} className={styles.columns_container}>
				<Grid item md={5} className={styles.left_column}>
					<Typography component={'h2'}>
						Explore, influence and greenlight the future of film and TV
					</Typography>
					<Typography component={'p'}>
						Film.io is the world’s most powerful decentralized filmmaking
						ecosystem. Powered by the Filmio DAO, we are placing Hollywood
						decision-making onto the blockchain and into the hands of creators
						and fans.
					</Typography>
					<Box className={styles.actions}>
						<CustomButton customStyle={'blue'} onClick={handleGoToExplore}>
							Explore All Projects
						</CustomButton>
					</Box>
				</Grid>
				<Grid item md={7} className={styles.carrousel_container}>
					<Carousel />
				</Grid>
			</Grid>
		</Box>
	)
}

export default ExploreSection
