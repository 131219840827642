import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import useAxios from 'hooks/useAxios'
import {
	getVestingShedule
} from 'services/vesting.service'
import useLoadingMask from 'hooks/useLoadingMask'


const useWalletTab = () => {
    // tabs :
    // 1: walletOverView
    // 2: fgr
    // 3: HODL
    const { axiosService } = useAxios()
    const { showMask, hideMask } = useLoadingMask()
    const [tabSelectedIndex, seTtabSelectedIndex] = useState(1)
    const [schedules, setSchedules] = useState([])
    const [openVestingScheduleDialog, setOpenVestingScheduleDialog] = useState(false)
    const { user: signInUser } = useSelector(
        state => state.signIn
    )
    const isHodl = signInUser?.isHodl
    const isFraud = Number(signInUser.isFraud) === 1
    const isVesting = Number(signInUser.isVesting) === 1

    function changeTab(value) {
        seTtabSelectedIndex(value)
    }

    const handleOpenVestingScheduleDialog = () => {
        setOpenVestingScheduleDialog(true)
    }

    const handleCloseVestingScheduleDialog = () => {
        setOpenVestingScheduleDialog(false)
    }

    const getSchedules = () => {
        showMask()
        axiosService(
            getVestingShedule(),
            (data, error) => {
                if (!error) {
                    setSchedules(data)
                }
                hideMask()
            }

        )
    }


    useEffect(() => {
        getSchedules()
    }, [])

    return {
        tabSelectedIndex,
        schedules,
        openVestingScheduleDialog,
        setOpenVestingScheduleDialog,
        signInUser,
        isHodl,
        isFraud,
        isVesting,
        changeTab,
        handleOpenVestingScheduleDialog,
        handleCloseVestingScheduleDialog,
    }
}

export default useWalletTab

