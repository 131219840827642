import _ from 'lodash'
import { useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { Icon, IconButton, InputAdornment } from '@mui/material'
import { TextFieldControl } from '../textFieldControl'
import styles from './Search.module.scss'

const Search = ({ setSearch, search, placeholder = 'Search', enableAutoFocus=true, ...props }) => {
	const inputRef = useRef(null)

	const handleChangeDebounced = useMemo(() => {
		return _.debounce(e => {
			setSearch(e.target.value)
		}, 500)
	}, [])

	const handleKeypress = (e, v) => {
		if (e.charCode === 13) {
			setSearch(e.target.value)
		}
	}

	const clearField = () => {
		inputRef.current.value = ''
		setSearch('')
	}

	useEffect(() => {
		if (search === '') inputRef.current.value = ''
	}, [search])

	return (
		<div>
			<TextFieldControl
				autoFocus= {enableAutoFocus}
				variant="blue_search"
				defaultValue={search}
				placeholder={placeholder}
				onChange={handleChangeDebounced}
				onKeyPress={handleKeypress}
				InputProps={{
					classes: { root: styles.search_input },
					disableUnderline: true,
					inputRef,
					startAdornment: (
						<InputAdornment position="start">
							<IconButton>
								<Icon baseClassName="fas" className="fa-search" />
							</IconButton>
						</InputAdornment>
					),
					endAdornment: search !== '' && (
						<InputAdornment position="end">
							<IconButton onClick={clearField} className={styles.close}>
								<Icon baseClassName="fas" className="fa-xmark" />
							</IconButton>
						</InputAdornment>
					)
				}}
				{...props}
			/>
		</div>
	)
}

Search.propTypes = {
	search: PropTypes.string,
	placeholder: PropTypes.string,
	setSearch: PropTypes.func,
	props: PropTypes.object,
	enableAutoFocus: PropTypes.bool
}

export default Search
