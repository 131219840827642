import _ from 'lodash'

export const getProjectById = ({
	id,
	isUpdate = false,
	draft = false,
	checkViews = false
}) => ({
	method: 'get',
	url: `/project/${id}`,
	params: {
		isUpdate,
		draft,
		checkViews
	}
})

export const getProjectBySlugService = slug => ({
	method: 'get',
	url: `/project/slug/${slug}`
})

export const getProjectByUserId = userId => ({
	method: 'post',
	url: '/dashboard',
	params: { userId }
})

export const createProject = data => ({
	method: 'post',
	url: '/project',
	data
})

export const addProjectService = data => {
	const formData = new FormData()
	const projectFields = [
		'typeId',
		'statusId',
		'stageId',
		'genres',
		'title',
		'logline',
		'story',
		'trailer',
		'poster',
		'script',
		'lookbook',
		'treatment',
		'coverImg'
	]

	const files = ['poster', 'lookbook', 'coverImg', 'treatment', 'script']

	projectFields.forEach(key => {
		if (!_.isNil(data[key])) {
			if (key === 'genres') {
				formData.append(key, JSON.stringify(data[key]))
			} else if (data[key] instanceof File) {
				formData.append(key, data[key], data[key].name)
			} else if (!files.includes(key)) {
				formData.append(key, data[key])
			}
		}
	})

	return {
		method: 'post',
		url: '/project',
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}
}

export const editProjectService = data => {
	const formData = new FormData()
	const projectFields = [
		'id',
		'typeId',
		'studioId',
		'statusId',
		'stageId',
		'creator',
		'genres',
		'title',
		'logline',
		'story',
		'trailer',
		'poster',
		'lookbook',
		'thumbnail',
		'treatment',
		'projectHash',
		'script',
		'coverImg'
	]

	const files = [
		'poster',
		'coverImg',
		'lookbook',
		'thumbnail',
		'script',
		'treatment'
	]

	projectFields.forEach(key => {
		if (!_.isNil(data[key])) {
			if (key === 'genres') {
				formData.append(key, JSON.stringify(data[key]))
			} else if (data[key] instanceof File) {
				formData.append(key, data[key], data[key].name)
			} else if (!files.includes(key)) {
				formData.append(key, data[key])
			}
		}
		if (
			['poster', 'coverImg', 'script', 'treatment', 'lookbook'].includes(key) &&
			data[key] === null
		) {
			formData.append(key, data[key])
		}
	})

	return {
		method: 'put',
		url: '/project',
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		autoexec: false
	}
}

export const getProjectListService = (autoexec = true) => ({
	method: 'get',
	url: '/project/list',
	autoexec
})

export const addProjectUserTeam = data => ({
	method: 'post',
	url: '/project/userTeam',
	data
})

export const getProjectUserTeams = projectId => ({
	method: 'get',
	url: `/project/userTeam/${projectId}`
})

export const getProjectActivities = (projectId, autoexec = true) => ({
	method: 'get',
	url: `/project/activities/${projectId}`,
	autoexec
})

export const getProjectFGRUnclaimed = projectId => ({
	method: 'get',
	url: `/project/fgr/${projectId}`
})

export const claimFGRService = (projectId, autoexec = false) => ({
	method: 'post',
	url: '/project/fgr/claim',
	data: { projectId },
	autoexec
})

export const claimFgrBlockchainService = transactionId => ({
	method: 'post',
	url: '/project/fgr/claim/blockchain',
	data: { transactionId }
})

export const deleteProjectUseTeam = params => ({
	method: 'delete',
	url: `/project/userTeam`,
	params
})
