import { useRef } from 'react'
import PropTypes from 'prop-types'
import { Box, Icon, IconButton } from '@mui/material'
import {
	SnapList,
	SnapItem,
	useScroll,
	useDragToScroll,
	useVisibleElements
} from 'react-snaplist-carousel'
import styles from './FeaturedProjects.module.scss'

export const FeaturedProjects = ({ children }) => {
	const snapList = useRef(null)

	const visible = useVisibleElements(
		{ debounce: 2, ref: snapList },
		([element]) => element
	)
	const goToSnapItem = useScroll({ ref: snapList })
	useDragToScroll({ ref: snapList, disabled: false })

	const handleNextClick = () => {
		goToSnapItem(visible + 2)
	}

	const handlePrevClick = () => {
		goToSnapItem(visible > 2 ? visible - 2 : 0)
	}

	const renderItems = () => {
		return children.map(projectLink => {
			return (
				<SnapItem key={projectLink.key} className={styles.snap_item}>
					{projectLink}
				</SnapItem>
			)
		})
	}

	return (
		<Box className={styles.projects}>
			{visible > 0 && (
				<IconButton
					size="small"
					onClick={handlePrevClick}
					className={styles.prev}
				>
					<Icon baseClassName="fas" className="fa-chevron-left" />
				</IconButton>
			)}

			<SnapList ref={snapList} tabIndex={0}>
				{renderItems()}
			</SnapList>
			{visible < children?.length - 2 && (
				<IconButton
					size="small"
					onClick={handleNextClick}
					className={styles.next}
				>
					<Icon baseClassName="fas" className="fa-chevron-right" />
				</IconButton>
			)}
		</Box>
	)
}

FeaturedProjects.propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
}
