import { Box } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { ErrorBoundary } from 'react-error-boundary'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import Header from './component/header/Header'
import { theme } from './theme/Theme'
import AppRoutes from './routes/AppRoutes'
import useQuery from './hooks/useQuery'
import { ErrorFallback, ErrorHandler } from 'component/common/errorFallback'
import { Notification } from 'component/common'
import { initMixpanel } from 'services/mixpanel.service'
import { initSentry } from 'services/sentry.service'
import { ConfirmProvider } from 'material-ui-confirm'
import { wagmiConfig } from 'utils/blockchain/config/constants'
import { ModalNotificationExtended } from 'component/common/notificationExtendedModal'
import userflow from 'userflow.js'
import { WagmiConfig } from 'wagmi'
import 'utils/axios.util'
import './App.scss'

initSentry()
initMixpanel()
userflow.init('ct_3bcsf7yxwferlkqvmc2n6roc64')

function App() {
	const { user, isAuthenticated } = useSelector(state => state.signIn)
	const isHodl = user?.isHodl
	const query = useQuery()
	const isProfileTab4 = query.get('tab') === '4'

	useEffect(() => {
		const isTokenSaleWithoutAccess =
			user?.isTokenSale === 1 && user?.appAccess === 0
		if (isAuthenticated && !isTokenSaleWithoutAccess) {
			userflow.identify(user.id, {
				name: user?.displayName,
				username: user?.username,
				email: user?.email,
				createdAt: user?.createdAt
			})
		} else {
			userflow.reset()
		}
	}, [isAuthenticated])

	return (
		<WagmiConfig config={wagmiConfig}>
			<ThemeProvider theme={theme}>
				<ConfirmProvider
					defaultOptions={{
						dialogProps: { classes: { root: 'modal_dark' } }
					}}
				>
					<ErrorBoundary
						FallbackComponent={ErrorFallback}
						onError={ErrorHandler}
					>
						{
							isHodl && isProfileTab4 ? (
								<Box className="diamond_bg"></Box>
							) : (
								<>
									<Box className="blue_light"></Box>
									<Box className="red_light"></Box>
								</>
							)
						}
						<Box className='App'>
							<Notification />
							<ModalNotificationExtended />
							<Header />
							<AppRoutes />
						</Box>
					</ErrorBoundary>
				</ConfirmProvider>
			</ThemeProvider>
		</WagmiConfig>
	)
}

export default App
