import { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import Cropper from 'react-easy-crop'
import { useDropzone } from 'react-dropzone'
import { Box, FormHelperText, Slider, Typography } from '@mui/material'
import getCroppedImg from './getCropImage'
import { DropZone } from '../dropZone'
import { getOriginalName } from '../dropZone/DropZone'
import projectImages from 'assets/images/product-detail'
import styles from './CropImage.scss'

const CropImage = ({
	setCroppedImage,
	defaultImage,
	error,
	helperTextLeft,
	helperTextRight
}) => {
	const [crop, setCrop] = useState({ x: 0, y: 0 })
	const [zoom, setZoom] = useState(1)
	const [file, setFile] = useState(null)
	const [isNewImage, setIsNewImage] = useState(false)
	const [image, setImage] = useState(null)
	const [mediaSize, setMediaSize] = useState({ width: 0, height: 0 })
	const [moveX, setMoveX] = useState(false)
	const [defaultFile, setDefaultFile] = useState(defaultImage)
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
	const cropSize = { width: 1080, height: 495 }

	const previewWidth = window.screen.width - 60
	const previewHeight = (previewWidth * 495) / 1080

	const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels)
	}, [])

	useEffect(() => {
		const updateCroopedImage = async () => {
			if (image && (isNewImage || zoom !== 1)) {
				const scaledImgSize = {
					width: mediaSize?.width * zoom,
					height: mediaSize?.height * zoom,
					y: crop.y,
					x: crop.x
				}
				const croppedImage = await getCroppedImg(
					image,
					{
						...croppedAreaPixels,
						...scaledImgSize
					},
					cropSize
				)
				const originalFileName =
					file?.name || defaultImage?.url.split('/').pop()
				const fileName = isNewImage
					? originalFileName
					: getOriginalName(originalFileName)
				const fileCrooped = new File([croppedImage], fileName)
				setCroppedImage(fileCrooped)
				const allowMoveX = mediaSize?.width * zoom > cropSize.width
				setMoveX(allowMoveX)
			}
		}
		updateCroopedImage()
	}, [zoom, croppedAreaPixels, image])

	useEffect(() => {
		if (defaultImage) {
			const { url, ...defaultFile } = defaultImage
			setImage(url)
			setDefaultFile(defaultFile)
		}

		if (defaultImage === null) {
			setImage(null)
			setDefaultFile(null)
		}
	}, [defaultImage])

	useEffect(() => {
		// force zoom for portrait images error
		setZoom(1.1)
		setTimeout(() => {
			setZoom(1)
		})
	}, [])

	const clearImage = () => {
		setCroppedImage(null)
		setDefaultFile(null)
		setImage(null)
	}

	const valueLabelFormat = value => `${Math.round(value * 100)}%`

	const setBlob = image => {
		setIsNewImage(true)
		setImage(image)
	}

	const onDrop = useCallback(acceptedFiles => {
		acceptedFiles.map(file => {
			setFile(file)
			setDefaultFile({
				path: `${file.lastModified}__${file.name}`,
				size: file.size
			})
			const reader = new FileReader()
			reader.onload = function (e) {
				setBlob(e.target.result)
			}
			reader.readAsDataURL(file)
			return file
		})
	}, [])

	const accept = { 'image/*': ['.jpeg', '.png', '.jpg'] }

	const { getRootProps, getInputProps } = useDropzone({
		accept,
		onDrop,
		noClick: true
	})

	return (
		<Box className="my_crop" {...getRootProps()}>
			<input {...getInputProps()} />
			<Typography component={'h2'} className={error ? 'error' : ''}>
				Header Image*
			</Typography>
			<Typography component={'small'} className={styles.helperTextLeft}>
				{error || helperTextLeft}
			</Typography>
			{helperTextRight && (
				<FormHelperText className={styles.helperTextRight}>
					{helperTextRight}
				</FormHelperText>
			)}
			<Box className="controls">
				<Box className="zoom-range">
					<Slider
						size="small"
						value={zoom}
						aria-label="Small"
						valueLabelDisplay="auto"
						min={1}
						max={4}
						step={0.01}
						getAriaValueText={valueLabelFormat}
						valueLabelFormat={valueLabelFormat}
						onChange={e => {
							setZoom(e.target.value)
						}}
					/>
				</Box>
				<DropZone
					{...{
						accept: { 'image/*': ['.jpeg', '.png', '.jpg'] },
						clearFile: clearImage,
						setFile,
						setBlob,
						defaultFile
					}}
				/>
			</Box>
			<Box
				className="mobile_preview"
				style={{
					width: previewWidth,
					height: previewHeight
				}}
			>
				<img src={image} />
			</Box>
			<Box className="crop-container">
				<Cropper
					image={image || projectImages.defaultProjectCover}
					crop={crop}
					zoom={zoom}
					maxZoom={4}
					minZoom={1}
					onCropChange={setCrop}
					onCropComplete={onCropComplete}
					onZoomChange={setZoom}
					objectFit="vertical-cover"
					showGrid={false}
					zoomWithScroll={false}
					transform={`translate(${moveX ? crop.x : 0}px, ${
						crop.y
					}px) scale(${zoom})`}
					cropSize={cropSize}
					onMediaLoaded={size => {
						setMediaSize(size)
					}}
				/>
			</Box>
			{error && <FormHelperText className="error">{error}</FormHelperText>}
		</Box>
	)
}

CropImage.propTypes = {
	error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	setCroppedImage: PropTypes.func,
	defaultImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	helperTextLeft: PropTypes.string,
	helperTextRight: PropTypes.string
}

export default CropImage
