import { Box, Button, Dialog, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { updateSignInUser } from '../signInSlice'
import styles from './FeatureModalDialog.module.scss'
import useAxios from 'hooks/useAxios'
import { updateUserFields } from 'services/profile.service'
import rewardSafegaurds from 'assets/images/rewards-safegaurds.jpg'

export const FeatureModalDialog = () => {
	const { user } = useSelector(state => state.signIn)
	const open = user?.showFeatureModal === 1
	const dispatch = useDispatch()
	const { axiosService } = useAxios()

	const updateUserShowFeatureModal = () => {
		try {
			axiosService(updateUserFields({ showFeatureModal: 0, id: user?.id }))
		} catch (error) {
			console.log(error)
		}
	}

	const handleClose = () => {
		dispatch(updateSignInUser({ showFeatureModal: 0 }))
		updateUserShowFeatureModal()
	}

	return (
		<Dialog
			open={open}
			disableScrollLock={true}
			className={styles.fgr_dialog}
			modal="true"
			classes={{
				paper: styles.dialog_paper,
				container: styles.dialog_container
			}}
			onClose={handleClose}
		>
			<Box className={styles.content}>
				<Typography component={'h1'}>Rewards & Safeguards</Typography>
				<img className={styles.headerImg} src={rewardSafegaurds} />
				<Typography>
					Today, we are rolling out gasless platform wallets for everyone, making it easier than ever to support your favorite creators and earn more rewards in the process! 
				</Typography>
				<Typography>
					Plus, we will soon be releasing a gamified Bonus Token Release Schedule that focuses on rewarding our active community members while guarding against bots and bad actors.
				</Typography>
				<Typography>
				<a
						href={'https://film.io/2024/04/new-film-io-platform-safety-measures-and-updates/'}
						target="_blank"
						rel="noreferrer">
						Click Here
					</a> to learn more about the gamified Bonus Token Release Schedule (coming very soon)!
				</Typography>
				<Typography>
					<i>** Bonus $FAN governance token withdrawal is temporarily paused. Purchased tokens will always remain transferrable and can be used for staking and earning rewards.</i>
				</Typography>
				<Box className={styles.controls}>
					<Button onClick={handleClose}>Got It!</Button>
				</Box>
			</Box>
		</Dialog>
	)
}
