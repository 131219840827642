import { abbreviateNumber } from 'js-abbreviation-number'
import _ from 'lodash'

const blockExploreBaseUrl = process.env.REACT_APP_BLOCK_EXPLORE_BASE_URL
const ethExploreBaseUrl = process.env.REACT_APP_ETH_EXPLORE_BASE_URL

export const createSelectOptions = arr =>
	arr.map((el, idx) => {
		return { name: el, id: el.toLowerCase().replaceAll(' ', '_') }
	}, [])

export const formatAddress = hash =>
	_.isNil(hash) ? '' : `${hash.slice(0, 6)}...${hash.slice(-4)}`

export const formatShortAddress = hash =>
	_.isNil(hash) ? '' : `${hash.slice(0, 4)}...${hash.slice(-3)}`

export const getTransactionLink = blockchainHash => {
	if (_.isNil(blockchainHash))
		return (
			<a target="_blank" rel="noreferrer">
				Processing...
			</a>
		)
	const blockchainHashText = formatAddress(blockchainHash)
	const blockchainTransactionUrl = `${blockExploreBaseUrl}${blockchainHash}`
	return (
		<a href={blockchainTransactionUrl} target="_blank" rel="noreferrer">
			{blockchainHashText}
		</a>
	)
}

export const getEthTransactionLink = blockchainHash => {
	if (_.isNil(blockchainHash))
		return (
			<a target="_blank" rel="noreferrer">
				Processing...
			</a>
		)
	const blockchainHashText = `${blockchainHash.slice(
		0,
		4
	)}...${blockchainHash.slice(-4)}`
	const blockchainTransactionUrl = `${ethExploreBaseUrl}${blockchainHash}`
	return (
		<a href={blockchainTransactionUrl} target="_blank" rel="noreferrer">
			{blockchainHashText}
		</a>
	)
}

const addMetaTag = (name, content) => {
	const tag = document.querySelector(`meta[name="${name}"]`)
	if (_.isNil(tag)) {
		const tagDesc = document.querySelector('meta[name="description"]')
		const meta = document.createElement('meta')
		meta.setAttribute('name', name)
		document.getElementsByTagName('head')[0].insertBefore(meta, tagDesc)
	}
	document
		.querySelector(`meta[name="${name}"]`)
		.setAttribute('content', content)
}

export const updateMetaTags = ({
	title = 'Filmio',
	description = `Film.io is the world's most powerful decentralized filmmaking ecosystem, placing Hollywood decision-making into the hands of creators and fans.`,
	url = null,
	image = null,
	author = null
}) => {
	addMetaTag('twitter:card', 'summary')
	addMetaTag('og:type', 'article')

	if (!_.isNil(description)) {
		const desc = description.replace('<p>', '').replace('</p>', '')
		addMetaTag('description', desc)
		addMetaTag('twitter:description', desc)
		addMetaTag('og:description', desc)
	}
	if (document.title !== title && !_.isNil(title)) {
		document.title = title
		addMetaTag('twitter:title', title)
		addMetaTag('og:title', title)
	}
	if (!_.isNil(url)) {
		addMetaTag('twitter:url', url)
		addMetaTag('og:url', url)
	}
	if (!_.isNil(image)) {
		addMetaTag('twitter:image', image)
		addMetaTag('og:image', image)
	}
	if (!_.isNil(author)) {
		addMetaTag('og:author', author)
	}
}

export const addObjectToLocalStorage = data => {
	Object.keys(data).forEach(key => {
		let value = _.get(data, [key], '')
		if (typeof value === 'object') value = JSON.stringify(value)
		localStorage.setItem(key, value)
	})
}

function isObjectString(str) {
	try {
		JSON.parse(str)
	} catch (e) {
		return false
	}
	return true
}

export const getObjectFromLocalStorage = keys => {
	return keys.reduce((acc, key) => {
		let value = localStorage.getItem(key)
		if (isObjectString(value)) value = JSON.parse(value)
		acc[key] = isNaN(key) ? value : Number(value)
		return acc
	}, {})
}

export const isMobile = () => {
	const isBrowser = typeof window !== 'undefined'
	const userAgent = isBrowser ? _.get(window, 'navigator.userAgent') : ''
	const isAndroid = /(Android)/i.test(userAgent)
	const isIphone = /(iPhone|iPod)/i.test(userAgent)
	const isIpad = /(iPad)/i.test(userAgent)
	return isIphone || isAndroid || isIpad
}

export const isMetamask = () => {
	const isBrowser = typeof window !== 'undefined'
	const hasEthereum = isBrowser && _.has(window, 'ethereum')
	return isMobile() && hasEthereum
}

export const isSafariMacOs = () => {
	const userAgent = navigator.userAgent.toLowerCase()
	return /safari/.test(userAgent) && !/chrome/.test(userAgent) && /macintosh/.test(userAgent)
}

const wait = ms =>
	new Promise(resolve => {
		setTimeout(() => resolve(), ms)
	})

export const retryWithDelay = async (
	fn,
	failResult = null,
	retries = 240,
	interval = 500
) => {
	try {
		const result = await fn()
		return result
	} catch (err) {
		if (retries <= 0) {
			return Promise.resolve(failResult)
		}
		await wait(interval)
		return retryWithDelay(fn, failResult, retries - 1, interval)
	}
}

export const abbreviateNumberFixed = (value, digit, decimals = 2) => {
	const valueFixed = Number(value).toFixed(decimals)
	return abbreviateNumber(valueFixed, digit)
}

export const NumberWithDecimals = (value, decimals = 4) => {
	return hasDecimalPart(value) ? parseFloat(Number(value).toFixed(decimals)) : value
}

export const hasDecimalPart = (number) => {
	return number % 1 > 0
}

export const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

export const removeDuplicates = (arr) => arr.filter(
	(v, i, a) => a.findIndex(v2 => v2.id === v.id) === i
)
