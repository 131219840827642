import styles from './DaoTiers.module.scss'
import { Box, Icon, Typography } from '@mui/material'
import { TierItem } from './tierItem'
import useDaoTiers from './useDaoTiers'

const DaoTiers = () => {
	const { tiers, userMetrics, globalProgress, showDAO } = useDaoTiers()

	const renderTiers = () => {
		return tiers.map((tier, i) => (
			<TierItem
				key={`tier-${i}`}
				{...{
					...tier,
					userMetrics
				}}
			/>
		))
	}

	return (
		<Box className={styles.dao_tiers}>
			<Box className={styles.left}>
				<Typography>Your DAO</Typography>
				<Typography>Membership</Typography>
				<Typography>
					Journey
					<span onClick={showDAO}>
						<Icon baseClassName="fas" className="fa-brain-circuit" />
					</span>
				</Typography>
			</Box>
			<Box className={styles.right}>
				<Box className={styles.progress_container}>
					<Box className={styles.progress_base}>
						<Box
							className={styles.progress}
							style={{ width: `${globalProgress}%` }}
						>
							<Box className={styles.point}></Box>
						</Box>
					</Box>
				</Box>
				<Box className={styles.tiers_container}>{renderTiers()}</Box>
			</Box>
		</Box>
	)
}

export default DaoTiers
