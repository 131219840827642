import _ from 'lodash'
import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { StakesTable } from './stakesTable'
import { AddStakeForm } from './addStakeForm'
import styles from './StakesTab.module.scss'
import { RecentActivitySection } from '../dashboardTab/recentActivity/RecentActivity'
import { getUserStakeTotalService } from 'services/stake.service'
import useLoadingMask from 'hooks/useLoadingMask'
import { useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios'
import { abbreviateNumberFixed } from 'utils/utils'

const StakesTab = () => {
	const [total, setTotal] = useState(0)
	const { project, projectId, updateStakes } = useSelector(
		state => state.projectDetail
	)
	const { showMask, hideMask } = useLoadingMask()
	const { axiosService } = useAxios()

	const getStakesTotal = () => {
		showMask()
		axiosService(getUserStakeTotalService(projectId), (data, error) => {
			if (!error) {
				setTotal(data?.total + data?.failedStakesTotal)
				hideMask()
			}
		})
	}

	useEffect(() => {
		if (!_.isNil(projectId)) getStakesTotal()
	}, [updateStakes, projectId])

	return (
		<Grid container spacing={2} className={styles.stakes_section}>
			<Grid item md={7}>
				<div className={styles.projectinfo}>
					<AddStakeForm />
					<StakesTable />
				</div>
			</Grid>
			<Grid item md={5}>
				<Grid item md={12} className={styles.stats}>
					<h1>Current Staking Totals for {project.title}</h1>
					<div className={styles.fan_staked}>
						<div className={styles.your_stake}>
							<p className={styles.stat}>{abbreviateNumberFixed(total, 2)}</p>
							<span>Your Staked FAN</span>
						</div>
						<div className={styles.total_staked}>
							<p className={styles.stat}>
								{abbreviateNumberFixed(project.stakesTotal, 2)}
							</p>
							<span>FAN Staked</span>
						</div>
						<div className={styles.total_stakers}>
							<p className={styles.stat}>
								{abbreviateNumberFixed(project.stakersCount, 2)}
							</p>
							<span>Unique Stakers</span>
						</div>
					</div>
					<div className={styles.explanations}>
						<p>
							<small>
								FAN Tokens can be staked to projects and rewards can be earned
								in the form of FGR (Fan Governance Rewards). Staking to a
								project helps creators unlock functionality and shows your
								support for their projects. FAN Tokens are not a financial
								instrument and have no monetary value.
							</small>
						</p>
					</div>
				</Grid>

				<RecentActivitySection />
			</Grid>
		</Grid>
	)
}

export default StakesTab
