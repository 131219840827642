import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCreatePlatformWalletDialogOpen } from '../signInSlice'
import PropTypes from 'prop-types'
import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material'
import styles from './createPlatformDialog.module.scss'


export const CreatePlatformWalletDialog = ({
    open,
    username
}) => {
    const headingText = "Create a Platform Wallet"
    const msg = "To participate and receive Fan Reward Tokens, please create a Platform Wallet on the wallet page."
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleSetupWallet = () => {
        dispatch(setCreatePlatformWalletDialogOpen(false))
        navigate(`/profile/${username}?tab=4`)
    }

    const handleClose = () => {
        dispatch(setCreatePlatformWalletDialogOpen(false))
    }

    return (
        <Dialog
            open={open}
            disableScrollLock={true}
            className={`${styles.dialog} warning`}
            modal="true"
            classes={{
                paper: styles.dialog_paper,
                container: styles.dialog_container
            }}
            onClose={handleClose}
        >

            <DialogContent className={styles.dialog_content}>
                <Typography component={'h2'}>{headingText}</Typography>
                <Typography component={'p'}>{msg}</Typography>
                <Box className={styles.actions}>
                    <Button
                        size="small"
                        onClick={handleSetupWallet}
                        className={styles.approve_btn}
                    >
                        Setup Wallet
                    </Button>
                    <Button
                        size="small"
                        onClick={handleClose}
                        className={styles.close_btn}
                    >
                        Cancel
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

CreatePlatformWalletDialog.propTypes = {
    open: PropTypes.bool,
    username: PropTypes.string
}
