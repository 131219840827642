export const dashboardItemTypes = [
	{
		name: 'Film.io Contest Projects',
		id: 'contest',
		tooltip: 'Projects that are part of a Film.io contest'
	},
	{
		name: 'DAO Creativity Proposals',
		id: 'project',
		icon: 'film',
		tooltip:
			'DAO Proposals that repesent a movie or tv project from a creator that fans help greenlight by voting with their $FAN Tokens'
	},
	{
		name: 'DAO Governance Proposals',
		id: 'dao',
		icon: 'check-to-slot',
		tooltip:
			'DAO Proposals that help chart the direction and development of the platform, determined through voting with $FAN Tokens'
	},
	{
		name: 'Favorite Projects',
		id: 'favorites_projects',
		icon: 'heart',
		tooltip: false
	},
	{
		name: 'Film.io Updates',
		id: 'tile',
		icon: 'newspaper',
		tooltip: false
	},
	{
		name: 'Staff Picks',
		id: 'staff_picks',
		tooltip: false
	}
]

export const newsreelFilters = [
	{
		name: 'Projects',
		id: 'project',
		icon: 'heart',
		iconBase: 'fas'
	},
	{
		name: 'People',
		id: 'people',
		icon: 'user-bounty-hunter'
	},
	{
		name: 'Stakes & Rewards',
		id: 'stakes_rewards',
		icon: 'fan-stake',
		iconBase: 'fa-kit'
	},
	{
		name: 'Reviews',
		id: 'reviews',
		icon: 'star-half-stroke',
		iconBase: 'fas'
	},
	{
		name: 'Comments & Reactions',
		id: 'comments_reactions',
		icon: 'comment'
	}
]
