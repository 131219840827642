import { useEffect, useMemo, useState } from 'react'
import useAxiosFetch from 'hooks/useAxiosFetch'
import { useSelector, useDispatch } from 'react-redux'
import {
	resetReviews,
	setIsReviewed,
	setReviews,
	setShowReviewsForm
} from '../projectDetailSlice'
import { getReviewService, isReviewedService } from 'services/review.service'
import useLoadingMask from 'hooks/useLoadingMask'

const useReviewsTab = () => {
	const dispatch = useDispatch()
	const [loadMore, setLoadMore] = useState(true)
	const [page, setPage] = useState(0)
	const { showMask, hideMask } = useLoadingMask()

	const {
		projectId,
		isAddReviewDisabled,
		isOwnerSignedIn,
		isReviewed,
		reviews,
		project
	} = useSelector(state => state.projectDetail)

	const [{ loading }, fetchReviews] = useAxiosFetch(
		getReviewService(projectId, page),
		afterFetch
	)

	function afterFetch(data, error) {
		if (!error) {
			setLoadMore(data?.length > 0)
			dispatch(setReviews(data))
		}
	}

	const [{ loading: loadingIsReviewed }, getIsReviewed] = useAxiosFetch(
		isReviewedService(projectId),
		(data, error) => {
			if (!error) {
				dispatch(setIsReviewed(data))
			}
		}
	)

	const onScroll = ev => {
		if (
			window.innerHeight + window.scrollY >= document.body.offsetHeight - 10 &&
			loadMore &&
			!loading
		) {
			setPage(p => p + 1)
		}
	}

	useEffect(() => {
		fetchReviews()
	}, [page, projectId])

	useEffect(() => {
		getIsReviewed()
		dispatch(setShowReviewsForm(true))
		return () => {
			dispatch(resetReviews())
		}
	}, [projectId])

	useEffect(() => {
		loading || loadingIsReviewed ? showMask() : hideMask()
	}, [loading, loadingIsReviewed])

	const showWriteReview = useMemo(
		() => !isReviewed && !isAddReviewDisabled && !isOwnerSignedIn && project?.stageId !== 5
		, [isAddReviewDisabled, isOwnerSignedIn, isReviewed, project])

	return {
		showWriteReview,
		reviews,
		onScroll
	}
}

export default useReviewsTab
