import { Box, Button, Container, Grid, Typography } from '@mui/material'
import useLoginDialog from 'component/header/loginDialog/useLoginDialog'
import useTokenSaleJoin from './useTokenSaleJoin'
import topImg from 'assets/images/token-sale-top.svg'
import { isMetamask, isMobile } from 'utils/utils'
import styles from './TokenSaleJoin.module.scss'
import { CaptchaCheck } from 'component/common/captchaCheck'
import _ from 'lodash'

const TokenSaleJoin = () => {
	const { metaMaskButtonUrl, isLogin, captchaToken } = useTokenSaleJoin()
	const { handleNcLogin, handleMMLogin } = useLoginDialog()

	return (
		<Container disableGutters maxWidth="sm" className={styles.onboard_join}>
			<Grid container spacing={2}>
				<Grid item md={12} className={styles.right}>
					<Box className={styles.hero_container}>
						<img src={topImg} />
					</Box>
					<Box className={styles.intro}>
						{isLogin ? (
							<Typography className={styles.subtext}>
								Welcome back to the Film.io Private Sale!
							</Typography>
						) : (
							<Typography className={styles.subtext}>
								We’re excited to have you here, BCW Fam!
							</Typography>
						)}
						{isLogin ? (
							<Typography className={styles.subtitle}>
								Please connect the wallet you previously signed up with!
							</Typography>
						) : (
							<Typography className={styles.subtitle}>
								Connect your wallet to join us in democratizing the
								entertainment industry!
							</Typography>
						)}
					</Box>
					<Box className={styles.recaptcha}>
						<CaptchaCheck />
					</Box>
					<Box className={styles.agree}>
						<p>
							By signing up, you agree to the{' '}
							<a
								href="https://film.io/constitution"
								target="_blank"
								rel="noreferrer"
							>
								DAO Constitution
							</a>
							{', '}
							<a
								href="https://film.io/token-terms-conditions/"
								target="_blank"
								rel="noreferrer"
							>
								Token Terms & Conditions
							</a>
							{' and '}
							<a
								href="https://film.io/terms-of-use"
								target="_blank"
								rel="noreferrer"
							>
								Terms of Use
							</a>
						</p>
					</Box>
					<a
						disabled={_.isNil(captchaToken)}
						className={styles.metamaskbutton}
						onClick={handleMMLogin}
						href={(isMetamask() || !isMobile()) ? undefined : `https://metamask.app.link/dapp/${metaMaskButtonUrl}`}>
						{'Connect with MetaMask'}
					</a>
					<Button
						onClick={handleNcLogin}
						className={styles.back}
						disabled={_.isNil(captchaToken)}
					>
						Connect Wallet
					</Button>
				</Grid>
			</Grid>
		</Container>
	)
}

export default TokenSaleJoin
