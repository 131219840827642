import { Box, Icon, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import reactions from 'assets/images/reactions'
import styles from './ActivityItem.module.scss'
import { ReviewRating } from 'component/projectDetail/reviewsTab/reviewRating'
import { formatDate } from 'utils/date.utils'
import { NumericFormat } from 'react-number-format'

const ActivityItem = ({
	type,
	date,
	rating,
	description,
	displayName,
	userId,
	projectTitle,
	reactionIcon,
	amount,
}) => {

	const { user: signInUser } = useSelector(state => state.signIn)
	const isUserReview = signInUser.id === userId && type === "review"

	const infoMap = {
		review: {
			title: isUserReview ? 'Review from you': 'Review from ',
			description,
			icon: 'star-half-stroke',
			iconVariant: 'fas'
		},
		stake: {
			title: 'Stake from ',
			description: (
				<>
					{displayName} staked{' '}
						<NumericFormat
							value={amount}
							displayType="text"
							thousandSeparator=","
						/>{' '}
					 FAN Tokens to{' '}
					<span>{projectTitle}</span>.
				</>
			),
			icon: 'fan-stake',
			iconVariant: 'fa-kit'
		},
		share: {
			title: 'Shared by ',
			description: (
				<>
					{displayName} shared <span>{projectTitle}</span> to his Facebook page.
				</>
			),
			icon: 'share',
			iconVariant: 'fas'
		},
		comment: {
			title: 'Comment by ',
			description,
			icon: 'comment',
			iconVariant: 'fas'
		},
		reaction: {
			title: `Reaction added by `,
			description: (
				<>
					{displayName} added a <img src={reactions[reactionIcon]} /> to your
					update.
				</>
			),
			icon: 'face-smile-hearts',
			iconVariant: 'fas'
		}
	}

	const icon = infoMap[type]?.icon
	const iconVariant = infoMap[type]?.iconVariant
	// const iconVariant = icon === 'star-half-stroke' ? 'fas' : 'fal'

	return (
		<Box className={isUserReview ? `${styles.activity_item} ${styles.user_review}`:styles.activity_item}>
			<Box className={styles.header}>
				<Icon baseClassName={iconVariant} className={`fa-${icon}`} />
					<Typography>
						{infoMap[type]?.title}
						{!isUserReview && <span>{displayName}</span>}
					</Typography>
				<Typography className={styles.date}>
					{formatDate(date, 'med')}
				</Typography>
			</Box>
			{rating && (
				<Box className={styles.rating}>
					<ReviewRating readOnly {...{ rating }} />
				</Box>
			)}
			<Typography className={styles.description}>
				{infoMap[type]?.description}
			</Typography>
		</Box>
	)
}

ActivityItem.propTypes = {
	type: PropTypes.string,
	date: PropTypes.string,
	rating: PropTypes.number,
	description: PropTypes.string,
	id: PropTypes.string,
	displayName: PropTypes.string,
	userId: PropTypes.string,
	projectTitle: PropTypes.string,
	reactionIcon: PropTypes.string,
	amount: PropTypes.number
}

export default ActivityItem
