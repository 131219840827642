import { useState } from 'react'
import PropTypes from 'prop-types'
import { CustomDialog } from 'component/common'
import { Box, IconButton } from '@mui/material'
import playButton from 'assets/images/play_button.svg'
import styles from './TrailerDialog.module.scss'

export const TrailerDialog = ({ trailerSrc, thumbnail }) => {
	const [open, setOpen] = useState(false)

	const handleClose = () => {
		setOpen(false)
	}

	const openDialog = () => {
		setOpen(true)
	}

	return (
		<Box className={styles.trailer_dialog}>
			<IconButton onClick={openDialog}>
				<img src={playButton} />
			</IconButton>
			<img src={thumbnail} onClick={openDialog} className={styles.thumbnail} />
			<CustomDialog {...{ open, handleClose, variant: 'trailer' }}>
				<iframe width="95%" height="90%" src={trailerSrc}></iframe>
			</CustomDialog>
		</Box>
	)
}

TrailerDialog.propTypes = {
	trailerSrc: PropTypes.string,
	thumbnail: PropTypes.string
}
