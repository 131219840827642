import { Box, Grid, Typography } from '@mui/material'
import pressImages from 'assets/images/press'
import styles from './PressSection.module.scss'

const imagesMap = [
	{
		image: 'filmDaily',
		url: 'https://filmdaily.co/indie-film/orlando-pedregosa'
	},
	{
		image: 'entr',
		url: 'https://www.entrepreneur.com/money-finance/bitcoin-caught-your-attention-now-blockchain-is-about-to/364349'
	},
	{
		image: 'ibt',
		url: 'https://www.ibtimes.com/why-film-tv-industry-needs-reboot-2911456'
	},
	{
		image: 'forbes',
		url: 'https://www.forbes.com/sites/adriennejordan/2020/04/25/how-long-it-takes-to-go-from-script-to-screen-in-hollywood/?sh=4f02fb0d2fbe'
	},
	{
		image: 'investing',
		url: 'https://www.investing.com/news/cryptocurrency-news/filmio-uses-blockchain-tech-to-democratize-the-entertainment-industry-2552014'
	}
]

const PressSection = () => {
	const renderBrands = () =>
		imagesMap.map(({ image, url }) => {
			return (
				<Grid item md={4} xs={12} key={`brand-${image}`}>
					<a
						className={styles.brand}
						href={url}
						target="_blank"
						rel="noreferrer"
					>
						<img src={pressImages[image]} />
					</a>
				</Grid>
			)
		})
	return (
		<Box className={styles.press}>
			<Box className={styles.content}>
				<Typography component={'h6'} className={styles.header}>
					In the Press
				</Typography>
				<Grid container spacing={2}>
					{renderBrands()}
				</Grid>
			</Box>
		</Box>
	)
}

export default PressSection
