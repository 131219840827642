import tokenIcon from './token.png'
import followerlarge from './followerlarge.png'
import investorlarge from './investorlarge.png'
import defaultProfileHeader from './default-profile-header.jpeg'
import wallet from './wallet.icon.png'
import communityVideoPoster from './community.image.png'
import empowerFans from './empower.fans.png'
import liberateCreators from './liberate.creators.png'
import twitter from './twitter.png'
import telegram from './telegram.png'
import discord from './discord.png'
import metamaskColor from './metamask-color.png'
import newToken from './new_token.svg'
import newTokenBlack from './new_token_black.svg'
import badgeLocked from './badge-locked.png'
import lock from './lock.svg'
import heart from './heart.svg'
import heartFill from './heart-fill.svg'
import help from './help.svg'
import lockIcon from './lockIcon.svg'
import airdrop2023 from './airdrop_2023.svg'
import newsreelEmpty from './newsreel_empty.svg'
import blankHeart from './superfan/blankHeart.svg'
import arrowGradient from './arrowGradient.svg'
import explainerSlideOne from './homeslides/IMG_1306.jpg'
import explainerSlideTwo from './homeslides/IMG_1309.jpg'
import explainerSlideThree from './homeslides/IMG_1310.jpg'
import explainerSlideFour from './homeslides/IMG_1312.jpg'
import explainerSlideFive from './homeslides/IMG_1315.jpg'
import staffPickLarge from './sss-large.svg'
import placeHolder from './placeholder.svg'
import twitterPreview from './twitter-preview.jpg'
import contestComplete from './contest-complete.png'
import fgrBack from './fgr-back.png'
import unclaimedBack from './unclaimed-back.png'
import ethLogo from './ethereum.svg'
import warning from './warning.png'
import rewardToken from './rewards-token.png'
import transferIllustration from './transfer-illustration.png'
import rewardSafegauard from './rewards-safegaurds.jpg'

const images = {
	wallet,
	warning,
	tokenIcon,
	empowerFans,
	followerlarge,
	liberateCreators,
	communityVideoPoster,
	defaultProfileHeader,
	newTokenBlack,
	investorlarge,
	metamaskColor,
	badgeLocked,
	heartFill,
	lockIcon,
	telegram,
	newToken,
	discord,
	twitter,
	heart,
	lock,
	help,
	airdrop2023,
	newsreelEmpty,
	blankHeart,
	arrowGradient,
	explainerSlideOne,
	explainerSlideTwo,
	explainerSlideThree,
	explainerSlideFour,
	explainerSlideFive,
	staffPickLarge,
	placeHolder,
	twitterPreview,
	contestComplete,
	fgrBack,
	unclaimedBack,
	ethLogo,
	rewardToken,
	transferIllustration,
	rewardSafegauard
}

export default images
