export const getDashboardService = (data, autoexec = false) => ({
	method: 'post',
	url: '/dashboard',
	data,
	autoexec
})

export const getGlobalSearchService = data => ({
	method: 'post',
	url: '/dashboard/search',
	data,
	autoexec: false
})

export const getSharerNameService = data => ({
	method: 'post',
	url: '/dashboard/sharerName',
	data,
	autoexec: false
})

export const getExploreInfoService = params => ({
	method: 'get',
	url: '/dashboard/explore',
	autoexec: true,
	params
})

export const getDashboardTilesService = () => ({
	method: 'get',
	url: '/dashboard/tiles',
	autoexec: true
})
