import heartEye from './heart-eye.png'
import heart from './heart.png'
import lol from './lol.png'
import thinking from './thinking.png'
import wow from './wow.png'

const REACTIONS = {
	heartEye,
	heart,
	lol,
	thinking,
	wow
}

export default REACTIONS
