import { setJoinDialogOpen } from 'component/header/signInSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getMaticBalance } from 'utils/blockchain/config/utils'
import useNotification from './useNotification'
import { sendEmailCodeService } from 'services/profile.service'
import useAxios from './useAxios'
import useLoadingMask from './useLoadingMask'

const useCommon = () => {
	const { isAuthenticated, user } = useSelector(state => state.signIn)
	const { showNotification } = useNotification()
	const { axiosService } = useAxios()
	const { showMask, hideMask } = useLoadingMask()
	const dispatch = useDispatch()

	const checkNoAuthToShowJoin = () => {
		if (!isAuthenticated) {
			dispatch(setJoinDialogOpen(true))
		}
		return isAuthenticated
	}

	const checkNcNoMagic = () => {
		return user.isNcWithoutMagic
	}

	const nonCustodialWithoutMatic = async () => {
		if (user?.isNonCustodial === 0 || user?.isNcWithoutMagic === false) return false
		const maticBalance = await getMaticBalance(user?.walletAddress)
		if (maticBalance === 0) {
			showNotification({
				message: 'You do not have enough MATIC in your account to pay for transaction fees on this network. Deposit MATIC from another account.'
			})
			return true
		}
		return false
	}

	const resendEmailConfirmation = (email) => {
		sendEmailConfirmation(email, () => {
			showNotification({ message: 'Email sent successfully' })
		})
	}

	const sendEmailConfirmation = (email, cb) => {
		showMask()
		axiosService(sendEmailCodeService({ email }), (_data, error) => {
			if (!error) {
				if (cb && typeof cb === 'function') cb()
			}
			hideMask()
		})
	}

	const isAuthenticatedReload = () => {
		const isAuthenticated = localStorage.getItem('isAuthenticated')
		if (isAuthenticated === 'true') {
			showNotification({
				message: 'You are already authenticated'
			})
			setTimeout(() => {
				window.location.reload()
			}, 3000)
			return true
		}
		return false
	}

	return {
		checkNoAuthToShowJoin,
		checkNcNoMagic,
		nonCustodialWithoutMatic,
		resendEmailConfirmation,
		sendEmailConfirmation,
		isAuthenticatedReload
	}
}

export default useCommon
