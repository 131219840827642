import { useCallback, useState } from 'react'
import ImageViewer from 'react-simple-image-viewer'
import PropTypes from 'prop-types'
import styles from './ImageWithViewer.scss'

export const ImageWithViewer = ({ src, alt }) => {
	const [isViewerOpen, setIsViewerOpen] = useState(false)
	const images = [src]
	const openImageViewer = useCallback(() => {
		setIsViewerOpen(true)
	}, [])

	const closeImageViewer = () => {
		setIsViewerOpen(false)
	}
	return (
		<>
			<img
				onClick={() => openImageViewer()}
				id="main_image"
				alt={alt || ''}
				src={images[0]}
			/>
			{isViewerOpen && (
				<ImageViewer
					src={images}
					currentIndex={0}
					onClose={closeImageViewer}
					disableScroll={true}
					leftArrowComponent={<></>}
					rightArrowComponent={<></>}
					backgroundStyle={{
						backgroundColor: 'rgba(0,0,0,0.5)',
						zIndex: 1000
					}}
					closeOnClickOutside={true}
					className={styles.image_viewer}
				/>
			)}
		</>
	)
}

ImageWithViewer.propTypes = {
	src: PropTypes.string,
	alt: PropTypes.string
}
