import { useEffect, useState } from 'react'
import { Popper, Fade, Box, Typography, Icon } from '@mui/material'
import { Search } from 'component/common'
import styles from './GlobalSearch.module.scss'
import useAxiosFetch from 'hooks/useAxiosFetch'
import { getGlobalSearchService } from 'services/dashboard.service'
import { Link } from 'react-router-dom'
import placeHolder from 'assets/images/placeholder.svg'

export default function GlobalSearch() {
	const [anchorEl, setAnchorEl] = useState(null)
	const [search, setSearch] = useState('')
	const [items, setItems] = useState([])

	const [{ loading }, fetchData] = useAxiosFetch(
		getGlobalSearchService({ search }),
		afterFetchProject
	)

	function afterFetchProject(data, error) {
		if (!error) {
			setItems(data)
		}
	}

	const handleKeyup = event => {
		setAnchorEl(event.currentTarget)
	}

	const handleLinkClick = () => {
		setSearch('')
	}

	const renderItems = () =>
		items.map(i => {
			const defaultAvatar = placeHolder
			const to =
				i.type === 'user' ? `/profile/${i.username}` : `/project/${i.slug}`

			return (
				<Link
					className={styles.list_item}
					key={i.id}
					to={to}
					onClick={handleLinkClick}
				>
					{i.type === 'user' ? (
						<span className={styles.avatarContainer}>
							<img src={i?.image ?? defaultAvatar} />
						</span>
					) : (
						<span className={styles.posterContainer}>
							<img src={i?.image ?? defaultAvatar} />
						</span>
					)}
					<Box className={styles.text}>
						<Typography component={'h2'}>{i.name}</Typography>
						{i.author && (
							<Typography>
								{i.author}{' '}
								{i.isVerified === 1 && (
									<Icon baseClassName="fas" className="fa-badge-check" />
								)}
							</Typography>
						)}
						<Typography>{i.description}</Typography>
					</Box>
				</Link>
			)
		})

	useEffect(() => {
		if (search !== '') fetchData()
	}, [search])

	return (
		<>
			<Search {...{ setSearch, search, onKeyUp: handleKeyup }} />
			<Popper
				open={search !== ''}
				anchorEl={anchorEl}
				transition
				sx={{ zIndex: 999 }}
			>
				{({ TransitionProps }) => (
					<Fade {...TransitionProps}>
						<Box className={styles.list}>
							{loading ? (
								<Typography component="h1">Searching...</Typography>
							) : items.length > 0 ? (
								renderItems()
							) : (
								<Typography component="h2">
									No results match your search
								</Typography>
							)}
						</Box>
					</Fade>
				)}
			</Popper>
		</>
	)
}
