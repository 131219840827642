import { Link } from 'react-router-dom'
import { Grid, Container, Button, Box } from '@mui/material'
import { TileTable } from './tileTable'
import useTileManagement from './useTileManagement'
import { LoadMask } from 'component/common'
import './TileManagement.scss'

const TileManagement = () => {
	const { tiles, loading } = useTileManagement()

	return (
		<>
			{loading ? (
				<LoadMask />
			) : (
				<Box className="tile-management-section">
					<Container disableGutters maxWidth="md">
						<Grid container>
							<Grid item xs={12}>
								<Box className="tile-heading">
									<h1>Tile Admin</h1>
									<Link to="/newTile">
										<Button variant="contained" className="yellow-bg-button">
											New Tile
										</Button>
									</Link>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<h4>Scheduled</h4>
								{tiles?.scheduled.length === 0 ? (
									<Grid item xs={12} className="currently-bg">
										<p>There are currently no scheduled tiles.</p>
									</Grid>
								) : (
									<Grid item xs={12}>
										<TileTable
											{...{ tiles: tiles?.scheduled, type: 'scheduled' }}
										/>
									</Grid>
								)}
							</Grid>
							<Grid item xs={12}>
								<h4>Active</h4>
								{tiles?.active.length === 0 ? (
									<Grid item xs={12} className="currently-bg">
										<p>There are currently no active tiles.</p>
									</Grid>
								) : (
									<Grid item xs={12}>
										<TileTable {...{ tiles: tiles?.active, type: 'active' }} />
									</Grid>
								)}
							</Grid>
							<Grid item xs={12}>
								<h4 className="expired">
									Expired <i className="fa-light fa-ghost"></i>
								</h4>
								{tiles?.expired.length === 0 ? (
									<Grid item xs={12} className="currently-bg">
										<p>There are currently no expired tiles.</p>
									</Grid>
								) : (
									<Grid item xs={12}>
										<TileTable {...{ tiles: tiles?.expired }} />
									</Grid>
								)}
							</Grid>
						</Grid>
					</Container>
				</Box>
			)}
		</>
	)
}

export default TileManagement
