import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Grid, Box, Icon, IconButton, Button } from '@mui/material'
import { useAccount, useSwitchNetwork } from 'wagmi'
import { useWindowSize } from 'react-use'
import ReactConfetti from 'react-confetti'
import { ViewProfile } from './viewProfile'
import { CustomButton, LoadMask } from 'component/common'
import {
	setCreatePlatformWalletDialogOpen,
	setLoginDialogOpen,
	setShowSearchMobile,
	setOnRampDialogOpen
} from './signInSlice'
import { CreatePlatformWalletDialog } from './createPlatformWalletDialog'
import { LoginDialog } from './loginDialog'
import { JoinDialog } from './joinDialog'
import { AddProjectDialog } from './addProjectDialog'
import { ConnectWallet } from './connectWallet'
import { GlobalSearch } from './globalSearch'
import { DaoTierLevelingDialog } from './daoTierLevelingDialog'
import { DaoTierLevelChangeDialog } from './daoTierLevelChangeDialog'
import { StartDialog } from './startDialog'
import { AirdropCheckDialog } from './airdropCheckDialog'
import { AirdropSignUpDialog } from './airdropSignUpDialog'
import { ClaimFgrDialog } from './claimFgrDialog'
import { SaleMagicDialog } from './saleMagicDialog'
import { Notifications } from './notifications'
import logo from 'assets/images/beta-logo.png'
import { getReactions } from 'services/reaction.service'
import { populateReactions } from 'component/common/reactions/reactionSlice'
import styles from './Header.module.scss'
import { signVerificationMessage } from 'utils/blockchain/config/utils'
import { chains } from 'utils/blockchain/config/constants'
import useLoginDialog from './loginDialog/useLoginDialog'
import useWeb3 from 'hooks/useWeb3'
import useHeader from './useHeader'
import { ImportFanTokenDialog } from './importFanTokenDialog'
import { BanDialog } from './banDialog'
import { ClaimButtonDialog } from './claimButtonDialog'
import { VerificationEmailDialog } from 'component/profile/dashboardTab/verificationEmailDialog/VerificationEmailDialog'
import { MagicEmailUpdateDialog } from 'component/profile/dashboardTab/magicEmailUpdateDialog/MagicEmailUpdateDialog'
import FraudWarningDialog from './fraudWarningDialog/FraudWarningDialog'
import useCommon from 'hooks/useCommon'
import { CreateMagicWalletDialog } from './createMagicWalletDialog'
import { NcWihoutMagicWarning } from './ncWihoutMagicWarning'
import { PlatformWalletCreatedDialog } from '../header/platformWalletCreatedDialog'
import { FeatureModalDialog } from './featureModalDialog'
import { CongratsDialog } from './congratsDialog'
import { ContestJoinedDialog } from '../projectDetail/contestJoinedDialog/contestJoinedDialog'
import { OnRampDialog } from './onRampDialog'

const Header = () => {
	const {
		showConfetti,
		showSearchMobile,
		frontTransactionsInProgress,
		socialJoinParams,
		isAuthenticated,
		joinDialogOpen,
		createPlatformWalletDialogOpen,
		showLoading,
		user
	} = useSelector(state => state.signIn)
	const { checkNcNoMagic } = useCommon()
	const { reactions } = useSelector(state => state.reactions)
	const { captchaToken } = useSelector(state => state.captchaCheck)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const { relaodUserInfo } = useHeader()
	const { address, isConnected } = useAccount()
	const { signer, isConnectedWrongNetwork } = useWeb3()
	const { switchNetwork } = useSwitchNetwork()
	const { authNcWallet } = useLoginDialog()
	const { handleLogout } = useHeader()
	const isExplorePage = pathname === '/explore'
	const isNewsreelPage = pathname === '/newsreel'
	const isTokenSale = pathname.includes('tokenSale') ?? false
	const isTokenSaleNoAccess = user?.isTokenSale === 1 && user?.appAccess === 0
	const isTokenSaleNoAccessClass = isTokenSaleNoAccess
		? styles.is_token_sale_no_access
		: ''
	const fanTokenImported = user?.fanTokenImported === 1

	const openLoginDialog = () => {
		dispatch(setLoginDialogOpen(true))
	}

	const handleCloseSearch = () => {
		dispatch(setShowSearchMobile(false))
	}

	const showOnRamp = () => {
		dispatch(setOnRampDialogOpen(true))
	}

	useEffect(() => {
		const fetchReactions = async () => {
			const reactions = await getReactions()
			dispatch(populateReactions(reactions.data))
		}
		if (isAuthenticated) {
			relaodUserInfo()
		}
		if (isAuthenticated && reactions.length === 0) {
			fetchReactions()
		}
	}, [isAuthenticated])

	useEffect(() => {
		const handleBeforeUnload = event => {
			event.preventDefault()
		}
		const handlePageHide = event => {
			event.stopPropagation()
		}

		if (frontTransactionsInProgress) {
			window.addEventListener('beforeunload', handleBeforeUnload)
			window.addEventListener('pagehide', handlePageHide)
		} else {
			window.removeEventListener('beforeunload', handleBeforeUnload)
			window.removeEventListener('pagehide', handlePageHide)
		}
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload)
			window.removeEventListener('pagehide', handlePageHide)
		}
	}, [frontTransactionsInProgress])

	useEffect(() => {
		const authUser = async () => {
			if (address && signer && !isAuthenticated) {
				try {
					const message =
						'Please sign this message to verify you are the owner of this wallet.'
					const { hash, signature } = await signVerificationMessage(
						signer,
						message
					)
					authNcWallet({
						captchaToken,
						hash,
						signature,
						address,
						...socialJoinParams
					})
				} catch (error) {
					await handleLogout()
					window.location.reload() // TODO find why is needed the reload and search for a better solution
				}
			}
		}
		authUser()
	}, [address, signer, isAuthenticated, isConnected])

	const checkNcUserNoMagic = () => {
		if (checkNcNoMagic()) {
			dispatch(setCreatePlatformWalletDialogOpen(true))
		} else {
			// TODO: check navigation with Link
			navigate('/newProject')
		}
	}
	const { width, height } = useWindowSize()

	return (
		<header>
			<Box className={`${styles.header_content} ${isTokenSaleNoAccessClass}`}>
				<Box className={styles.header_logo}>
					<>
						{isAuthenticated ? (
							<Link to="/explore">
								<img src={logo} alt="logo" />
							</Link>
						) : (
							<Link to="/">
								<img src={logo} alt="logo" />
							</Link>
						)}
					</>
				</Box>
				{user?.isNcWithoutMagic && isConnectedWrongNetwork && (
					<div className={styles.network}>
						You are connected to the wrong network!
						<Button
							onClick={() => switchNetwork(chains[0].id)}
							className={styles.back}
						>
							Switch to {chains[0].name}
						</Button>
					</div>
				)}
				{showLoading && <LoadMask />}

				{isAuthenticated ? (
					<>
						<Box className={styles.dashboard_header}>
							<Grid container className={styles.right_header}>
								<Grid
									item
									md={5}
									xs={12}
									className={`${styles.hide_mobile} ${styles.search_container}`}
								>
									<GlobalSearch />
								</Grid>
								<Grid item md={7} xs={12} className={styles.navigation_group}>
									<ul className={styles.header_navigation}>
										<li className={styles.hide_mobile}>
											<CustomButton
												customStyle="outlined"
												onClick={checkNcUserNoMagic}
											>
												Create
											</CustomButton>
										</li>
										<li className={styles.hide_mobile}>
											<Link
												to="/explore"
												className={
													isExplorePage ? styles.explore_active : styles.explore
												}
												title="Explore Film.io"
											>
												Explore
											</Link>
										</li>
										<li className={styles.hide_mobile}>
											<Link
												to="/newsreel"
												className={
													isNewsreelPage
														? styles.explore_active
														: styles.explore
												}
												title="Newsreels Film.io"
											>
												Newsreel
											</Link>
										</li>
										<li>
											<Notifications />
										</li>
										{user?.isOnRamperEnabled && (
											<li className={styles.buy_fan}>
												<CustomButton
													onClick={showOnRamp}
													customStyle="buy_fan"
												>
													<Icon
														baseClassName="fal"
														className="fa-cart-shopping"
													/>
												</CustomButton>
											</li>
										)}
										<ClaimButtonDialog />
										<li className={styles.user}>
											<ConnectWallet />
										</li>
										<li className={styles.view_profile}>
											<ViewProfile />
										</li>
									</ul>
								</Grid>
							</Grid>
						</Box>
						{!isTokenSaleNoAccess && (
							<>
								<AddProjectDialog />
								<DaoTierLevelingDialog />
								<DaoTierLevelChangeDialog />
								{!fanTokenImported && <ImportFanTokenDialog />}
								<ClaimFgrDialog />
								<VerificationEmailDialog />
								<CreateMagicWalletDialog />
								<MagicEmailUpdateDialog />
								<FraudWarningDialog />
								<NcWihoutMagicWarning />
								<CreatePlatformWalletDialog
									open={createPlatformWalletDialogOpen}
									username={user.username}
								/>
								<PlatformWalletCreatedDialog />
								<FeatureModalDialog />
								<CongratsDialog />
								<ContestJoinedDialog />
								<OnRampDialog />
							</>
						)}
					</>
				) : (
					<>
						{!isTokenSale && (
							<CustomButton
								customStyle={'outlined'}
								className={styles.custom_btn}
								onClick={openLoginDialog}
							>
								Login/Signup
							</CustomButton>
						)}
						<BanDialog />
						<LoginDialog />
						<SaleMagicDialog />
						{joinDialogOpen && <JoinDialog />}
						<StartDialog />
						<AirdropCheckDialog />
						<AirdropSignUpDialog />
					</>
				)}
			</Box>
			{showSearchMobile && (
				<Box className={styles.search_header}>
					<GlobalSearch />
					<IconButton
						size="small"
						onClick={handleCloseSearch}
						className={styles.close_btn}
					>
						<Icon baseClassName="fas" className="fa-xmark" />
					</IconButton>
				</Box>
			)}
			{showConfetti && <ReactConfetti width={width} height={height} />}
		</header>
	)
}
export default Header
