export const helpBaseUrl =
	process.env.REACT_APP_HELP_URL || 'https://help.film.io'
export const baseUrl =
	process.env.REACT_APP_API_BASE_URL || 'https://app.film.io'

export const config = {
	sentryDsn:
		'https://ab1503d84d8cbe3356b40eb7646e8ed7@o4506774611755008.ingest.sentry.io/4506775000121344',
		turnstileSiteKey: '0x4AAAAAAAWWOb6jyvIsLe6p'
}
