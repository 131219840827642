import {
	Grid,
	Avatar,
	Badge,
	IconButton,
	Container,
	Typography,
	Icon
} from '@mui/material'
import PropTypes from 'prop-types'
import { Link, useParams } from 'react-router-dom'
import { TabsSection } from './tabsSection'
import useProjectDetail from 'component/projectDetail/useProjectDetail'
import { VerifiedPopover } from 'component/profile/verifiedPopover'
import { LightTooltip } from 'component/common'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import styles from './ProjectDetail.module.scss'
import { VaultlockPopover } from './vaultlockPopover'
import productDetailImages from 'assets/images/product-detail'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setProjectId } from './projectDetailSlice'
import { StatusMessages } from './statusMessages'
import { NumericFormat } from 'react-number-format'
import { ActiveContestBadge } from '../dashboard/contests/badge/ActiveContestBadge'

const ProjectDetail = ({ draft }) => {
	const {
		project,
		loading,
		tabIndex,
		signInUser,
		isOwnerSignedIn,
		haveEditPermission,
		handleFollowUnfollowProjectClick,
		handleFollowUnfollowUserClick,
		handleResetProject,
		getProjectBySlug
	} = useProjectDetail(draft)

	const dispatch = useDispatch()
	const { slug, id } = useParams()
	const [anchorEl, setAnchorEl] = useState(null)

	useEffect(() => {
		if (!draft) {
			getProjectBySlug(slug)
		} else {
			dispatch(setProjectId(id))
		}
		return () => {
			dispatch(setProjectId(null))
		}
	}, [slug, id])

	useEffect(() => {
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth'
		})
		return () => {
			handleResetProject()
		}
	}, [])

	const creatorName = project?.user?.displayName
	const projectCover =
		project?.coverImg || productDetailImages.defaultProjectCover
	const isDraft = project.stageId === 1
	const { isAdmin } = signInUser

	const showMessage =
		project.stageId === 2 || project.stageId === 3 || project.stageId === 5

	const contests = project.contests ?? []

	const handlePopoverOpen = event => {
		setAnchorEl(event.currentTarget)
	}

	const handlePopoverClose = () => {
		setAnchorEl(null)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const canEditProject =
		Number(isAdmin) === 1 || isOwnerSignedIn === true || haveEditPermission()

	return (
		<Container disableGutters maxWidth="md" className={styles.project_detail}>
			<Grid container className={styles.top_detail_list}>
				{showMessage && <StatusMessages />}
				<Grid item container className={styles.title_metrics} xs={8}>
					<Grid item container xs={12}>
						<Typography component="h2">
							{project.title}
							{contests.length > 0  && (
								contests.map(contest => { 
									const src = contest.isWinner ? contest?.badgeWinner : contest?.badge
									const tootipTitle = contest?.isActive ? 
										`${project.title} is participating in the ${contest.name}` : 
										`${project.title} participated in the ${contest.name}`
									return <LightTooltip title={tootipTitle} key={contest?.id}>
										<span className={styles.contestBadge}>
											<ActiveContestBadge {...{ src, height: 30, width: 30 }} />
										</span>
									</LightTooltip>
								})
							)}
							{canEditProject && (
								<span>
									<Link to={`/project/edit/${project.id}`}>Edit</Link>
								</span>
							)}
						</Typography>
					</Grid>

					<Grid item xs={12} className={styles.social_info}>
						<ul>
							<li>
								<IconButton
									disabled={isDraft || isOwnerSignedIn}
									onClick={handleFollowUnfollowProjectClick}
								>
									{project.isFollowedByUser ? (
										<FavoriteIcon />
									) : (
										<FavoriteBorderIcon />
									)}
								</IconButton>
								<span>
									<NumericFormat
										value={project.likes}
										displayType="text"
										thousandSeparator=","
									/>
								</span>
							</li>
							<li>
								<IconButton size="small">
									<Icon baseClassName="fas" className="fa-star-half-stroke" />
								</IconButton>
								<span>{project?.reviewsAvg}</span>
							</li>
							<li>
								<IconButton size="small">
									<Icon baseClassName="fa-kit" className="fa-fan-stake" />
								</IconButton>
								<span>
									<NumericFormat
										value={project.stakesTotal}
										displayType="text"
										thousandSeparator=","
									/>
								</span>
							</li>
							<li>
								<IconButton size="small">
									<Icon baseClassName="fal" className="fa-share" />
								</IconButton>
								<span>
									<NumericFormat
										value={project?.socialMediaShares}
										displayType="text"
										thousandSeparator=","
									/>
								</span>
							</li>
							{project.vaultlockId && (
								<li>
									<VaultlockPopover
										title={project.title}
										date={project.vaultlockDate}
										vaultlockQR={project.vaultlockQR}
									/>
								</li>
							)}
						</ul>
					</Grid>
				</Grid>
				<Grid item xs={4} className={styles.avatar_container}>
					<ul>
						<li>
							<Badge
								overlap="circular"
								anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
								badgeContent={
									<LightTooltip
										title={`${project?.user?.isFollowedByUser ? 'Unfo' : 'Fo'
											}llow this creator`}
									>
										<IconButton
											color="success"
											size="small"
											disabled={isDraft || isOwnerSignedIn}
											onClick={handleFollowUnfollowUserClick}
											sx={{
												backgroundColor: '#ffffff',
												':hover': {
													backgroundColor: '#ffffff'
												}
											}}
										>
											{project?.user?.isFollowedByUser ? (
												<FavoriteIcon fontSize="inherit" />
											) : (
												<FavoriteBorderIcon fontSize="inherit" />
											)}
										</IconButton>
									</LightTooltip>
								}
							>
								<LightTooltip title={`Visit ${creatorName}'s profile`}>
									<Link to={`/profile/${project.user?.username}`}>
										<Avatar
											alt={creatorName}
											sx={{
												bgcolor: '#000000',
												border: '1px solid #ffffff'
											}}
											src={project?.user?.avatar}
										/>
									</Link>
								</LightTooltip>
							</Badge>
						</li>
						<li className={styles.owner}>
							<Typography component={'h3'}>
								{project.user?.isVerified === 1 && (
									<>
										<span onClick={handlePopoverOpen}>
											<Icon baseClassName="fas" className="fa-badge-check" />
										</span>
										<VerifiedPopover
											{...{
												anchorEl,
												handlePopoverClose,
												handleClose
											}}
										/>
									</>
								)}
								<LightTooltip title={`Visit ${creatorName}'s profile`}>
									<Link to={`/profile/${project.user?.username}`}>
										{creatorName}
										<br />
										<span>{project?.user?.profession}</span>
									</Link>
								</LightTooltip>
							</Typography>
						</li>
					</ul>
				</Grid>
			</Grid>

			<Grid
				item
				xs={12}
				className={`${styles.cover_container} ${tabIndex === 0 ? '' : styles.no_dashboard
					}`}
			>
				{!loading && <img src={`${projectCover}?tr=w-1080`} alt="cover" />}
			</Grid>

			<Grid container>
				<Grid item xs={12}>
					<TabsSection />
				</Grid>
			</Grid>
		</Container>
	)
}

ProjectDetail.propTypes = {
	draft: PropTypes.bool
}

export default ProjectDetail
