import { useEffect, useState } from 'react'
import { Box, Button, Icon, IconButton, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { closeWhitelistWalletDialog } from 'component/profile/profileSlice'

import { isMetamask, isMobile } from 'utils/utils'
import { setImportFanTokenDialogOpen, updateSignInUser } from 'component/header/signInSlice'
import { CustomDialog } from 'component/common'

import useAxios from 'hooks/useAxios'
import useWeb3 from 'hooks/useWeb3'
import metamaskLogo from 'assets/images/metamask-color.png'
import styles from './WhitelistWalletDialog.module.scss'
import { addWhitelistWalletService } from 'services/wallet.service'
import { signVerificationMessage } from 'utils/blockchain/config/utils'
import { ImportFanTokenDialog } from 'component/header/importFanTokenDialog'
import _ from 'lodash'


// eslint-disable-next-line react/prop-types
const AddWhiteListPage = ({ setMmLoginPressed, setNcLoginPressed }) => {
	const dispatch = useDispatch()
	const { axiosService } = useAxios()
	const { metamaskLogin, openConnectWalletModal, signer, address } = useWeb3()
	const { user: signInUser } = useSelector(state => state.signIn)

	const handleClose = () => {
		dispatch(closeWhitelistWalletDialog())
	}

	const addWhitelistWallet = ({
		hash,
		signature,
		address: whitelistedAddress
	}) => {
		axiosService(
			addWhitelistWalletService({ hash, signature, whitelistedAddress }),
			(_data, error) => {
				if (!error) {
					dispatch(
						updateSignInUser({
							whitelistedAddress: address
						})
					)
				}
			}
		)
		if(_.isNil(signInUser?.fanTokenImported) || signInUser?.fanTokenImported === 0){
			dispatch(setImportFanTokenDialogOpen(true))	
		}
	}

	const handleMMLogin = async () => {
		if (signer) {
			const { hash, signature } = await signVerificationMessage(
				signer,
				'Please sign this message to verify you are the owner of this wallet.'
			)
			addWhitelistWallet({ hash, signature, address })
		} else {
			setMmLoginPressed(true)
			await metamaskLogin()
		}
		handleClose()
	}

	const handleNcLogin = async () => {
		if (signer) {
			const { hash, signature } = await signVerificationMessage(
				signer,
				'Please sign this message to verify you are the owner of this wallet.'
			)
			addWhitelistWallet({ hash, signature, address })
		} else {
			setNcLoginPressed(true)
			openConnectWalletModal()
		}
		handleClose()
	}

	const currentURL = window.location.href
	const metaMaskButtonUrl = currentURL
		.replace('https://', '')
		.replace('http://', '')

	return (
		<Box className={styles.nc_connect}>
			<ImportFanTokenDialog />
			<Typography component={'h1'}>Select Wallet</Typography>
			<Typography>Connect your wallet account</Typography>
			<Box className={styles.otherLogin}>
				{isMetamask() || !isMobile() ? (
					<Button className={styles.wallet_mobile_cnct} onClick={handleMMLogin}>
						<img src={metamaskLogo} alt="MetaMask" />
						MetaMask
					</Button>
				) : (
					<a href={`https://metamask.app.link/dapp/${metaMaskButtonUrl}`}>
						<Box className={styles.walletMobileCnct}>
							<IconButton size="small" className={styles.walletBtn}>
								<img src={metamaskLogo} alt="MetaMask" />
								<Typography>MetaMask</Typography>
							</IconButton>
						</Box>
					</a>
				)}
				<Button
					className={styles.wallet_cnct}
					onClick={handleNcLogin}
					startIcon={<Icon baseClassName="fas" className="fa-wallet" />}
				>
					Wallet Connect
				</Button>
			</Box>
			<Button className={styles.cancel} onClick={handleClose}>
				Cancel
			</Button>
		</Box>
	)
}

const WhitelistWalletDialog = () => {
	const dispatch = useDispatch()
	const { whitelistWalletDialogData } = useSelector(state => state.profile)
	const { user: signInUser } = useSelector(state => state.signIn)
	const { open } = whitelistWalletDialogData
	const { signer, address } = useWeb3()
	const { axiosService } = useAxios()
	const [mmLoginPressed, setMmLoginPressed] = useState(false)
	const [ncLoginPressed, setNcLoginPressed] = useState(false)

	const addWhitelistWallet = ({
		hash,
		signature,
		address: whitelistedAddress
	}) => {
		axiosService(
			addWhitelistWalletService({ hash, signature, whitelistedAddress }),
			(_data, error) => {
				if (!error) {
					dispatch(
						updateSignInUser({
							whitelistedAddress: address
						})
					)
				}
			}
		)
		if(_.isNil(signInUser?.fanTokenImported) || signInUser?.fanTokenImported === 0){
			dispatch(setImportFanTokenDialogOpen(true))	
		}
	}

	const handleClose = () => {
		dispatch(closeWhitelistWalletDialog())
	}

	useEffect(() => {
		const asyncAddWhitelistWallet = async () => {
			setMmLoginPressed(false)
			setNcLoginPressed(false)
			const { hash, signature } = await signVerificationMessage(
				signer,
				'Please sign this message to verify you are the owner of this wallet.'
			)
			addWhitelistWallet({ hash, signature, address })
		}
		if ((mmLoginPressed || ncLoginPressed) && signer) {
			asyncAddWhitelistWallet()
		}
	}, [signer, mmLoginPressed, ncLoginPressed, address])

	return (
		<CustomDialog {...{ open, handleClose, variant: 'email_verification' }}>
			<ImportFanTokenDialog />
			<AddWhiteListPage setNcLoginPressed={setNcLoginPressed} setMmLoginPressed={setMmLoginPressed} />
		</CustomDialog>
	)
}

export default WhitelistWalletDialog
