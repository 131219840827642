import { DateTime } from 'luxon'

const defaultOptions = { zone: 'utc' }

export const formatDate = (dateStr, format) => {
	if (!dateStr) return null
	const date = DateTime.fromISO(dateStr, defaultOptions)
	switch (format) {
		case 'med':
			return date.toLocaleString(DateTime.DATE_MED)
		case 'full':
			return date.toLocaleString(DateTime.DATE_FULL)
		case 'inputDate':
			return date.toFormat('yyyy-MM-dd')
		default:
			return date.toLocaleString(DateTime.DATE_SHORT)
	}
}

export const formatDateToRelative = date => {
	return DateTime.fromISO(date, defaultOptions).toRelative()
}

export const getDateNow = (format = 'yyyy-MM-dd') => {
	return DateTime.utc().toISO({ format })
}

export const getNowDateDiff = compareDate => {
	const endDate = DateTime.fromISO(compareDate, { zone: 'utc' })
	const dateDiff = DateTime.now({ zone: 'utc' }).diff(endDate, ['seconds'])
	return dateDiff
}

export const getDateDiff = (startDate, endDate) => {
	const start = DateTime.fromISO(startDate, defaultOptions)
	const end = DateTime.fromISO(endDate, defaultOptions)
	return end.diff(start, ['seconds']).seconds;
}

export const getDateNowFull = () => {
	return DateTime.now().toLocaleString(DateTime.DATE_FULL, defaultOptions)
}

export const convertToMillis = date =>
	DateTime.fromISO(date, defaultOptions).toMillis()

export const humanDateDiff = (startDate, endDate) => {
	const start = DateTime.fromISO(startDate, defaultOptions)
	const end = DateTime.fromISO(endDate, defaultOptions)
	const months = end.diff(start, ['months']).months
	const days = end.diff(start, ['days']).days
	const hours = end.diff(start, ['hours']).hours
	const unit = []
	if (hours >= 1){
		unit.push('hours')
	}else{
		unit.push('minutes')
	}
	if (days >= 1) unit.push('days')
	if (months >= 1) unit.push('months')
	return end.diff(start, unit).toHuman({ maximumFractionDigits: 0 })
}

export const customHumanDateDiff = (startDate, endDate) => {
	const start = DateTime.fromISO(startDate, defaultOptions)
	const end = DateTime.fromISO(endDate, defaultOptions)
	const seconds = end.diff(start, ['seconds']).seconds

    const days = Math.floor(seconds / ( 60 * 60 * 24));
    const hours = Math.floor((seconds % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((seconds % ( 60 * 60)) / ( 60));

	const result = [];
    if (days > 0) result.push(`${days} days`);
    if (hours > 0) result.push(`${hours} hours`);
    if (minutes > 0) result.push(`${minutes} minutes`);

    return result.join(', ') || '0 minutes';
}
