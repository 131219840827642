import { createSlice } from '@reduxjs/toolkit'
import { getObjectFromLocalStorage } from 'utils/utils'

const initialState = {
	isAuthenticated: !!localStorage.getItem('isAuthenticated'),
	jwt: localStorage.getItem('jwt'),
	user: {
		isOnRamperEnabled:false,
		...getObjectFromLocalStorage([
			'id',
			'email',
			'avatar',
			'balance',
			'username',
			'daoTierId',
			'daoTierImg',
			'nextTierId',
			'displayName',
			'daoTierName',
			'daoTierDate',
			'nextTierName',
			'nextTierImage',
			'daoTierRingImg',
			'daolevelUpImage',
			'isDaoTermsAgreed',
			'isNcWithoutMagic',
			'nextTierCompleted',
			'whitelistedAddress',
			'unlockRewardsEndDate',
			'tokenSaleConditionsAccepted',
			'projectsPublishedCount',
			'daoTierConfirmed',
			'daoTierCompleted',
			'isAirdropCreator',
			'fanTokenImported',
			'isEmailVerified',
			'isNonCustodial',
			'isRestricted',
			'isHodl',
			'walletAddress',
			'isAirdropFan',
			'projectCount',
			'isTokenSale',
			'isVerified',
			'isVesting',
			'appAccess',
			'codeDate',
			'shortUrl',
			'fgrRate',
			'isFraud',
			'isAdmin',
			'isOnRamperEnabled',
		])
	},
	ncWalletAddress: getObjectFromLocalStorage(['address', 'hash', 'signature']),
	showLoading: false,
	loadingMessage: null,
	startDialogOpen: false,
	airdropCheckDialogOpen: false,
	airdropSignUpDialogOpen: false,
	airdropJoinOpen: false,
	banDialogOpen: false,
	loginDialogOpen: false,
	onRampDialogOpen: false,
	loginDialogMessage: null,
	saleEmailDialogOpen: false,
	saleUserId: null,
	joinDialogOpen: false,
	joinDialogEmail: '',
	showTierInfoMenu: false,
	createAccountDialogOpen: false,
	createPlatformWalletDialogOpen: false,
	congratsDialogOpen: false,
	addProjectDialogOpen: false,
	daoTierLevelingDialogOpen: false,
	importFanTokenDialogOpen: false,
	daoTierLevelingChangeDialogOpen: false,
	createMagicWalletDialogOpen: false,
	platformWalletCreatedDialogOpen: false,
	showConfetti: false,
	showSearchMobile: false,
	transactionsInProgress: false,
	frontTransactionsInProgress: false,
	fraudWarningDialogOpen: false,
	menuOpen: false,
	notificationsCount: 0,
	socialJoinParams: {
		userShortUrl: null,
		projectShortUrl: null,
		network: null,
		campaignSlug: null
	},
	bronzeTierData: {
		name: '',
		metrics: [],
		benefits: [],
		userMetrics: {}
	},
	activeContests: [],
	contestJoinedDialogOpen: false,
	isIngXRestrictedInvestor: localStorage.getItem('isIngXRestrictedInvestor') === 'true',
}

export const signInSlice = createSlice({
	name: 'signIn',
	initialState,
	reducers: {
		setAuthenticated: (state, { payload }) => {
			state.isAuthenticated = true
			state.user = payload
			state.jwt = payload?.jwt
		},
		removeAuthenticated: state => {
			state.isAuthenticated = false
			state.user = null
			state.jwt = null
		},
		updateSignInUser: (state, { payload }) => {
			state.user = {
				...state.user,
				...payload
			}
		},
		setMenuOpen: (state, { payload }) => {
			state.menuOpen = payload
		},
		setLoginDialogOpen: (state, { payload }) => {
			state.loginDialogOpen = payload
		},
		setOnRampDialogOpen: (state, { payload }) => {
			state.onRampDialogOpen = payload
		},
		setBanDialogOpen: (state, { payload }) => {
			state.banDialogOpen = payload
		},
		setShowTierInfoMenu: (state, { payload }) => {
			state.showTierInfoMenu = payload
		},
		setBronzeTierData: (state, { payload }) => {
			state.bronzeTierData = payload
		},
		setLoginDialogMessage: (state, { payload }) => {
			state.loginDialogMessage = payload
		},
		setCreateAccountDialogOpen: (state, { payload }) => {
			state.createAccountDialogOpen = payload
		},
		setCreatePlatformWalletDialogOpen: (state, { payload }) => {
			state.createPlatformWalletDialogOpen = payload
		},
		setCongratsDialogOpen: (state, { payload }) => {
			state.congratsDialogOpen = payload
		},
		setJoinDialogOpen: (state, { payload }) => {
			state.joinDialogOpen = payload
		},
		setJoinDialogEmail: (state, { payload }) => {
			state.joinDialogEmail = payload
		},
		setFraudWarningDialogOpen: (state, { payload }) => {
			state.fraudWarningDialogOpen = payload
		},
		setAddProjectDialogOpen: (state, { payload }) => {
			state.addProjectDialogOpen = payload
		},
		setSocialJoinParams: (state, { payload }) => {
			state.socialJoinParams = payload
		},
		setDaoTierLevelingDialogOpen: (state, { payload }) => {
			state.daoTierLevelingDialogOpen = payload
		},
		setImportFanTokenDialogOpen: (state, { payload }) => {
			state.importFanTokenDialogOpen = payload
		},
		setDaoTierLevelingChangeDialogOpen: (state, { payload }) => {
			state.daoTierLevelingChangeDialogOpen = payload
		},
		setStartDialogOpen: (state, { payload }) => {
			state.startDialogOpen = payload
		},
		setAirdropCheckDialogOpen: (state, { payload }) => {
			state.airdropCheckDialogOpen = payload
		},
		setAirdropSignUpDialogOpen: (state, { payload }) => {
			state.airdropSignUpDialogOpen = payload
		},
		setAirdropJoinOpen: (state, { payload }) => {
			state.airdropJoinOpen = payload
		},
		setShowLoading: (state, { payload }) => {
			state.showLoading = payload
		},
		setLoadingMessage: (state, { payload }) => {
			state.loadingMessage = payload
		},
		setTransactionsInProgress: (state, { payload }) => {
			state.transactionsInProgress = payload
		},
		setFrontTransactionsInProgress: (state, { payload }) => {
			state.frontTransactionsInProgress = payload
		},
		setCreateMagicWalletDialogOpen: (state, { payload }) => {
			state.createMagicWalletDialogOpen = payload
		},
		setNotificationsCount: (state, { payload }) => {
			state.notificationsCount = payload
		},
		setShowSearchMobile: (state, { payload }) => {
			state.showSearchMobile = payload
		},
		setSaleEmailDialogOpen: (state, { payload }) => {
			state.saleEmailDialogOpen = payload
		},
		setSaleUserId: (state, { payload }) => {
			state.saleUserId = payload
		},
		setNcWalletAddress: (state, { payload }) => {
			state.ncWalletAddress = payload
		},
		setShowConfetti: (state, { payload }) => {
			state.showConfetti = payload
		},
		setPlatformWalletCreatedDialogOpen: (state, { payload }) => {
			state.platformWalletCreatedDialogOpen = payload
		},
		setActiveContests: (state, { payload }) => {
			state.activeContests = payload
		},
		setContestJoinedDialogOpen: (state, { payload }) => {
			state.contestJoinedDialogOpen = payload
		},
		setIsIngXRestrictedInvestor: (state, { payload }) => {
			state.isIngXRestrictedInvestor = payload
		}
	}
})

export const {
	setMenuOpen,
	setSaleUserId,
	setShowLoading,
	setShowConfetti,
	setAuthenticated,
	updateSignInUser,
	setBanDialogOpen,
	setBronzeTierData,
	setJoinDialogOpen,
	setLoadingMessage,
	setLoginDialogOpen,
	setOnRampDialogOpen,
	setStartDialogOpen,
	setJoinDialogEmail,
	setAirdropJoinOpen,
	setNcWalletAddress,
	removeAuthenticated,
	setSocialJoinParams,
	setShowSearchMobile,
	setShowTierInfoMenu,
	setNotificationsCount,
	setLoginDialogMessage,
	setSaleEmailDialogOpen,
	setAddProjectDialogOpen,
	setAirdropCheckDialogOpen,
	setFraudWarningDialogOpen,
	setTransactionsInProgress,
	setCreateAccountDialogOpen,
	setCreatePlatformWalletDialogOpen,
	setCongratsDialogOpen,
	setAirdropSignUpDialogOpen,
	setImportFanTokenDialogOpen,
	setDaoTierLevelingDialogOpen,
	setCreateMagicWalletDialogOpen,
	setFrontTransactionsInProgress,
	setDaoTierLevelingChangeDialogOpen,
	setPlatformWalletCreatedDialogOpen,
	setActiveContests,
	setContestJoinedDialogOpen,
	setIsIngXRestrictedInvestor
} = signInSlice.actions

export default signInSlice.reducer
