import _ from 'lodash'
import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	setCreatePlatformWalletDialogOpen,
	setDaoTierLevelingChangeDialogOpen,
	setMenuOpen,
	setShowSearchMobile,
	setShowTierInfoMenu,
	updateSignInUser
} from 'component/header/signInSlice'
import {
	calculateUserDaoTierProgress,
	upgradeUserDaoTier,
	claimDaoTierBonus
} from 'services/daoTier.service'
import { completeKycService } from 'services/profile.service'
import useAxiosFetch from 'hooks/useAxiosFetch'
import useHeader from '../../useHeader'
import useAxios from 'hooks/useAxios'
import useNotification from 'hooks/useNotification'
import { reloadRecentActivity } from 'component/profile/walletTab/walletTabSlice'
import useNotifications from '../../notifications/useNotifications'
import { reloadDaoTiers } from 'component/profile/profileSlice'
import useLoadingMask from 'hooks/useLoadingMask'
import Persona from 'persona'
import { formatDate } from 'utils/date.utils'
import { useNavigate } from 'react-router-dom'
import useCommon from 'hooks/useCommon'

const kycTemplateId = process.env.REACT_APP_KYC_TEMPLATE_ID
const kycEnv = process.env.REACT_APP_KYC_ENVIRONMENT

export default function useDefaultMenu() {
	const dispatch = useDispatch()
	const {
		user: signInUser,
		showSearchMobile,
		menuOpen,
		jwt
	} = useSelector(state => state.signIn)
	const { checkNcNoMagic } = useCommon()
	const personaKycRef = useRef(null)
	const [tierProgress, setTierProgress] = useState(null)
	const { showNotification } = useNotification()
	const { loadUserTierInfo, relaodUserInfo, showConfetti } = useHeader()
	const { axiosService } = useAxios()
	const navigate = useNavigate()
	const { getUnvisiteNotificationCount } = useNotifications()
	const { showMask: showLoading, hideMask } = useLoadingMask()

	const [, refreshProgress] = useAxiosFetch(
		calculateUserDaoTierProgress(),
		(data, error) => {
			if (!error) {
				setTierProgress(data.progress)
			}
		}
	)

	const claimTierBenefitsBlockchain = () => {
		axiosService(claimDaoTierBonus(), (_data, error) => {
			if (!error) {
				showNotification({ message: 'Tier bonus completed successfully!' })
			}
			dispatch(reloadRecentActivity())
			relaodUserInfo()
			getUnvisiteNotificationCount()
		})
	}

	const [, upgradeTier] = useAxiosFetch(
		upgradeUserDaoTier(),
		(_data, error) => {
			if (!error) {
				claimTierBenefitsBlockchain()
				loadUserTierInfo(() => {
					dispatch(setDaoTierLevelingChangeDialogOpen(true))
					showConfetti(5000)
					refreshProgress()
				})
				dispatch(reloadDaoTiers())
				relaodUserInfo()
				getUnvisiteNotificationCount()
			}
			hideMask()
		}
	)

	const handleClose = () => {
		dispatch(setMenuOpen(false))
	}

	const toggleSearchMobile = () => {
		handleClose()
		dispatch(setShowSearchMobile(!showSearchMobile))
	}

	const handleClickUpgradme = () => {

		if (checkNcNoMagic()) {
			dispatch(setCreatePlatformWalletDialogOpen(true))
		} else {
			handleClose()
			showLoading()
			upgradeTier()
		}

	}

	const handleCopyWalletAddress = () => {
		navigator.clipboard.writeText(signInUser?.walletAddress).then(() => {
			showNotification({
				message: 'Your wallet address has been copied to your clipboard.'
			})
		})
	}

	const handleKYCStart = () => {
		showKyc()
		handleClose()
	}

	const redirectToProfile = () => {
		navigate(profileHref)
	}

	const handleShowTierInfo = () => {
		dispatch(setShowTierInfoMenu(true))
	}

	useEffect(() => {
		if (!_.isNil(menuOpen)) {
			refreshProgress()
			loadUserTierInfo()
		}
	}, [menuOpen])

	const onKycComplete = ({ inquiryId }) => {
		showLoading()
		axiosService(completeKycService({ inquiryId }), (data, error) => {
			if (error) {
				showNotification({ message: 'Verification failed!' })
				hideMask()
				return
			}
			dispatch(updateSignInUser({ isVerified: data.isVerified }))
			showNotification({ message: 'Verification completed successfully!' })
			refreshProgress()
			loadUserTierInfo()
			dispatch(reloadDaoTiers())
			hideMask()
		})
	}

	const showKyc = () => {
		let personaClient = personaKycRef.current
		if (!personaClient) {
			personaClient = new Persona.Client({
				templateId: kycTemplateId,
				referenceId: signInUser.id,
				environmentId: kycEnv,
				onComplete: onKycComplete,
				fields: {
					nameFirst: signInUser?.firstName,
					nameLast: signInUser?.lastName,
					birthdate: formatDate(signInUser.birthday, 'finputDate'),
					addressCountryCode: signInUser.countryId,
					emailAddress: signInUser.email
				}
			})
			personaKycRef.current = personaClient
		}

		personaClient.open()
	}

	useEffect(() => {
		return () => {
			const personaKyc = personaKycRef.current
			if (!personaKyc) {
				return
			}
			personaKyc.destroy()
			personaKycRef.current = null
		}
	}, [])

	const profileHref = `/profile/${signInUser?.username}`

	const tierName = _.capitalize(signInUser?.daoTierName)
	const nextTierName = _.capitalize(signInUser?.nextTierName)
	const isDaoTierConfirmed = Number(signInUser?.daoTierConfirmed) === 1
	const isDaoTierCompleted = Number(signInUser?.daoTierCompleted) === 1
	const upgradeAvailable =
		(!isDaoTierConfirmed && isDaoTierCompleted) ||
		Number(signInUser?.nextTierCompleted) === 1
	const tierImage = upgradeAvailable
		? signInUser?.nextTierImage
		: signInUser?.daoTierImg

	return {
		jwt,
		tierName,
		tierImage,
		signInUser,
		profileHref,
		tierProgress,
		nextTierName,
		upgradeAvailable,
		handleCopyWalletAddress,
		handleClickUpgradme,
		handleShowTierInfo,
		toggleSearchMobile,
		redirectToProfile,
		handleKYCStart,
		handleClose
	}
}
