import { useNavigate } from 'react-router-dom'
import { Box, Grid, Typography } from '@mui/material'
import { ExplainerSlideshow } from './explainerSlideshow'
import { CustomButton } from 'component/common/customButton'
import { useDispatch, useSelector } from 'react-redux'
import { setLoginDialogOpen } from 'component/header/signInSlice'
import { Callout } from './callout'
import images from 'assets/images'
import videos from 'assets/videos'
import styles from './DaoSection.module.scss'

const DaoSection = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { isAuthenticated } = useSelector(state => state.signIn)

	const handleGoToExplore = () => {
		if (!isAuthenticated) dispatch(setLoginDialogOpen(true))
		else {
			navigate('/explore')
		}
	}
	return (
		<Box className={styles.dao_section}>
			<Box className={styles.header}>
				<Typography component={'h3'}>
					A community-driven entertainment universe
				</Typography>
				<Typography component={'p'}>
					Governed by the world&apos;s first filmmaking industry DAO, Film.io
					liberates creators and replaces centralized power with transparency
					and inclusivity by handing over the governance of Hollywood to fans.
				</Typography>
			</Box>
			<Box>
				<ExplainerSlideshow />
			</Box>
			<Box className={styles.content}>
				<video
					id="background-video"
					autoPlay
					loop
					muted
					playsInline
					poster={images.communityVideoPoster}
				>
					<source src={videos.communityVideo} type="video/mp4" />
				</video>
				<Callout
					{...{
						title: 'Onboard Projects',
						content:
							'Creators, add your film or TV project onto the Film.io platform.',
						top: true,
						left: true
					}}
				/>
				<Callout
					{...{
						title: 'Support Projects you Love',
						content:
							'Stake to show your support and help projects progress within the platform.',
						bottom: true,
						left: true
					}}
				/>
				<Callout
					{...{
						title: 'Particpate to Earn',
						content:
							'Stake, Review, Refer and more to earn FAN Tokens and DAO Reputation.',
						top: true,
						right: true
					}}
				/>
				<Callout
					{...{
						title: 'Film.io Go Score™',
						content:
							'Engage with your favorite projects to unlock functionality and funding opportunities.',
						bottom: true,
						right: true
					}}
				/>
			</Box>

			<Box className={styles.bottom_section}>
				<Grid container spacing={3} className={styles.columns_container}>
					<Grid item md={6} className={styles.column}>
						<Typography component={'h2'}>The Film.io DAO</Typography>
						<Typography component={'p'}>
							DAO governance gives you the ability to influence project
							greenlighting and platform decisions.
						</Typography>
						<Typography component={'h2'}>Transparency & Inclusivity</Typography>
						<Typography component={'p'}>
							Film.io liberates film, TV, and NFT creators and replaces
							centralized power with transparency and inclusivity by handing
							over the governance of Hollywood to fans.
						</Typography>
						{!isAuthenticated && (
							<CustomButton
								customStyle={'outlined'}
								onClick={handleGoToExplore}
							>
								Login/Signup
							</CustomButton>
						)}
					</Grid>
					<Grid item md={6} className={styles.column}>
						<Typography component={'h2'}>The FAN Token</Typography>
						<Typography component={'p'}>
							FAN is a globally compliant governance and staking token used to
							help move independent film, TV and NFT projects through the
							Film.io ecosystem.
						</Typography>
						<Typography component={'h2'}>Reputation & Rewards</Typography>
						<Typography component={'p'}>
							Interact with projects you love and get rewarded with badges,
							NFTs, increased reputation on the platform and many other
							benefits.
						</Typography>
						<CustomButton customStyle={'blue'} onClick={handleGoToExplore}>
							Explore All Projects
						</CustomButton>
					</Grid>
				</Grid>
			</Box>
		</Box>
	)
}

export default DaoSection
