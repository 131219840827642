import { Icon, Typography, Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { LightTooltip } from 'component/common'
import { setFraudWarningDialogOpen } from '../signInSlice'
import newTokenImg from 'assets/images/new_token.svg'
import styles from './ConnectWallet.module.scss'
import { abbreviateNumberFixed } from 'utils/utils'

export default function ConnectWallet() {
	const { user: signInUser, transactionsInProgress } = useSelector(
		state => state.signIn
	)
	const dispatch = useDispatch()

	const handleOpen = () => {
		dispatch(setFraudWarningDialogOpen(true))
	}

	const isFraud = Number(signInUser.isFraud) === 1
	const balance = Number(signInUser.balance).toFixed(2)
	const tooltipTitle = transactionsInProgress
		? 'You have transactions that are still processing and are not yet reflected in your balance.'
		: ''

	return (
		<Box className={styles.wallet_info}>
			<LightTooltip title={tooltipTitle}>
				<Link
					className={styles.wallet_menu_btn}
					to={`/profile/${signInUser.username}?tab=4`}
				>
					<img src={newTokenImg} />
					<Box className={styles.balance_container}>
						<Box>
							<Typography component={'span'}>Available</Typography>
							{transactionsInProgress && (
								<div>
									<Icon baseClassName="far" className="fa-spinner fa-spin" />
								</div>
							)}
						</Box>
						<Typography>{abbreviateNumberFixed(balance, 2)}</Typography>
					</Box>
				</Link>
			</LightTooltip>
			{isFraud &&
				<span className={styles.fraud_icon} onClick={handleOpen}>
					<Icon
						baseClassName="fal"
						className="fa-diamond-exclamation"
						fontSize={'1rem'}
					/>
				</span>
			}
		</Box>
	)
}
