import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useAxiosFetch from 'hooks/useAxiosFetch'
import useNotification from 'hooks/useNotification'
import { getProjectListService } from 'services/project.service'
import { getTileTypes } from 'services/seed.service'
import {
	addTileService,
	getTileByIdService,
	updateTileService
} from 'services/tile.service'
import { updateMetaTags } from 'utils/utils'
import {
	addDaoProposalService,
	updateDaoProposalService
} from 'services/daoProposal.service'
import useAxios from 'hooks/useAxios'
import FilmioDaoContract from 'utils/blockchain/functions/filmioDao'

const useNewTile = () => {
	const [projects, setProjects] = useState([])
	const [tile, setTile] = useState({ tileTypeId: 1 })
	const [image, setImage] = useState(null)
	const [file, setFile] = useState(null)
	const [poster, setPoster] = useState(null)
	const [owner, setOwner] = useState('')
	const [errors, setErrors] = useState({})
	const [tileTypes, setTileTypes] = useState([])
	const [savingProposal, setSavingProposal] = useState(false)
	const { showNotification } = useNotification()
	const navigate = useNavigate()
	const { tileId } = useParams()
	const { axiosService } = useAxios()

	const clearImage = () => {
		setImage(null)
	}

	const [{ loading: loadingTile }] = useAxiosFetch(
		{
			...getTileByIdService(tileId),
			autoexec: tileId !== undefined
		},
		afterGetTile
	)

	function afterGetTile(response) {
		setTile(response)
		setImage(response?.poster?.url)
	}

	const [{ loading: saving }, addTile] = useAxiosFetch(
		{
			...addTileService({
				...tile,
				poster
			}),
			autoexec: false
		},
		afterSave
	)

	const [{ loading: updating }, updateTile] = useAxiosFetch(
		updateTileService({
			...tile,
			poster
		}),
		afterSave
	)

	const addDaoProposal = async () => {
		setSavingProposal(true)
		axiosService(
			addDaoProposalService({
				...tile,
				poster,
				owner,
				description: tile?.content
			}),
			(response, error) => {
				if (!error) {
					updateDaoProposal(response?.id, response?.ipfsHash)
				} else {
					setSavingProposal(false)
				}
			}
		)
	}

	const updateDaoProposal = async (id, ipfsHash) => {
		try {
			const { endDate: endDateStr } = tile
			const startDate = Math.floor(Date.now() / 1000) + 300
			const endDate = Math.floor(new Date(endDateStr).getTime() / 1000)
			const duration = endDate - startDate
			const { hash, proposalId } = await FilmioDaoContract.createProposal(
				ipfsHash,
				startDate,
				duration,
				id
			)
			axiosService(
				updateDaoProposalService({
					id,
					blockchainHash: hash,
					blockchainId: proposalId
				}),
				afterSave
			)
		} catch (error) {
			console.log({ error })
			setSavingProposal(false)
		}
	}

	function afterSave() {
		const text = tileId ? 'updated' : 'created'
		showNotification({ message: `Tile ${text} sucessfuly` })
		navigate('/tileManagement')
		setSavingProposal(false)
	}

	const afterFetchProjects = (data, error) => {
		if (!error) {
			const none = { id: null, title: 'None' }
			setProjects([none, ...data])
		}
	}

	const [{ loading: loadingProjects }] = useAxiosFetch(
		getProjectListService(),
		afterFetchProjects
	)

	const [{ loading: loadingTileTypes }] = useAxiosFetch(
		{
			...getTileTypes(),
			autoexec: true
		},
		(data, error) => {
			if (!error) setTileTypes(data)
		}
	)

	const updateErrors = payload => {
		setErrors({
			...errors,
			...payload
		})
	}

	const isValid = () => {
		let isValid = true
		const errorsTmp = {}
		const message = 'This field is required'
		if (_.isNil(tile.name) || tile.name.trim() === '') {
			errorsTmp.name = message
			isValid = false
		}
		if (_.isNil(tile.content) || tile.content.trim() === '') {
			errorsTmp.content = message
			isValid = false
		}
		if (_.isNil(tile.startDate) || tile.startDate.trim() === '') {
			errorsTmp.startDate = message
			isValid = false
		}
		updateErrors(errorsTmp)
		return isValid
	}

	const handleSubmit = () => {
		let action
		if (tile.tileTypeId === 3) {
			action = addDaoProposal
		} else {
			action = tileId ? updateTile : addTile
		}

		if (isValid()) action()
		else
			showNotification({
				message: 'Please fix the form errors to save changes',
				type: 'error'
			})
	}

	const handleChange = payload => {
		const key = Object.keys(payload)[0]
		updateErrors({ [key]: false })
		setTile({
			...tile,
			...payload
		})
	}

	const handleTypeClick = type => () => {
		handleChange({ tileTypeId: type?.id })
	}

	const handleCancel = () => {
		navigate(-1)
	}

	useEffect(() => {
		updateMetaTags({})
	}, [])

	const showMask =
		loadingProjects ||
		saving ||
		loadingTileTypes ||
		updating ||
		loadingTile ||
		savingProposal

	const submitText = tileId ? 'Update Tile' : 'Create Tile'

	return {
		tile,
		file,
		owner,
		image,
		errors,
		projects,
		showMask,
		tileTypes,
		submitText,
		handleTypeClick,
		handleCancel,
		handleSubmit,
		handleChange,
		clearImage,
		setPoster,
		setOwner,
		setImage,
		setFile
	}
}

export default useNewTile
