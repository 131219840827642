import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
	MenuItem,
	Icon,
	Typography,
	Box,
	Button,
	useMediaQuery,
	IconButton
} from '@mui/material'
import LinearProgress, {
	linearProgressClasses
} from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'
import { helpBaseUrl } from 'config'
import useDefaultMenu from './useDefaultMenu'
import { RingAvatar } from 'component/common/customAvatar'
import useHeader from '../../useHeader'
import { formatAddress } from 'utils/utils'
import styles from './DefaultMenu.module.scss'

const adminBaseUrl = process.env.REACT_APP_ADMIN_URL
const vestingBaseUrl = process.env.REACT_APP_VESTING_URL

const BorderLinearProgress = styled(LinearProgress)(() => ({
	height: 5,
	width: '100%',
	flex: 1,
	position: 'relative',
	marginTop: 8,
	marginBottom: 4,
	borderRadius: 5,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		background: '#000000'
	},
	[`&>.${linearProgressClasses.barColorPrimary}`]: {
		background:
			'#000 linear-gradient(90deg, #01BF52 75%, #01ACBD 80%, #000000);'
	}
}))

const DefaultMenu = () => {
	const {
		jwt,
		tierName,
		tierImage,
		signInUser,
		profileHref,
		tierProgress,
		nextTierName,
		upgradeAvailable,
		handleCopyWalletAddress,
		handleClickUpgradme,
		handleShowTierInfo,
		toggleSearchMobile,
		redirectToProfile,
		handleKYCStart,
		handleClose
	} = useDefaultMenu()

	const {
		isVerified,
		avatar,
		isAdmin,
		username,
		isVesting,
		daoTierRingImg,
		projectCount,
		displayName,
		nextTierId
	} = signInUser
	const { handleLogout } = useHeader()
	const isMobileScreen = useMediaQuery('(max-width:1000px)')
	const isTokenSaleWithoutAccess =
		signInUser?.isTokenSale === 1 && signInUser?.appAccess === 0

	return (
		<>
			<MenuItem className={`${styles.view_profile} ${styles.menu_item}`}>
				<RingAvatar
					{...{
						avatar,
						ring: daoTierRingImg,
						displayName,
						onClick: redirectToProfile
					}}
				/>
				<Box className={styles.menu_header}>
					<Link to={profileHref} onClick={handleClose}>
						<Typography>{displayName}</Typography>
						{isVerified === 1 && (
							<Box className={styles.verified}>
								<Icon baseClassName="far" className="fa-badge-check" />
								Verified
							</Box>
						)}
						{isVerified === 0 && (
							<Box className={styles.verify} onClick={handleKYCStart}>
								<Icon baseClassName="far" className="fa-badge" />
								Verify
							</Box>
						)}
					</Link>
					<Button
						className={styles.wallet_address}
						onClick={handleCopyWalletAddress}
					>
						<Icon baseClassName="far" className="fa-copy" />
						{formatAddress(signInUser?.walletAddress)}
					</Button>
				</Box>
			</MenuItem>
			<Box className={`${styles.dao_tier} ${styles.menu_item}`}>
				{upgradeAvailable && nextTierId ? (
					<Box className={styles.leveling_up} onClick={handleClickUpgradme}>
						<Box className={styles.tier_info}>
							<Typography component={'h4'}>Click to Upgrade</Typography>
							<Typography>
								congrats on reaching <strong>{nextTierName} DAO</strong>
							</Typography>
						</Box>
						<img src={tierImage} />
					</Box>
				) : (
					<Box className={styles.leveling}>
						<Box className={styles.tier_info}>
							<img src={tierImage} />
							<Box className={styles.tier_progress}>
								<Typography component={'h4'}>{tierName} DAO Member</Typography>
								{nextTierId && (
									<>
										<BorderLinearProgress
											variant="determinate"
											value={tierProgress}
										/>
										<Typography>Current progress to {nextTierName}</Typography>
									</>
								)}
							</Box>

							<IconButton
								aria-label="addComment"
								size="small"
								onClick={handleShowTierInfo}
								className={styles.dao_tier_info_btn}
							>
								<Icon baseClassName="fal" className="fa-angle-right" />
							</IconButton>
						</Box>
					</Box>
				)}
			</Box>
			{isMobileScreen && (
				<>
					<MenuItem className={styles.menu_item}>
						<Link to="/explore" onClick={handleClose}>
							<Icon baseClassName="fal" className="fa-compass" />
							<span> Explore Projects </span>
						</Link>
					</MenuItem>
					<MenuItem className={styles.menu_item}>
						<Link to="/newsreel" onClick={handleClose}>
							<Icon baseClassName="fal" className="fa-newspaper" />
							<span> Newsreel </span>
						</Link>
					</MenuItem>
				</>
			)}
			{projectCount > 0 && (
				<MenuItem className={styles.menu_item}>
					<Link to={`profile/${username}?tab=2`} onClick={handleClose}>
						<Icon baseClassName="fal" className="fa-film" />
						<span> Your Projects </span>
					</Link>
				</MenuItem>
			)}
			<MenuItem className={styles.menu_item}>
				<Link to="/settings" onClick={handleClose}>
					<Icon baseClassName="fal" className="fa-gear" />
					<span> Your Settings </span>
				</Link>
			</MenuItem>
			<MenuItem className={styles.menu_item}>
				<Link onClick={toggleSearchMobile}>
					<Icon baseClassName="fal" className="fa-search" />
					<span> Search </span>
				</Link>
			</MenuItem>
			<MenuItem className={`${styles.nohover} ${styles.borderBottom}`}>
				<Link
					to="/newProject"
					className={styles.join_btn}
					onClick={handleClose}
				>
					<span> Submit Your Project </span>
				</Link>
			</MenuItem>
			{Number(isAdmin) === 1 && (
				<MenuItem className={styles.menu_item}>
					<Link to="/tileManagement" onClick={handleClose}>
						<Icon baseClassName="fal" className="fa-grid-2-plus" />
						<span> FET Management </span>
					</Link>
				</MenuItem>
			)}

			{jwt && Number(isAdmin) === 1 && (
				<MenuItem className={styles.menu_item}>
					<a
						href={`${adminBaseUrl}/admin/auth/${jwt}`}
						target="_blank"
						rel="noreferrer"
						onClick={handleClose}
					>
						<Icon baseClassName="fal" className="fa-user-crown" />
						<span> Platform Administration </span>
					</a>
				</MenuItem>
			)}
			{jwt && Number(isAdmin) === 1 && Number(isVesting) === 1 && (
				<MenuItem className={styles.menu_item}>
					<a
						href={`${vestingBaseUrl}/user/auth/${jwt}`}
						target="_blank"
						rel="noreferrer"
						onClick={handleClose}
					>
						<Icon baseClassName="fal" className="fa-vest" />
						<span> Wallet Vesting Management </span>
					</a>
				</MenuItem>
			)}
			<MenuItem
				className={`${styles.menu_item} ${styles.borderBottom}`}
				onClick={handleLogout}
			>
				<Link onClick={handleClose}>
					<Icon baseClassName="fal" className="fa-arrow-right-from-bracket" />
					{isTokenSaleWithoutAccess ? (
						<span> Disconnect </span>
					) : (
						<span> Logout </span>
					)}
				</Link>
			</MenuItem>
			<MenuItem className={styles.menu_item}>
				<a
					href={`${helpBaseUrl}/knowledgebase/welcome/what-is-film.io`}
					target="_blank"
					rel="noreferrer"
					onClick={handleClose}
				>
					<Icon baseClassName="fal" className="fa-life-ring" />
					<span> Get Help </span>
				</a>
			</MenuItem>
		</>
	)
}

DefaultMenu.propTypes = {
	anchorEl: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
	handleClose: PropTypes.func
}

export default DefaultMenu
