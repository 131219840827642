import { Grid } from '@mui/material'
import { ReviewItem } from './reviewItem'
import WriteReview from './writeReview/WriteReview'
import useReviewsTab from './useReviewsTab'
import { RecentActivitySection } from '../dashboardTab/recentActivity/RecentActivity'
import reviewStyles from './ReviewsTab.module.scss'
import InfiniteScroll from 'react-infinite-scroll-component'

const ReviewsTab = () => {
	const { showWriteReview, reviews, onScroll } = useReviewsTab()

	return (
		<InfiniteScroll {...{ onScroll, dataLength: 10000000 }}>
			<Grid container className={reviewStyles.reviewRatting} spacing={2}>
				<Grid item md={7} className="border-top">
					<WriteReview hide={!showWriteReview} />
					<div className="projectinfo">
						{reviews?.map(item => (
							<ReviewItem {...item} key={item.id} />
						))}
					</div>
				</Grid>
				<Grid item md={5}>
					<Grid item md={12} className={reviewStyles.badges_achievements}>
						<RecentActivitySection />
					</Grid>
				</Grid>
			</Grid>
		</InfiniteScroll>
	)
}

export default ReviewsTab
