import axios from 'axios'
import magic, { ethersInstance, contractsConfig, jsonRpcProvider } from './config/constants'
import { waitForTransaction } from './config/utils'
import { ethers } from 'ethers'
import _ from 'lodash'
import { retryWithDelay } from 'utils/utils'

export const prepareTransaction = async (
	contractName,
	functionName,
	functionArgs,
	idNote
) => {

	const contract = new ethers.Contract(
		contractsConfig[contractName].address,
		contractsConfig[contractName].abi,
		jsonRpcProvider
	)

	const rawTransaction = await contract.populateTransaction[functionName](
		...functionArgs
	)
	const hexNote = ethers.utils.hexlify(
		ethers.utils.toUtf8Bytes(idNote ?? 'No Note Provided!')
	)

	let txData = rawTransaction.data
	if(idNote){
		txData += hexNote.substring(2);
	}
	const transaction = {
		to: contractsConfig[contractName].address,
		data: txData
	}

	return transaction
}

const getHashByRequestId = requestId => async () => {
	const url = `https://gas-api.magic.link/v1/relayer/get-request-state?request_id=${requestId}`
	const response = await axios.get(url)
	const hash = response?.data?.tx_hash
	const state = response?.data?.state
	if (state === 'FAILED') return Promise.resolve(null)
	return _.isNil(hash)
		? Promise.reject(new Error('Hash empty'))
		: Promise.resolve(hash)
}

export const sendBlockchainTransaction = async (
	contractName,
	functionName,
	functionArgs,
	idNote,
	signer
) => {
	try {
		const transaction = await prepareTransaction(
			contractName,
			functionName,
			functionArgs,
			idNote
		)

		if (_.isNil(signer)) {
			const userWalletAddress = await getMagicWalletAddress()
			const gaslessRequest = await magic.wallet.sendGaslessTransaction(
				userWalletAddress,
				transaction
			)
			return await retryWithDelay(getHashByRequestId(gaslessRequest.request_id))
		} else {
			const hash = await signer.sendTransaction(transaction)
			await waitForTransaction(hash)
			return hash
		}
	} catch (error) {
		console.log({ error })
		return null
	}
}

export const getContractValue = async (
	contractName,
	functionName,
	functionArgs
) => {

	const contract = new ethers.Contract(
		contractsConfig[contractName].address,
		contractsConfig[contractName].abi,
		jsonRpcProvider
	)

	const value = await contract[functionName](...functionArgs)
	return value
}

export const getMagicWalletAddress = async () => {
	const metadata = await magic?.user?.getInfo()
	return metadata?.publicAddress
}

export const signMagicVerificationMessage = async () => {
	if (!ethersInstance || !magic) {
		throw new Error('Magic is not initialized')
	}

	const signer = ethersInstance.getSigner()

	const message = 'filmio-verification-message'
	const hash = ethers.utils.solidityKeccak256(['string'], [message])
	const signature = await signer.signMessage(ethers.utils.arrayify(hash))

	return {
		message,
		signature
	}
}
