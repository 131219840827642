import PropTypes from 'prop-types'
import {
	Icon,
	Typography,
	Box,
	Button,
} from '@mui/material'
import useTierInfoMenu from './useTierInfoMenu'
import { useDispatch } from 'react-redux'
import loadingChest from 'assets/images/dao-tiers/loading.svg'
import { setCreateMagicWalletDialogOpen, setShowTierInfoMenu } from 'component/header/signInSlice'
import styles from './TierInfoMenu.module.scss'

const TierInfoMenu = () => {
	const { signInUser, daoTierInfo } = useTierInfoMenu()
	const dispatch = useDispatch()
	const isEmailVerified = signInUser?.isEmailVerified === 1

	const handleTriggerClaimMagicWallet = () => {
		dispatch(setCreateMagicWalletDialogOpen(true))
	}

	const handleHideTierInfo = () => {
		dispatch(setShowTierInfoMenu(false))
	}

	const renderTodo = () => {
		const metrics = daoTierInfo?.nextDaoTier?.metrics ?? {}
		const userMetrics = daoTierInfo?.userMetrics
		return Object.keys(metrics).map(key => {
			const threshold = metrics[key]
			const value = userMetrics[key] ?? 0
			const completed = value >= threshold
			let name = ''
			let label = ''
			let goal = ''
			let extra = ''

			switch (key) {
				case 'Is User Verified':
					name = 'Verified Account'
					break
				case 'Refer':
					name = 'Refer'
					label = 'users '
					goal = threshold
					break
				case 'Setup Platform Wallet':
					name = key
					extra = <Button className={styles.verifyButton} onClick={handleTriggerClaimMagicWallet}>Setup</Button>

					break
				default:
					name = key
					label = key === 'Stake' ? 'FAN ' : 'projects '
					goal = threshold
					break
			}
			const classes = `${styles.dao_metric} ${completed ? styles.completed : ''}`

			return (
				<Typography key={key} className={classes}>
					<span className={styles.complete}>
						({value}/{threshold})
						{' '}
					</span>
					<span className={styles.metric}>{name} {goal} {label}
						{!isEmailVerified && extra}
					</span>
				</Typography>
			)
		})
	}

	const renderBenefits = (benefits) =>
		benefits
			?.filter(b => {
				const isAidropUser =
					Number(signInUser?.isAirdropFan) === 1 ||
					Number(signInUser?.isAirdropCreator) === 1
				if (!isAidropUser && b.highlight) return false
				return true
			})
			.map(b => {
				return (
					<Typography key={b.id} className={b.highlight ? styles.green : ''}>
						<span>
							<img src={loadingChest} />
						</span>{' '}
						{b.description}
					</Typography>
				)
			})

	return <>
		<Box className={styles.dao_tier_info}>
			<Box className={styles.tier_info_header} onClick={handleHideTierInfo}>
				<Icon baseClassName="fal" className="fa-arrow-left" />
				<span>DAO Membership Tier</span>
			</Box>
			<Box className={styles.tier_info_details}>
				<Typography component={'h2'}>{signInUser?.daoTierName} DAO Member</Typography>
				<Typography component={'h4'}>Membership Detail and Unlocks</Typography>
				{renderBenefits(daoTierInfo?.daoTier?.benefits)}
			</Box>
			<Box className={styles.separator}></Box>
			{daoTierInfo?.nextDaoTier ?
				<>
					<Box className={styles.next_tier_info}>
						<Typography component={'h2'}>{signInUser?.nextTierName} DAO Member <span>(Next Tier)</span></Typography>
						<Typography component={'h4'}>Membership Detail and Unlocks</Typography>
						<Typography className={styles.title}>Requirements</Typography>
						{renderTodo()}
						<Typography className={styles.title}>Rewards</Typography>
						{renderBenefits(daoTierInfo?.nextDaoTier?.benefits)}
					</Box>
				</>
				:
				<>
					<Box className={styles.next_tier_info}>
						<Typography component={'h2'}>Next DAO Tier</Typography>
						<Typography component={'h4'}>You have reached the final tier!</Typography>
					</Box>
				</>
			}
		</Box>
	</>
}

TierInfoMenu.propTypes = {
	anchorEl: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
	handleClose: PropTypes.func
}

export default TierInfoMenu