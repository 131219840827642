import { setDaoProposalUpdated } from 'component/dashboard/dashboardSlice'
import useAxios from 'hooks/useAxios'
import useLoadingMask from 'hooks/useLoadingMask'
import useNotification from 'hooks/useNotification'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
	getDaoProposalByIdService,
	updateVoteDaoProposalService,
	voteDaoProposalService
} from 'services/daoProposal.service'
import FilmioDaoContract from 'utils/blockchain/functions/filmioDao'

const useDaoProposalDialog = () => {
	const [loading, setLoading] = useState(false)
	const { axiosService } = useAxios()
	const dispatch = useDispatch()
	const { showNotification } = useNotification()
	const { showMask, hideMask } = useLoadingMask()
	
	const updateVoteDaoProposal = async data => {
		const { hash: blockchainHash, votingWeight } = await FilmioDaoContract.vote(
			data?.proposalBlockchainId,
			data?.accepted === 1,
			data?.id
		)
		return new Promise((resolve, _reject) => {
			axiosService(
				updateVoteDaoProposalService({
					id: data?.id,
					blockchainHash,
					votingWeight
				}),
				(_response, error) => {
					if (!error) {
						getDaoProposalById(data?.daoProposalId)
					} else {
						hideMask()
						setLoading(false)
					}
					resolve(true)
				}
			)
		})
	}

	const getDaoProposalById = id => {
		axiosService(getDaoProposalByIdService(id), (response, error) => {
			if (!error) {
				dispatch(setDaoProposalUpdated(response))
			}
			hideMask()
			setLoading(false)
		})
	}

	const voteDaoProposal = data => {
		showMask(
			'Submitting your DAO Proposal Vote to the blockchain.\n Please wait.'
		)
		setLoading(true)
		axiosService(voteDaoProposalService(data), (response, error) => {
			if (!error) {
				updateVoteDaoProposal({ ...response })
			} else {
				setLoading(false)
				hideMask()
			}
		})
	}

	const handleVote = (accepted, daoProposalId) => async () => {
		const message =
			accepted === 1
				? 'Approve the listing of $FAN Tokens on Uniswap and potential CEXs as evaluated by the Global Creativity Foundation at such price as the Foundation reasonably determines having regard to such factors as the Foundation considers suitable and appropriate.'
				: 'Do not proceed with the listing of $FAN Tokens on any exchanges.'
		const buttonText = accepted === 1 ? 'Accept Proposal' : 'Reject Proposal'
		showNotification({
			message,
			cancelButton: true,
			buttonText,
			buttonCallback: () => {
				voteDaoProposal({
					accepted,
					daoProposalId
				})
			}
		})
	}

	return { loading, handleVote, updateVoteDaoProposal, getDaoProposalById }
}

export default useDaoProposalDialog
