import { useMediaQuery } from '@mui/material'
import { MobileMenu } from './mobileMenu'
import { SidebarMenu } from './sidebarMenu'
import styles from './SideBar.module.scss'
import { useSelector } from 'react-redux'

const SideBarDashboard = () => {
	const isMobileScreen = useMediaQuery('(max-width:430px)')
	const { activeContests } = useSelector(state => state.signIn)
	const isThereActiveContests = activeContests?.length > 0
	return (
		<>
			{isMobileScreen ? (
				<MobileMenu isThereActiveContests={isThereActiveContests}/>
			) : (
				<section
					className={`${styles.sidebar_dashboard} ${
						isThereActiveContests ? styles.with_banner : ''
					}`}
				>
					<SidebarMenu />
				</section>
			)}
		</>
	)
}

export default SideBarDashboard
