import { Box } from '@mui/material' 
import styles from './GoScoreCreatorEngagementBar.module.scss'
import PropTypes from 'prop-types'

const GoScoreCreatorEngagementBar = ({ value,createrStatus }) => { 
	return (
		<Box className={styles.progress_container}>
			<Box className={createrStatus===2?`${styles.progress_bar} ${styles.no_fire}`:`${styles.progress_bar}`} style={{width:`${value}%`}}>
			</Box>
		</Box>
	)
}

GoScoreCreatorEngagementBar.propTypes = {
	value: PropTypes.number,
	createrStatus: PropTypes.number

}

export default GoScoreCreatorEngagementBar
