import { Button, Container, Grid, Typography } from '@mui/material'
import {
	LoadMask,
	SelectBox,
	TextArea,
	TextFieldControl
} from 'component/common'
import { DropZone } from 'component/common/dropZone'
import { formatDate, humanDateDiff } from 'utils/date.utils'
import HtmlEditor from 'component/common/htmlEditor/HtmlEditor'
import styles from './NewTile.module.scss'
import { TilePreview } from './tilePreview'
import useNewTile from './useNewTile'

const NewTile = () => {
	const {
		tile,
		file,
		image,
		owner,
		errors,
		projects,
		showMask,
		tileTypes,
		submitText,
		handleTypeClick,
		handleCancel,
		handleSubmit,
		handleChange,
		clearImage,
		setPoster,
		setImage,
		setOwner,
		setFile
	} = useNewTile()

	const isDaoProposal = tile.tileTypeId === 3

	const getBtnClasses = id => {
		const classes = [styles.type_btn]
		if (tile.tileTypeId === id) {
			classes.push(styles.active)
		}
		return classes.join(' ')
	}

	const renderTypeButtons = (tileTypes = []) => {
		return tileTypes.map(el => {
			return (
				<button
					key={el.id}
					className={getBtnClasses(el?.id)}
					onClick={handleTypeClick(el)}
				>
					{el?.name}
				</button>
			)
		})
	}

	return (
		<>
			{showMask ? (
				<LoadMask />
			) : (
				<Container disableGutters maxWidth="md" className={styles.new_tile}>
					<Grid container spacing={4}>
						<Grid item xs={12} className={styles.controls}>
							<Button
								className={`yellow-bg-button ${styles.create_btn}`}
								onClick={handleSubmit}
							>
								{submitText}
							</Button>
							<Button className={styles.cancel_btn} onClick={handleCancel}>
								Cancel
							</Button>
						</Grid>
						<Grid
							item
							xs={7}
							className={styles.edit_form}
							container
							spacing={3}
						>
							<Grid item xs={12} container spacing={2}>
								<Grid item xs={12}>
									<Typography component={'h3'}>Type</Typography>
								</Grid>
								<Grid item xs={12} className={styles.types}>
									{renderTypeButtons(tileTypes)}
								</Grid>
							</Grid>
							{tile.tileTypeId !== 3 && (
								<Grid item xs={12}>
									<SelectBox
										{...{
											options: projects || [],
											value: tile?.projectId,
											variant: 'blue',
											placeholder: 'Project',
											label: 'Project',
											displayField: 'title',
											setValue: value => {
												handleChange({ projectId: value })
											}
										}}
									/>
								</Grid>
							)}

							{tile.tileTypeId === 2 && (
								<Grid item xs={12}>
									<TextFieldControl
										label="Link URL"
										value={tile?.link || ''}
										variant={'blue'}
										onChange={e => {
											handleChange({ link: e.target.value })
										}}
									/>
								</Grid>
							)}
							{isDaoProposal && (
								<Grid item xs={12}>
									<TextFieldControl
										error={errors?.owner}
										label="Owner*"
										value={owner || ''}
										variant={'blue'}
										autoFocus
										onChange={e => {
											setOwner(e.target.value)
										}}
									/>
								</Grid>
							)}
							<Grid item xs={12}>
								<TextFieldControl
									error={errors?.name}
									label="Title*"
									value={tile?.name || ''}
									variant={'blue'}
									autoFocus
									onChange={e => {
										handleChange({ name: e.target.value })
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								{isDaoProposal ? (
									<HtmlEditor
										{...{
											error: errors?.content,
											label: 'Text*',
											ariaLabel: 'text',
											value: tile?.content,
											maxLength: 5000,
											setValue: content => {
												handleChange({ content })
											}
										}}
									/>
								) : (
									<TextArea
										error={errors?.content}
										variant={'blue'}
										minRows={5}
										maxLength={5000}
										label="Text*"
										aria-label="text"
										value={tile?.content || ''}
										onChange={e => {
											handleChange({ content: e.target.value })
										}}
									/>
								)}
								<em>
									{`${tile?.content?.length ?? 0}`}/{isDaoProposal ? 5000 : 180}
								</em>
							</Grid>
							<Grid item xs={12}>
								<DropZone
									{...{
										accept: { 'image/*': ['.jpeg', '.png', '.jpg'] },
										clearFile: clearImage,
										setBlob: setImage,
										setFile,
										defaultFile: tile?.poster
									}}
								/>
							</Grid>
							{tile.tileTypeId !== 3 && (
								<Grid item xs={12}>
									<TextFieldControl
										label="Video"
										placeholder="The link to your Trailer at YouTube"
										helperText="YouTube or Vimeo link"
										helperTextRight="This will override any image you have attached"
										value={tile.videoLink || ''}
										variant={'blue'}
										onChange={e => {
											handleChange({ videoLink: e.target.value })
										}}
									/>
								</Grid>
							)}
							<Grid item xs={6}>
								<TextFieldControl
									error={errors?.startDate}
									label="Start date*"
									placeholder="Start date"
									value={formatDate(tile?.startDate, 'inputDate') || ''}
									type="date"
									variant={'blue'}
									onChange={e => {
										handleChange({ startDate: e.target.value })
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextFieldControl
									label="End date"
									placeholder="End date"
									value={formatDate(tile?.endDate, 'inputDate') || ''}
									type="date"
									variant={'blue'}
									onChange={e => {
										handleChange({ endDate: e.target.value })
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography component={'h3'}>
									<span className={styles.tile_duration}>Tile Duration:</span>{' '}
									{tile?.endDate
										? humanDateDiff(tile.startDate, tile.endDate)
										: 'indefinite'}
								</Typography>
							</Grid>
						</Grid>
						<Grid item xs={5} className={styles.preview_container}>
							<TilePreview
								{...{
									image,
									tile,
									setCroppedImage: setPoster,
									fileName: file?.name
								}}
							/>
						</Grid>
					</Grid>
				</Container>
			)}
		</>
	)
}

export default NewTile
