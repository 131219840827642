import {
	Icon,
	Box,
	useMediaQuery,
	IconButton,
	Menu
} from '@mui/material'
import useViewProfile from './useViewProfile'
import { RingAvatar } from 'component/common/customAvatar'
import { BlokchainWallet } from 'component/header/blokchainWallet'
import { TokenSaleMenu } from './tokenSaleMenu'
import { TierInfoMenu } from './tierInfoMenu'
import { DefaultMenu } from './defaultMenu'
import styles from './ViewProfile.module.scss'

export default function ViewProfile() {
	const {
		anchorEl,
		menuOpen,
		signInUser,
		showTierInfoMenu,
		upgradeAvailable,
		handleClose,
		handleOpen
	} = useViewProfile()
	const isMobileScreen = useMediaQuery('(max-width:1000px)')

	const {
		avatar,
		daoTierRingImg,
		daolevelUpImage,
		displayName,
	} = signInUser

	const isTokenSaleWithoutAccess =
		signInUser?.isTokenSale === 1 && signInUser?.appAccess === 0

	return (
		<Box className={styles.avatar_container}>
			{(isTokenSaleWithoutAccess) ? (
				<>
					<BlokchainWallet />
					<RingAvatar
						{...{
							avatar,
							ring: daoTierRingImg,
							levelUp: upgradeAvailable ? daolevelUpImage : null,
							displayName,
							onClick: handleOpen
						}}
					/>
					<Menu
						classes={{ paper: styles.menu_popup }}
						anchorEl={anchorEl}
						keepMounted
						open={menuOpen}
						onClose={handleClose}
					>
						<TokenSaleMenu />
					</Menu>
				</>
			) : (
				<>
					{!isMobileScreen ? (
						<RingAvatar
							{...{
								avatar,
								ring: daoTierRingImg,
								levelUp: upgradeAvailable ? daolevelUpImage : null,
								displayName,
								onClick: handleOpen
							}}
						/>
					) : (
						<IconButton size="small" onClick={handleOpen}>
							<Icon baseClassName="fal" className="fa-bars" />
						</IconButton>
					)}

					<Menu
						classes={{ paper: styles.menu_popup }}
						anchorEl={anchorEl}
						keepMounted
						open={menuOpen}
						onClose={handleClose}
					>
						{showTierInfoMenu
							? <TierInfoMenu />
							: <DefaultMenu />
						}
					</Menu>
				</>
			)}
		</Box>
	)
}
