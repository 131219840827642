import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import {
	useNavigationType,
	useLocation,
	createRoutesFromChildren,
	matchRoutes
} from 'react-router-dom'
import { config } from '../config'

export const initSentry = () => {
	Sentry.init({
		dsn: config.sentryDsn,
		environment: process.env.REACT_APP_ENVIRONMENT,
		integrations: [
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes
			})
		],
		tracesSampleRate: 0.1,
		tracePropagationTargets: ['localhost', /^https:\/\/api\.film\.io\//]
	})
}
