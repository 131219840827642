import { Box, Typography, Button } from '@mui/material'
import newToken from 'assets/images/new_token_blue.svg'
import gemIcon from 'assets/images/gem_icon.png'
import styles from './DiamondCircleHodl.module.scss'
import { LightTooltip } from 'component/common'
import Grid from '@mui/material/Unstable_Grid2'
import timerIcon from 'assets/images/walletIcons/timer.svg'
import InfoDiamond from 'assets/images/diamond_icon_info.svg'
import { SpeedUpDialog } from '../speedUpDialog'
import { abbreviateNumberFixed } from 'utils/utils'
import useDiamondCircleHodl  from './useDiamondCircleHodl'
import PropTypes from 'prop-types'
import { formatDate } from 'utils/date.utils'
import { SpeedUpBar } from 'component/common/speedUpBar'

const DiamondCircleHodl = ({changeTab}) => {
	const {
		progress,
		gemTotal,
		hodlAmount,
		earningsToDate,
		type,
		startDate,
		endDate,
		balance,
		balanceTotal,
		stakesTotal,
		timeToUnlock,
		earningsTotal,
		unlockedRewards,
		openSpeedUpDialog,
		handleCloseSpeedUp,
		handleOpenSpeedUp,
		updateOverview,
	} = useDiamondCircleHodl()

	const rewardsTokens = Math.max((earningsTotal ?? 0) - (unlockedRewards ?? 0), 0)

	const onClickChangeTab = () =>{
		changeTab(1)
	}

	return (
		<Box className={styles.overview}>
			<Box className={styles.diamond_circle_HODL}>
				<Box className={styles.body}>
					<Box className={styles.top}>
						<Typography component={'h2'}>Diamond Circle HODL
							<span>
								<img alt='info Icon' src={InfoDiamond} />
							</span>
						</Typography>
					</Box>
					<Box className={styles.center}>
						<Typography component={'h1'}>
							<img alt='info Icon' src={newToken} />
							{abbreviateNumberFixed(hodlAmount, 1)}
						</Typography>
					</Box>
					<Box className={styles.bottom}>
						<Button onClick={onClickChangeTab} className={styles.back_to_main}>
							Back to Main Wallet
						</Button>
					</Box>
				</Box>
			</Box>
			<Box className={styles.bones_earings}>
				<Box className={styles.body}>
					<Box className={styles.top}>
						<Typography component={'h2'}>Bonus Earnings to Date
							<span>
								<img alt='info Icon' src={InfoDiamond} />
							</span>
						</Typography>
					</Box>
					<Box className={styles.center}>
						<Typography component={'h1'}>
							<img alt='info Icon' src={newToken} />
							{abbreviateNumberFixed(earningsToDate, 1)}
						</Typography>
					</Box>
					<Box className={styles.bottom}>
						<Box className={styles.btn_bonus}>
							<span className={styles.bonus}>
								{type?.hodlPeriod} Months
							</span>
							<span className={styles.bonus}>
								{type?.bonusMultiplier}% Bonus
							</span>
						</Box>

					</Box>
				</Box>
			</Box>
			<Box className={styles.both_section}>
				<Box className={styles.hodl_period}>
					<Box className={styles.body}>
						<Box className={styles.top}>
							<Typography component={'h2'} className={styles.header_token_hodl}>HODL Period:
							</Typography>

						</Box>
						<Box className={styles.number_copy}>
							<Typography component={'h2'} className={styles.header_token_hodl}>{formatDate(startDate)}
							</Typography>
							{' - '}
							<Typography component={'h2'} className={styles.header_token_hodl}>{formatDate(endDate)}
							</Typography>
						</Box>
					</Box>
				</Box>
				<Box className={styles.balance_breakdown}>
					<Box className={styles.body}>
						<Box className={styles.top}>
							<Typography component={'h2'} className={styles.header_token_hodl}>
								Balance Breakdown:
								<span className={styles.inside_header}>
								{abbreviateNumberFixed(balanceTotal ?? 0, 2)}
								</span>
							</Typography>
						</Box>
						<Box className={styles.number_copy}>
							<Typography component={'h2'} className={styles.header_token_hodl}>{abbreviateNumberFixed(balance ?? 0, 2)} Available
							</Typography>
							<Typography component={'h2'} className={styles.header_token_hodl}>{abbreviateNumberFixed(stakesTotal ?? 0, 2)} Staked
							</Typography>

						</Box>
					</Box>
				</Box>
			</Box>


			<Box className={styles.speedUp}>
				<Box className={styles.body}>
					<Box className={styles.top}>
						<Typography component={'h2'}>
							Reward Release Schedule
							<LightTooltip
								enterTouchDelay={0}
								leaveTouchDelay={4000}
								placement='bottom'
								title="Rewards and Bonus Tokens are subject to Film.io's Rewards Release Schedule (RRS) and are released based on your activity, reputation, time, and can be accelerated using gems.">
								<span>
									<img alt='info Icon' src={timerIcon} />
								</span>
							</LightTooltip>
						</Typography>
						<Box className={`${styles.center} ${styles.flex_column}`}>
							<Box className={styles.countdown}>{timeToUnlock}</Box>
							<Box className={styles.progress_container}> 
								<SpeedUpBar value={progress} />
							</Box>
							<Grid className={styles.flex_Gems} container xs={12}>
								<Typography className={styles.flex_width} component={'h3'}>Your Gems:</Typography>
								<LightTooltip
									enterTouchDelay={0}
									leaveTouchDelay={4000}
									placement='bottom'
									title="Gems are used to accelerate your RRS and are earned over time (similar to an FGR - the more FAN you stake the more gems you earn), as well as by completing quests at club.film.io"
								>
									<img width={17} height={14} src={gemIcon} alt="Timer Icon" />
								</LightTooltip>
								<Typography className={styles.gem_text} component={'h2'}>{abbreviateNumberFixed(gemTotal ?? 0, 2)} </Typography>
							</Grid>
							<Button className={styles.speedUpBtn} onClick={handleOpenSpeedUp} disabled={progress === '0%' || gemTotal <= 0 || earningsTotal <= 0}>
								<img src={gemIcon} />
								Speed Up
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
			<SpeedUpDialog 
				open={openSpeedUpDialog}
				handleClose={handleCloseSpeedUp}
				gemTotal={gemTotal}
				timeToUnlock={timeToUnlock}
				rewardsTokens={rewardsTokens}
				releasedTokens={unlockedRewards}
				percentage={progress}
				updateOverview={updateOverview}
			/>
		</Box>
	)

}

DiamondCircleHodl.propTypes = {
    changeTab: PropTypes.func.isRequired
}

export default DiamondCircleHodl
