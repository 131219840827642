import { baseUrl } from 'config'
import _ from 'lodash'

export const getSignUpFacebookUrl = () => `${baseUrl}/user/sauth/facebook`

export const getSignUpTwitterUrl = () => `${baseUrl}/user/sauth/twitter`

export const builApidUrl = (path = '') => {
	const includeSlash = path.startsWith('/')
	return `${baseUrl}${includeSlash ? '' : '/'}${path}`
}

export const getFileUrl = src => {
	const awsBucketBaseUrl = process.env.REACT_APP_IMAGEKIT_URL
	return _.isNil(src) || src.startsWith('https://')
		? src
		: `${awsBucketBaseUrl}${src}`
}

export const isRouteMatch = (pathname, routes) => {
	return routes.some(route => {
		if (route.includes(':')) {
			const routePattern = new RegExp(
				`^${route.replace(/:[^/]+/g, '([^/]+)')}$`
			)
			return routePattern.test(pathname)
		}
		return pathname === route
	})
}

export { baseUrl }
