import PropTypes from 'prop-types'
import images from 'assets/images/product-detail'
import { Box, Icon, IconButton } from '@mui/material'
import { Link } from 'react-router-dom'
import badge from 'assets/images/sss-small.svg'
import styles from './UserItem.module.scss'
import { followUnfollowUser } from 'services/followUnfollow.service'
import useNotification from 'hooks/useNotification'
import { useState } from 'react'
import useCommon from 'hooks/useCommon'

const UserItem = ({
	about,
	displayName,
	avatar,
	headerImg,
	username,
	isFollowedByUser,
	isStaffPick,
	id,
	isVerified
}) => {
	const [followed, setFollowed] = useState(isFollowedByUser)
	const { showNotification } = useNotification()
	const { checkNoAuthToShowJoin } = useCommon()
	const handleFollowUnfollowUserClick = async () => {
		if (checkNoAuthToShowJoin()) {
			const followingUser = isFollowedByUser
			const userId = id
			const res = await followUnfollowUser(userId, followingUser)
			if (res.status === 200) {
				const message = `You are ${
					followingUser ? 'no longer' : 'now'
				} following ${displayName}`
				showNotification({ message })
				setFollowed(f => !f)
			}
		}
	}
	const link = `/profile/${username}`
	return (
		<Box className={styles.user_item}>
			<Box className={styles.follow}>
				{followed && (
					<IconButton
						aria-label="addComment"
						size="small"
						onClick={handleFollowUnfollowUserClick}
						className={styles.unfollow_btn}
					>
						<Icon baseClassName="fal" className="fa-user-minus" />
					</IconButton>
				)}
				{!followed && (
					<IconButton
						aria-label="addComment"
						size="small"
						onClick={handleFollowUnfollowUserClick}
						className={styles.follow_btn}
					>
						<Icon baseClassName="fal" className="fa-user-plus" />
					</IconButton>
				)}
			</Box>
			<Box className={styles.mid_section}>
				<img src={avatar} className={styles.avatar} />
				{isStaffPick && <img src={badge} className={styles.badge} />}
			</Box>
			<Link to={link} className={styles.header}>
				<img
					loading="lazy"
					className={styles.artwork}
					src={headerImg + '?tr=w-276' ?? images?.posterNotAvailable}
					alt=""
				/>
			</Link>
			<Box className={styles.content}>
				<Link to={link}>
					{displayName}
					{isVerified === 1 && (
						<Icon baseClassName="fas" className="fa-badge-check" />
					)}
				</Link>
				<Box
					className={styles.about}
					dangerouslySetInnerHTML={{ __html: about }}
				></Box>
			</Box>
		</Box>
	)
}

UserItem.propTypes = {
	id: PropTypes.string,
	about: PropTypes.string,
	displayName: PropTypes.string,
	avatar: PropTypes.string,
	link: PropTypes.string,
	headerImg: PropTypes.string,
	username: PropTypes.string,
	isFollowedByUser: PropTypes.bool,
	isStaffPick: PropTypes.bool,
	isVerified: PropTypes.number
}

export default UserItem
