import { Box, Grid, Icon, Typography } from '@mui/material'
import { CustomButton } from 'component/common/customButton'
import { useState } from 'react'
import styles from './StartSection.module.scss'
import images from 'assets/images'
// import { TextFieldControl } from 'component/common'

const StartSection = () => {
	const [toogleFan, setToogleFan] = useState(true)
	// const [email, setEmail] = useState('')

	const handleToggleFanCreator = value => () => {
		setToogleFan(value)
	}

	return (
		<Box className={styles.start}>
			<Box className={styles.header}>
				<Typography component={'h3'}>
					A Beginners Guide to the Film.io Platform
				</Typography>
				<p>
					Staking, voting, DAO’s and blockchain? We know it can be overwhelming
					at first, so we’ve created videos and articles to demystify the magic
					of the Film.io platform. You wll find jumping off points for both
					Creators and Fans below.
				</p>
			</Box>

			<iframe
				src="https://player.vimeo.com/video/704609704?h=774874be14"
				width="640"
				height="360"
				frameBorder={0}
				allow="autoplay; fullscreen"
			></iframe>
			<Typography component={'h3'}>What Brings You to Film.io?</Typography>
			<Box className={styles.start_section_actions}>
				<CustomButton
					customStyle={toogleFan ? 'blue' : 'no_text_transform'}
					onClick={handleToggleFanCreator(true)}
				>
					I&apos;m a Fan
				</CustomButton>
				<CustomButton
					customStyle={!toogleFan ? 'blue' : 'no_text_transform'}
					onClick={handleToggleFanCreator(false)}
				>
					I&apos;m a Creator
				</CustomButton>
			</Box>

			<Box className={styles.bottom_section}>
				<Grid container spacing={3} className={styles.columns_container}>
					<Grid item md={2}></Grid>
					<Grid item md={4} className={styles.column}>
						<Box className={styles.info}>
							<img src={images.empowerFans} />
							<Typography component={'p'}>
								Find out how Film.io empowers fans by giving them a voice.
							</Typography>
						</Box>
						{toogleFan ? (
							<CustomButton
								customStyle={'purple'}
								href={
									'https://help.film.io/knowledgebase/welcome/what-is-film.io'
								}
								target="_blank"
								rel="noreferrer"
								endIcon={
									<Icon
										baseClassName="fas"
										className="fa-thin fa-arrow-up-right"
									/>
								}
							>
								Getting Started
							</CustomButton>
						) : (
							<>
								<CustomButton
									customStyle={'purple'}
									href={
										'https://help.film.io/knowledgebase/creator-academy/project-submission-process'
									}
									target="_blank"
									rel="noreferrer"
									endIcon={
										<Icon
											baseClassName="fas"
											className="fa-thin fa-arrow-up-right"
										/>
									}
								>
									Creating Your Project
								</CustomButton>
								<CustomButton
									customStyle={'purple'}
									href={
										'https://help.film.io/knowledgebase/getting-started/your-profile'
									}
									target="_blank"
									rel="noreferrer"
									endIcon={
										<Icon
											baseClassName="fas"
											className="fa-thin fa-arrow-up-right"
										/>
									}
								>
									Setting up your Profile
								</CustomButton>
							</>
						)}
					</Grid>
					<Grid item md={4} className={styles.column}>
						<Box className={styles.info}>
							<img src={images.liberateCreators} />
							<Typography component={'p'}>
								Discover how Film.io liberates creators.
							</Typography>
						</Box>
						<CustomButton
							customStyle={'purple'}
							href="https://discord.gg/FYJAMCG7mN"
							target="_blank"
							rel="noreferrer"
							endIcon={
								<Icon
									baseClassName="fas"
									className="fa-thin fa-arrow-up-right"
								/>
							}
						>
							Discord
						</CustomButton>
					</Grid>
					<Grid item md={2}></Grid>
					<Grid item md={2}></Grid>
					<Grid item container md={8} className={styles.newsletters}>
						<Grid item md={12} className={styles.emailSignup}>
							<Typography component={'h1'} className={styles.newsletters_title}>
								Be the first to hear about our hottest new updates
							</Typography>
							<Typography component={'h2'}>
								<a
									className={styles.emailButton}
									href="https://film.us12.list-manage.com/subscribe?u=671c0bf1c1805e740ae36d507&id=7e37c9f834"
									target="_blank"
									rel="noreferrer"
									title="Click here to signup for our Newsletter"
								>
									Signup for our Newsletter
								</a>
							</Typography>
						</Grid>
						<Grid item container md={12}>
							<Box className={styles.footer}>
								<Typography
									component={'small'}
									className={styles.newsletters_helper_text}
								>
									By signing up for email notifications, you agree to our
									<a
										href="https://film.io/privacy-policy/"
										rel="noreferrer"
										target="_blank"
									>
										Privacy Policy
									</a>
									{' and '}
									<a
										href="https://film.io/terms-of-use/"
										rel="noreferrer"
										target="_blank"
									>
										Terms of Use
									</a>
								</Typography>

								{/* <CustomButton customStyle={'blue'}>Let&apos;s Go!</CustomButton> */}
							</Box>
						</Grid>
					</Grid>
					<Grid item md={2}></Grid>
				</Grid>
			</Box>
		</Box>
	)
}

export default StartSection
