import { ethers } from 'ethers'
import _ from 'lodash'
import { jsonRpcProvider } from './constants'
import axios from 'axios'
import { prepareTransaction, getMagicWalletAddress } from '../magic.js'

const env = process.env.REACT_APP_ENVIRONMENT || 'development'

export const getTransactionReceipt = async (
	transactionHash,
	confirmations = 1
) => {
	if (typeof transactionHash === 'undefined' || transactionHash === undefined) {
		return false
	}

	await waitForTransaction(transactionHash, confirmations)
	return await jsonRpcProvider.getTransactionReceipt(transactionHash)
}

export const waitForTransaction = async (hash, confirmations = 1) => {
	await jsonRpcProvider.waitForTransaction(hash, confirmations)
}

export const retrieveEventArgs = async (txHash, eventName, contractABI, txReceipt = null) => {
	const receipt = txReceipt ?? await getTransactionReceipt(txHash)

	const isValidEvent = item => {
		const fragmentItem = ethers.utils.Fragment.from(item)
		if (_.isNil(fragmentItem.name) || _.isNil(fragmentItem.type)) {
			return false
		}

		return fragmentItem.name === eventName && fragmentItem.type === 'event'
	}

	const isValidEventInReceipt = item => {
		try {
			eventInterface.parseLog(item)
			return true
		} catch {
			return false
		}
	}

	const abiInterface = new ethers.utils.Interface(contractABI) // this is contract's ABI
	const humanReadableABI = abiInterface.format(ethers.utils.FormatTypes.full) // convert to human readable ABI
	if (typeof humanReadableABI === 'string') {
		throw Error('Invalid ABI!')
	}

	const abiFragments = humanReadableABI.map(item =>
		ethers.utils.Fragment.from(item)
	)

	const eventFragment = abiFragments.filter(isValidEvent)

	if (eventFragment.length === 0) {
		throw Error('Invalid Given Event!')
	}

	const eventInterface = new ethers.utils.Interface(eventFragment)

	const eventLogs = receipt.logs.filter(isValidEventInReceipt)

	return eventLogs.map(log => eventInterface.parseLog(log).args)
}

export const getNetworkFeesData = async () => {
	let maxFeePerGas = ethers.BigNumber.from(100000000000).toBigInt() // fallback to 100 gwei
	let maxPriorityFeePerGas = ethers.BigNumber.from(100000000000).toBigInt() // fallback to 100 gwei

	// get max fees from gas station
	try {
		const { data: rawData } = await axios({
			method: 'get',
			url:
				env === 'production'
					? 'https://gasstation.polygon.technology/v2'
					: 'https://gasstation-testnet.polygon.technology/v2'
		})

		const data = env === 'production' ? rawData : rawData.data

		maxFeePerGas = ethers.utils
			.parseUnits(Math.ceil(data.fast.maxFee).toString(), 'gwei')
			.toBigInt()

		maxPriorityFeePerGas = ethers.utils
			.parseUnits(Math.ceil(data.fast.maxPriorityFee).toString(), 'gwei')
			.toBigInt()
	} catch {}

	return {
		maxFeePerGas,
		maxPriorityFeePerGas
	}
}


export const signVerificationMessage = async (signer, message) => {
	const hash = ethers.utils.hashMessage(message)
	const signature = await signer.signMessage({ message })

	return {
		hash,
		signature
	}
}

export const getTransactionFee = async (
	signer,
	contractName,
	functionName,
	functionArgs
) => {
	try {

		const gasPrice = (await jsonRpcProvider.getGasPrice()).toBigInt()

		const transaction = await prepareTransaction(
			contractName,
			functionName,
			functionArgs,
			'',
			true
		)
		
		const estimate = (await jsonRpcProvider.estimateGas({
			...transaction,
			nonce: await jsonRpcProvider.getTransactionCount(signer?.account?.address),
			from: signer?.account?.address,
			gasPrice
		})).toBigInt()
		
		const transactionFee = Number(ethers.utils.formatEther(gasPrice * estimate))
		const usdValue = await maticToUsd(transactionFee)

		return {
			transactionFee,
			usdValue
		}
	} catch (e) {
		console.error(contractName, functionName, functionArgs, e)
		return {
			transactionFee: -1,
			usdValue: -1
		}
	}
}

export const maticToUsd = async (matic) => {
	const { data } = await axios({
		method: 'get',
		url: 'https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd'
	})
	return Number(data['matic-network'].usd) * Number(matic)
}

export const getMaticBalance = async (address) => {
	const balance = await jsonRpcProvider.getBalance(address)
	return Number(ethers.utils.formatEther(balance))
}

export const getCurrentActiveWalletAddress = async (
	wagmiSigner
) => {
	try {
		const magicWalletAddress = await getMagicWalletAddress()
		return magicWalletAddress
	} catch {
		console.error('No Magic Wallet detected!')
	}

	try {
		const ncWalletAddress = wagmiSigner.account.address
		return ncWalletAddress
	} catch {
		console.error('No NC Wallet detected!')
		return ''
	}
}
