import { Box, Button, Dialog, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setBanDialogOpen } from '../signInSlice'
import banTopImg from 'assets/images/ban_top.svg'
import styles from './BanDialog.module.scss'
import { formatDate } from 'utils/date.utils'

export default function BanDialog() {
	const { banDialogOpen: open, user } = useSelector(
		state => state.signIn
	)
	const dispatch = useDispatch()

	const handleClose = () => { dispatch(setBanDialogOpen(false)) }

	return <Dialog
		open={open}
		disableScrollLock={true}
		className={styles.ban_dialog}
		modal="true"
		classes={{
			paper: styles.dialog_paper,
			container: styles.dialog_container
		}}
		onClose={handleClose}
	>
		<img src={banTopImg} />
		<Box className={styles.content}>
			<Typography component={'h1'}>You have been banned.</Typography>
			<Typography>
				{' '}
				When you created your Film.io account on <strong>{formatDate(user?.createdAt, 'full')}</strong>, you agreed to our <a href={'https://film.io/terms-of-use/'} target="_blank" rel="noreferrer">TOS</a>.
				You have been found in violation of those terms and as such, on this day, <strong>{formatDate(user?.banDate, 'full')}</strong> your account has been permanently banned.
				If you feel this ban is in error, you can <a href={'https://filmio.atlassian.net/servicedesk/customer/portal/4'} target="_blank" rel="noreferrer">lodge a formal complaint</a> which will be reviewed by our internal community guidelines team.
			</Typography>
			<Box className={styles.controls}>
				<Button onClick={handleClose}>Close</Button>
			</Box>
		</Box>
		<Box className={styles.detail_dialog}></Box>
	</Dialog>
}
