import originality from './originality.png'
import originalityRing from './originality-ring.png'
import adoption from './adoption.png'
import adoptionRing from './adoption-ring.png'
import development from './development.png'
import developmentRing from './development-ring.png'
import distribution from './distribution.png'
import distributionRing from './distribution-ring.png'
import scoreRing from './score-ring.png'
import locked from './locked.png'

const stagesImages = {
	originalityRing,
	originality,
	adoption,
	adoptionRing,
	development,
	developmentRing,
	distribution,
	distributionRing,
	scoreRing,
	locked
}

export default stagesImages
