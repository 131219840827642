import comments from './comments.png'
import ratings from './ratings.png'
import shares from './shares.png'
import stakers from './stakers.png'
import tokens from './tokens.png'
import views from './views.png'
import commentsV2 from './comments_v2.png'
import ratingsV2 from './ratings_v2.png'
import sharesV2 from './shares_v2.png'
import stakersV2 from './stakers_v2.png'
import tokensV2 from './tokens_v2.png'
import viewsV2 from './views_v2.png'
const images = {
	comments,
	ratings,
	shares,
	stakers,
	tokens,
	views,
	commentsV2,
	ratingsV2,
	sharesV2,
	stakersV2,
	tokensV2,
	viewsV2,
}

export default images
