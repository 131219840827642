import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { ReviewRating } from '../reviewRating'
import { ReviewInfoTimestamp } from '../reviewInfoTimestamp'
import { CommentReaction } from '../../../common/commentReaction'
import styles from './ReviewItem.module.scss'
import { getTransactionLink } from 'utils/utils'
import { Box } from '@mui/material'

function ReviewItem({
	id,
	user,
	rating,
	heading,
	content,
	comments,
	reactions,
	updatedAt,
	commentsCount,
	blockchainHash,
	project
}) {
	const { user: signInUser } = useSelector(state => state.signIn)
	const isSignInUserReview = signInUser.id === user.id
	return (
		<Box className={styles.review_item}>
			<Box className={styles.reviews}>
				<Box className={isSignInUserReview? `${styles.review_first_fhild} ${styles.review}`: styles.review}>
					<Box className={styles.heading}>
						<ReviewInfoTimestamp {...{ updatedAt, user, project }} />
						{!project && (
							<span className={styles.hash}>
								{getTransactionLink(blockchainHash)}
							</span>
						)}
					</Box>
					<Box className={styles.content}>
						<ReviewRating readOnly {...{ rating }} />
						<h5>
							<label>{heading}</label>
						</h5>
						{content}
					</Box>
					<CommentReaction
						{...{
							comments,
							commentsCount,
							defaultReactions: reactions,
							type: 'review',
							feedId: id
						}}
					/>
				</Box>
			</Box>
		</Box>
	)
}

ReviewItem.propTypes = {
	id: PropTypes.string,
	rating: PropTypes.number,
	content: PropTypes.string,
	comments: PropTypes.array,
	updatedAt: PropTypes.string,
	blockchainHash: PropTypes.string,
	commentsCount: PropTypes.number,
	reactions: PropTypes.array,
	heading: PropTypes.string,
	project: PropTypes.object,
	user: PropTypes.object
}

export default ReviewItem
